import { create } from 'zustand';
import { ReactComponent as Yards } from '../../components/formats/common/svgs/football/jerseyicon1.svg';
import { ReactComponent as Win } from '../../components/formats/common/svgs/football/jerseyicon2.svg';
import { ReactComponent as Receptions } from '../../components/formats/common/svgs/football/jerseyicon3.svg';
import { ReactComponent as Carries } from '../../components/formats/common/svgs/football/jerseyicon4.svg';
import { ReactComponent as Yardstick } from '../../components/formats/common/svgs/football/jerseyicon5.svg';
import { ReactComponent as Tackles } from '../../components/formats/common/svgs/football/jerseyicon6.svg';
import { ReactComponent as Sacks } from '../../components/formats/common/svgs/football/jerseyicon7.svg';
import { ReactComponent as Fumbles } from '../../components/formats/common/svgs/football/jerseyicon8.svg';
import { ReactComponent as Ints } from '../../components/formats/common/svgs/football/jerseyicon9.svg';
import { ReactComponent as QBR } from '../../components/formats/common/svgs/football/jerseyicon10.svg';
import { ReactComponent as Cleat } from '../../components/formats/common/svgs/football/jerseyicon11.svg';
import { ReactComponent as Touchdown } from '../../components/formats/common/svgs/football/jerseyicon12.svg';
import { ReactComponent as Target } from '../../components/formats/common/svgs/football/jerseyicon13.svg';
import { ReactComponent as Points } from '../../components/formats/common/svgs/basketball/points.svg';
import { ReactComponent as Assists } from '../../components/formats/common/svgs/basketball/assists.svg';
import { ReactComponent as Rebounds } from '../../components/formats/common/svgs/basketball/boards.svg';
import { ReactComponent as Steals } from '../../components/formats/common/svgs/basketball/steals.svg';
import { ReactComponent as Blocks } from '../../components/formats/common/svgs/basketball/blocks.svg';
import { ReactComponent as Turnovers } from '../../components/formats/common/svgs/basketball/turnovers.svg';
import { ReactComponent as Threes } from '../../components/formats/common/svgs/basketball/threes.svg';
import { ReactComponent as Ball } from '../../components/formats/common/svgs/baseball/icon1.svg';
import { ReactComponent as Glove } from '../../components/formats/common/svgs/baseball/icon2.svg';
import { ReactComponent as Strikeout } from '../../components/formats/common/svgs/baseball/icon3.svg';
import { ReactComponent as Pitcher } from '../../components/formats/common/svgs/baseball/icon4.svg';
import { ReactComponent as Hit } from '../../components/formats/common/svgs/baseball/icon5.svg';
import { ReactComponent as HomeRun } from '../../components/formats/common/svgs/baseball/icon6.svg';
import { ReactComponent as Run } from '../../components/formats/common/svgs/baseball/icon7.svg';
import { ReactComponent as StolenBase } from '../../components/formats/common/svgs/baseball/icon8.svg';
import { ReactComponent as Goal } from '../../components/formats/common/svgs/hockey/icon1.svg';
import { ReactComponent as Assist } from '../../components/formats/common/svgs/hockey/icon2.svg';
import { ReactComponent as Shots } from '../../components/formats/common/svgs/hockey/icon3.svg';
import { ReactComponent as Plus } from '../../components/formats/common/svgs/hockey/icon4.svg';
import { ReactComponent as Saves } from '../../components/formats/common/svgs/hockey/icon5.svg';
import { ReactComponent as GoalsAgst } from '../../components/formats/common/svgs/hockey/icon6.svg';
import { ReactComponent as Skate } from '../../components/formats/common/svgs/hockey/icon7.svg';
import { ReactComponent as SoccerGoal } from '../../components/formats/common/svgs/soccer/icon1.svg';
import { ReactComponent as SoccerCard } from '../../components/formats/common/svgs/soccer/icon2.svg';
import { ReactComponent as SoccerBlock } from '../../components/formats/common/svgs/soccer/icon3.svg';
import { ReactComponent as SoccerPass } from '../../components/formats/common/svgs/soccer/icon4.svg';
import { ReactComponent as SoccerKick } from '../../components/formats/common/svgs/soccer/icon5.svg';
import { SingleValue } from 'react-select';
import { Option } from '../options.store';
import { jerseyVersionOptions, jerseyStyleOptions } from '../../data/styles/styleOptions';

interface JerseyStore {
    jerseyType: SingleValue<Option>;
	jerseyVersion: SingleValue<Option>;
	playerName: string;
	playerNumber: string;
	iconTypes: string[];
	iconDescriptions: string[];
	banners: string[];
    bannerSpaces: number;
	availableIcons: any[];
    icons: any;
    setJerseyType: (newValue: SingleValue<Option>) => void;
	setJerseyVersion: (newValue: SingleValue<Option>) => void;
	setPlayerName: (newValue: string) => void;
	setPlayerNumber: (newValue: string) => void;
	setIconTypes: (newValue: string[]) => void;
	setIconDescriptions: (newValue: string[]) => void;
	setBanners: (newValue: string[]) => void;
    setBannerSpaces: (newValue: number) => void;
	reset: () => void;
}

const useJerseyStore = create<JerseyStore>((set) => ({
    jerseyType: jerseyStyleOptions[0],
	jerseyVersion: jerseyVersionOptions[0],
	playerName: '',
	playerNumber: '',
	iconTypes: [
        'target',
        'yards',
        'touchdown',
        'win'
    ],
	iconDescriptions: [
        '25 COMPLETIONS',
        '380 PASS YARDS',
        '4 TOUCHDOWNS',
        'WIN!'
    ],
	banners: [],
    bannerSpaces: 18,
	availableIcons: [
        { value: 'target', label: 'Target', sport: 'football', default: '25 COMPLETIONS' },
		{ value: 'yards', label: 'Yards', sport: 'football', default: '380 PASS YDS' },
        { value: 'touchdown', label: 'Touchdown', sport: 'football', default: '4 TOUCHDOWNS' },
        { value: 'win', label: 'Win', sport: 'all', default: 'WIN!' },
		{ value: 'receptions', label: 'Receptions', sport: 'football', default: '8 RECEPTIONS' },
		{ value: 'carries', label: 'Carries', sport: 'football', default: '15 CARRIES' },
		{ value: 'yardstick', label: 'Yardstick', sport: 'football', default: '4.5 YPC' },
		{ value: 'qbr', label: 'QBR', sport: 'football', default: '130.0 PASS RTG' },
		{ value: 'cleat', label: 'Cleat', sport: 'football', default: '108 RUSH YDS' },
        { value: 'tackles', label: 'Tackles', sport: 'football', default: '10 TACKLES' },
		{ value: 'sacks', label: 'Sacks', sport: 'football', default: '2 SACKS' },
		{ value: 'fumbles', label: 'Fumbles', sport: 'football', default: '2 FORCED FUMBLES' },
		{ value: 'ints', label: 'Ints', sport: 'football', default: '1 INT' },
		{ value: 'points', label: 'Points', sport: 'basketball', default: '25 PTS' },
		{ value: 'assists', label: 'Assists', sport: 'basketball', default: '10 AST' },
		{ value: 'rebounds', label: 'Rebounds', sport: 'basketball', default: '12 REB' },
		{ value: 'steals', label: 'Steals', sport: 'basketball', default: '3 STL' },
		{ value: 'blocks', label: 'Blocks', sport: 'basketball', default: '2 BLK' },
		{ value: 'turnovers', label: 'Turnovers', sport: 'basketball', default: '0 TO' },
		{ value: 'threes', label: 'Threes', sport: 'basketball', default: '5 3PM' },
		{ value: 'hit', label: 'Hit', sport: 'baseball', default: '3/4 H/AB' },
		{ value: 'homeRun', label: 'Home Run', sport: 'baseball', default: '1 Home Run' },
		{ value: 'run', label: 'Run', sport: 'baseball', default: '3 RBIs' },
		{ value: 'stolenBase', label: 'Stolen Base', sport: 'baseball', default: '1 Stolen Base' },
		{ value: 'strikeout', label: 'Strikeout', sport: 'baseball', default: '9 Strikeouts' },
		{ value: 'pitcher', label: 'Pitcher', sport: 'baseball', default: '6 Innings Pitched' },
		{ value: 'ball', label: 'Ball', sport: 'baseball', default: '' },
		{ value: 'glove', label: 'Glove', sport: 'baseball', default: '' },
		{ value: 'goal', label: 'Goal', sport: 'hockey', default: '2 Goals' },
		{ value: 'assist', label: 'Assist', sport: 'hockey', default: '1 Assist' },
		{ value: 'shots', label: 'Shots', sport: 'hockey', default: '5 Shots' },
		{ value: 'plus', label: 'Plus', sport: 'hockey', default: '+3' },
		{ value: 'saves', label: 'Saves', sport: 'hockey', default: '20 Saves' },
		{ value: 'goalsAgst', label: 'Goals Agst', sport: 'hockey', default: '2 Goals Agst' },
		{ value: 'skate', label: 'Skate', sport: 'hockey', default: '14:39 Time on Ice' },
		{ value: 'soccerGoal', label: 'Goal', sport: 'soccer', default: '' },
		{ value: 'soccerCard', label: 'Card', sport: 'soccer', default: '' },
		{ value: 'soccerBlock', label: 'Block', sport: 'soccer', default: '' },
		{ value: 'soccerPass', label: 'Pass', sport: 'soccer', default: '' },
		{ value: 'soccerKick', label: 'Kick', sport: 'soccer', default: '' }
	],
    icons: {
		yards: Yards,
		win: Win,
		receptions: Receptions,
		carries: Carries,
		yardstick: Yardstick,
		tackles: Tackles,
		sacks: Sacks,
		fumbles: Fumbles,
		ints: Ints,
		qbr: QBR,
		cleat: Cleat,
		touchdown: Touchdown,
		target: Target,
		points: Points,
		assists: Assists,
		rebounds: Rebounds,
		steals: Steals,
		blocks: Blocks,
		turnovers: Turnovers,
		threes: Threes,
		ball: Ball,
		glove: Glove,
		strikeout: Strikeout,
		pitcher: Pitcher,
		hit: Hit,
		homeRun: HomeRun,
		run: Run,
		stolenBase: StolenBase,
		goal: Goal,
		assist: Assist,
		shots: Shots,
		plus: Plus,
		saves: Saves,
		goalsAgst: GoalsAgst,
		skate: Skate,
		soccerGoal: SoccerGoal,
		soccerCard: SoccerCard,
		soccerBlock: SoccerBlock,
		soccerPass: SoccerPass,
		soccerKick: SoccerKick
	},
    setJerseyType: (newValue) => set({ jerseyType: newValue }),
	setJerseyVersion: (newValue) => set({ jerseyVersion: newValue }),
	setPlayerName: (newValue) => set({ playerName: newValue }),
	setPlayerNumber: (newValue) => set({ playerNumber: newValue }),
	setIconTypes: (newValue) => set({ iconTypes: newValue }),
	setIconDescriptions: (newValue) => set({ iconDescriptions: newValue }),
	setBanners: (newValue) => set({ banners: newValue }),
    setBannerSpaces: (newValue) => set({ bannerSpaces: newValue }),
	reset: () =>
		set(() => ({
			jerseyType: jerseyStyleOptions[0],
			jerseyVersion: jerseyVersionOptions[0],
			playerName: '',
			playerNumber: '',
			banners: [],
            bannerSpaces: 18
		}))
}));

export default useJerseyStore;
