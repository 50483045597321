import React, { useMemo } from 'react';
import useUserStore from '../../stores/user.store';
import useSettingsStore from '../../stores/settings.store';
import Select, { components } from 'react-select';
import type { MenuListProps, SingleValue } from 'react-select';
import { fontOptions } from '../../data/styles/fontOptions';
import type { Option } from '../../stores/options.store';
import { Settings } from '../../data/settings/settings';
import usePreferencesStore from '../../stores/preferences.store';
import Lock from '../Lock';
import { settingsStylesDark } from '../../data/styles/settingsStylesDark';
import { settingsStyles } from '../../data/styles/settingsStyles';
import useOptionsStore from '../../stores/options.store';

interface FontPickerProps {
	settingName: string;
	inline: boolean;
}

export const FontPicker: React.FC<FontPickerProps> = ({ settingName, inline }) => {
	const { lockedSettings } = useUserStore();
	const { settings, updateSetting } = useSettingsStore();
	const { darkMode } = usePreferencesStore();
	const { selectedStyle, monoOnly, setMonoOnly } = useOptionsStore();
	const filteredFontOptions = useMemo(() => {
		return monoOnly ? fontOptions.filter((option) => option.mono) : fontOptions;
	}, [monoOnly]);

	const findValue = useMemo((): SingleValue<Option> => {
		const selected = selectedStyle?.value;
		const fontKey = settings.type === 'news' || settings.type === 'highlightimage' || (settings.type === 'lineupimage' && selected === 'header') ? `${selected}FontNews` : `${selected}Font`;
		return fontOptions.find((font) => font.value === settings[fontKey]) ?? null;
	}, [settings, selectedStyle]);

	const fontChange = (selectedOption: SingleValue<Option>, property: keyof Settings): void => {
		if (settings.type === 'news' || settings.type === 'highlightimage' || (settings.type === 'lineupimage' && property === 'headerFont')) {
			property = String(property) + 'News';
		}
		if (selectedOption) {
			updateSetting(property, selectedOption.value);
		}
	};

	const checkMono = (e: any): void => {
		e.preventDefault();
		e.stopPropagation();
		setMonoOnly(!monoOnly);
	}

	const MenuList = (props: MenuListProps<Option, false>): JSX.Element => (
		<components.MenuList {...props}>
			<div style={{ padding: '8px', display: 'flex', alignItems: 'center' }}>
				<label
					htmlFor='toggle'
					onClick={checkMono}
					style={{ marginRight: '8px', fontSize: '10px' }}>
					Equal-Width Fonts Only
				</label>
				<input
					id='toggle'
					type='checkbox'
					checked={monoOnly}
					onChange={checkMono}
				/>
			</div>
			{props.children}
		</components.MenuList>
	);

	if (inline) {
		return (
			<span className='editor-dropdown lock-after'>
				<Select
					isDisabled={lockedSettings.includes(settingName)}
					value={findValue}
					onChange={(selectedOption: SingleValue<Option>) => {
						fontChange(selectedOption, settingName);
					}}
					options={filteredFontOptions}
					components={{ MenuList }}
					styles={darkMode ? settingsStylesDark : settingsStyles}
				/>
				<Lock
					setting={settingName}
					tooltip='Font'
					lockedSettings={lockedSettings}
				/>
			</span>
		);
	}
	return (
		<Select
			isDisabled={lockedSettings.includes(settingName)}
			value={findValue}
			onChange={(selectedOption: SingleValue<Option>) => {
				fontChange(selectedOption, settingName);
			}}
			options={filteredFontOptions}
			components={{ MenuList }}
			styles={darkMode ? settingsStylesDark : settingsStyles}
			placeholder={settings[settingName]}
		/>
	);
};
