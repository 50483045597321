import React from 'react';

interface FootballJerseyProps {
	fill1: string;
	fill2: string;
}

export const FootballJersey: React.FC<FootballJerseyProps> = ({
	fill1,
	fill2
}) => {
	return (<svg
		viewBox='0 0 400 400'
		className='jerseysvg'
		xmlns='http://www.w3.org/2000/svg'>
		<g id='svgg'>
			<path
				id='path0'
				d='M0.000 7.833 L 0.000 15.667 200.000 15.667 L 400.000 15.667 400.000 7.833 L 400.000 0.000 200.000 0.000 L 0.000 0.000 0.000 7.833 M0.017 131.583 L 0.034 156.167 25.100 172.213 L 50.167 188.260 50.251 294.130 L 50.334 400.000 200.333 400.000 L 350.332 400.000 350.416 294.131 L 350.500 188.261 375.249 172.381 L 399.999 156.500 399.999 131.750 L 400.000 107.000 200.000 107.000 L 0.000 107.000 0.017 131.583'
				stroke='none'
				fill={fill1}
				fillRule='evenodd'
			/>
			<path
				id='path1'
				d='M0.000 61.333 L 0.000 107.000 200.000 107.000 L 400.000 107.000 400.000 61.333 L 400.000 15.667 200.000 15.667 L 0.000 15.667 0.000 61.333 M0.000 278.131 L 0.000 400.000 25.167 400.000 L 50.334 400.000 50.251 294.131 L 50.167 188.262 25.500 172.426 C 11.933 163.716,0.646 156.516,0.417 156.426 C 0.084 156.295,0.000 180.771,0.000 278.131 M375.000 172.495 L 350.500 188.262 350.416 294.131 L 350.332 400.000 375.166 400.000 L 400.000 400.000 400.000 278.333 C 400.000 211.417,399.887 156.680,399.750 156.697 C 399.613 156.714,388.475 163.823,375.000 172.495'
				stroke='none'
				fill={fill2}
				fillRule='evenodd'
			/>
		</g>
	</svg>);
};
