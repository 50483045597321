export const styleOptions = [
	{ value: 'style-1', label: 'Style 1' },
	{ value: 'style-2', label: 'Style 2' },
	{ value: 'style-3', label: 'Style 3' },
	{ value: 'style-4', label: 'Style 4' }
];

export const styleOptionsNews = [
	{ value: 'style-1', label: 'Style 1' },
	{ value: 'style-2', label: 'Style 2' },
	{ value: 'style-3', label: 'Style 3' },
	{ value: 'style-4', label: 'Style 4' },
	{ value: 'style-5', label: 'Style 5' },
	{ value: 'style-6', label: 'Style 6' }
];

export const imageStyleOptions = [
	{ value: 'style-1', label: 'Style 1' },
	{ value: 'style-2', label: 'Style 2' },
	{ value: 'style-3', label: 'Style 3' }
];

export const imageStyleOptionsNews = [
	{ value: 'style-1', label: 'Style 1' },
	{ value: 'style-2', label: 'Style 2' }
];

export const jerseyStyleOptions = [
	{ value: 'football', label: 'Football' },
	{ value: 'basketball', label: 'Basketball' }
];

export const jerseyVersionOptions = [
	{ value: 'home', label: 'Home Jersey' },
	{ value: 'away', label: 'Away Jersey' }
];