import { create } from 'zustand';
import { columnOptions, gridColumnOptions } from '../data/styles/columnOptions';
import type { SingleValue } from 'react-select';
import { minOptions } from '../data/styles/minOptions';
import { colorOptions } from '../data/styles/colorOptions';

export interface Option {
  value: string | number | null;
  label: string;
  className?: string;
}

interface OptionsStore {
  monoOnly: boolean;
  selectedStyle: SingleValue<Option>;
  selectedDimensions: SingleValue<Option>;
  selectedPostColumns: SingleValue<Option>;
  selectedGridColumns: SingleValue<Option>;
  selectedTexture: SingleValue<Option>;
  selectedDarkMode: SingleValue<Option>;
  selectedConference: SingleValue<Option>;
  selectedConference2: SingleValue<Option>;
  selectedAlignment: SingleValue<Option>;
  selectedMultiColor: SingleValue<Option>;
  selectedLogoType: SingleValue<Option>;
  selectedBackgroundLogoType: SingleValue<Option>;
  setMonoOnly: (newValue: boolean) => void;
  setSelectedStyle: (newValue: SingleValue<Option>) => void;
  setSelectedDimensions: (newValue: SingleValue<Option>) => void;
  setSelectedPostColumns: (newValue: SingleValue<Option>) => void;
  setSelectedGridColumns: (newValue: SingleValue<Option>) => void;
  setSelectedTexture: (newValue: SingleValue<Option>) => void;
  setSelectedDarkMode: (newValue: SingleValue<Option>) => void;
  setSelectedConference: (newValue: SingleValue<Option>) => void;
  setSelectedConference2: (newValue: SingleValue<Option>) => void;
  setSelectedAlignment: (newValue: SingleValue<Option>) => void;
  setSelectedMultiColor: (newValue: SingleValue<Option>) => void;
  setSelectedLogoType: (newValue: SingleValue<Option>) => void;
  setSelectedBackgroundLogoType: (newValue: SingleValue<Option>) => void;
}

const useOptionsStore = create<OptionsStore>((set) => ({
  monoOnly: false,
  selectedStyle: { value: 'layout', label: 'Layout Styles' },
  selectedDimensions: { value: '1080x1080', label: 'Square' },
  selectedPostColumns: columnOptions[0],
  selectedGridColumns: gridColumnOptions[0],
  selectedTexture: { value: null, label: 'None' },
  selectedDarkMode: { value: null, label: 'None' },
  selectedConference: { value: '2', label: 'ACC' },
  selectedConference2: { value: 'ENG.1', label: 'Premier League' },
  selectedAlignment: minOptions[0],
  selectedMultiColor: colorOptions[0],
  selectedLogoType: { value: 'primary', label: 'Primary Logo' },
  selectedBackgroundLogoType: { value: 'primary', label: 'Primary Logo' },
  setMonoOnly: (newValue) => set({ monoOnly: newValue }),
  setSelectedStyle: (newValue) => set({ selectedStyle: newValue }),
  setSelectedDimensions: (newValue) => set({ selectedDimensions: newValue }),
  setSelectedPostColumns: (newValue) => set({ selectedPostColumns: newValue }),
  setSelectedGridColumns: (newValue) => set({ selectedGridColumns: newValue }),
  setSelectedTexture: (newValue) => set({ selectedTexture: newValue }),
  setSelectedDarkMode: (newValue) => set({ selectedDarkMode: newValue }),
  setSelectedConference: (newValue) => set({ selectedConference: newValue }),
  setSelectedConference2: (newValue) => set({ selectedConference2: newValue }),
  setSelectedAlignment: (newValue) => set({ selectedAlignment: newValue }),
  setSelectedMultiColor: (newValue) => set({ selectedMultiColor: newValue }),
  setSelectedLogoType: (newValue) => set({ selectedLogoType: newValue }),
  setSelectedBackgroundLogoType: (newValue) => set({ selectedBackgroundLogoType: newValue })
}));

export default useOptionsStore;
