import Draggable from 'react-draggable';
import {Resizable} from 're-resizable';
import useSettingsStore from '../../stores/settings.store';
import usePostStore from '../../stores/posts/base-post.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';
import useBracketStore from '../../stores/posts/bracket.store';
import './BracketFormat/BracketFormat.scss';

export default function BracketFormat(props) {
	const { settings } = useSettingsStore();
	const {
		team,
		name,
		stat,
		image,
		teamsplits,
		setStat
	} = usePostStore();
	const { individuallyEdit, setEditTeam, debug } = useGlobalStore();
	const { sport } = useUserStore();
	const { setRound } = useBracketStore();
	const bracketStore = useBracketStore();

	const advanceTeam = (e, i, round, total, side) => {
		if (e.target.closest('.editable') !== null) {
			return;
		}
		if (individuallyEdit) {
			return;
		}
		// move transform from one playerimage to next
		try {
			if (round !== 'round1') {
				let currentRound = Number(round.replace('round', ''));
				let next = currentRound === 12 ? 8 : currentRound / 2;
				let nextRound = 'round' + next;
				let currentTeams = team;
				let currentNames = name;
				let currentStats = stat;
				let currentImages = image;
				let nextTeams = bracketStore[nextRound].team;
				let nextNames = bracketStore[nextRound].name;
				let nextStats = bracketStore[nextRound].stat;
				let nextImages = bracketStore[nextRound].image;
				if (currentRound !== total) {
					currentTeams = bracketStore[round].team;
					currentNames = bracketStore[round].name;
					currentStats = bracketStore[round].stat;
					currentImages = bracketStore[round].image;
				}
				if (next === 4 && total === 12) {
					if (settings.bracketOneSided) {
						currentTeams = [
							team[2] ?? '',
							bracketStore.round8.team[0] ?? '',
							team[5] ?? '',
							bracketStore.round8.team[1] ?? '',
							team[8] ?? '',
							bracketStore.round8.team[2] ?? '',
							team[11] ?? '',
							bracketStore.round8.team[3] ?? '',
						];
						currentNames = [
							name[2] ?? '',
							bracketStore.round8.name[0] ?? '',
							name[5] ?? '',
							bracketStore.round8.name[1] ?? '',
							name[8] ?? '',
							bracketStore.round8.name[2] ?? '',
							name[11] ?? '',
							bracketStore.round8.name[3] ?? '',
						];
						currentStats = [
							stat[2] ?? '',
							bracketStore.round8.stat[0] ?? '',
							stat[5] ?? '',
							bracketStore.round8.stat[1] ?? '',
							stat[8] ?? '',
							bracketStore.round8.stat[2] ?? '',
							stat[11] ?? '',
							bracketStore.round8.stat[3] ?? '',
						];
						currentImages = [
							image[2] ?? '',
							bracketStore.round8.image[0] ?? '',
							image[5] ?? '',
							bracketStore.round8.image[1] ?? '',
							image[8] ?? '',
							bracketStore.round8.image[2] ?? '',
							image[11] ?? '',
							bracketStore.round8.image[3] ?? '',
						];
					} else {
						currentTeams = [
							bracketStore.round8.team[0] ?? '',
							team[2] ?? '',
							team[5] ?? '',
							bracketStore.round8.team[1] ?? '',
							bracketStore.round8.team[2] ?? '',
							team[8] ?? '',
							team[11] ?? '',
							bracketStore.round8.team[3] ?? '',
						];
						currentNames = [
							bracketStore.round8.name[0] ?? '',
							name[2] ?? '',
							name[5] ?? '',
							bracketStore.round8.name[1] ?? '',
							bracketStore.round8.name[2] ?? '',
							name[8] ?? '',name[11] ?? '',
							bracketStore.round8.name[3] ?? '',
						];
						currentStats = [
							bracketStore.round8.stat[0] ?? '',
							stat[2] ?? '',
							stat[5] ?? '',
							bracketStore.round8.stat[1] ?? '',
							bracketStore.round8.stat[2] ?? '',
							stat[8] ?? '',
							stat[11] ?? '',
							bracketStore.round8.stat[3] ?? '',
						];
						currentImages = [
							bracketStore.round8.image[0] ?? '',
							image[2] ?? '',
							image[5] ?? '',
							bracketStore.round8.image[1] ?? '',
							bracketStore.round8.image[2] ?? '',
							image[8] ?? '',
							image[11] ?? '',
							bracketStore.round8.image[3] ?? '',
						];
					}
				}
				while (nextTeams.length < next) {
					nextTeams.push('');
				}
				while (nextNames.length < next) {
					nextNames.push('');
				}
				while (nextStats.length < next) {
					nextStats.push('');
				}
				while (nextImages.length < next) {
					nextImages.push('');
				}
				let insert = Math.floor(i / 2);
				if (next === 8 && total === 12) {
					switch (i) {
						case 0:
						case 1:
							insert = 0;
							break;
						case 3:
						case 4:
							insert = 1;
							break;
						case 6:
						case 7:
							insert = 2;
							break;
						case 9:
						case 10:
							insert = 3;
							break;
					}
				}
				if (side === 'rightside' && insert === 1 && currentRound === 2) {
					insert = 0;
					i = 2;
				}
				if (currentTeams[i]) {
					nextTeams.splice(insert, 1, currentTeams[i]);
					nextNames.splice(insert, 1, currentNames[i]);
					nextStats.splice(insert, 1, currentStats[i]);
					nextImages.splice(insert, 1, currentImages[i]);
					if (next === 32) {
						setRound('round32', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 16) {
						setRound('round16', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 8) {
						setRound('round8', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 4) {
						setRound('round4', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 2) {
						setRound('round2', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 1) {
						setRound('round1', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					}
					const target = e.target;
					const parent = target.closest('.drag-container');
					const dragel = parent.querySelector('.react-draggable');
					if (dragel) {
						const transform = dragel.style.transform;
						const width = dragel.style.width;
						const height = dragel.style.height;
						setTimeout(() => {
							const newBox = document.querySelectorAll(`.round${next}span > #box${insert} > .playerImageContainer > .react-draggable`)[0];
							if (newBox) {
								newBox.style.transform = transform;
								newBox.style.width = width;
								newBox.style.height = height;
							}
						});
					}
				}
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	const shouldMinimize = (round, total) => {
		if (round === 'round64') {
			return settings.bracketMinimized;
		}
		if (total === 32) {
			return settings.bracketOneSided;
		}
	}

	const showEditPencil = (round, total, instance) => {
		if (!individuallyEdit) {
			return false;
		}
		if (total === 12 && round === 'round8') {
			if (settings.bracketOneSided) {
				return [0,2,4,6].includes(instance);
			}
			return [1,2,5,6].includes(instance);
		}
		return Number(round.replace('round', '')) === total;
	}

	let rounds, total;
	if (settings.bracketOneSided && team.length <= 32) {
		if (team.length <= 4) {
			rounds = ['round4', 'round2', 'round1'];
			total = 4;
		} else if (team.length > 4 && team.length <= 8) {
			rounds = ['round8', 'round4', 'round2', 'round1'];
			total = 8;
		} else if (team.length === 12) {
			rounds = ['round12', 'round8', 'round4', 'round2', 'round1'];
			total = 12;
		} else if (team.length > 8 && team.length <= 16) {
			rounds = ['round16', 'round8', 'round4', 'round2', 'round1'];
			total = 16;
		} else if (team.length > 16 && team.length <= 32) {
			rounds = ['round32', 'round16', 'round8', 'round4', 'round2', 'round1'];
			total = 32;
		}
	} else {
		if (team.length <= 4) {
			rounds = ['round4', 'round2', 'round1', 'round2', 'round4'];
			total = 4;
		} else if (team.length > 4 && team.length <= 8) {
			rounds = ['round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8'];
			total = 8;
		} else if (team.length === 12) {
			rounds = ['round12', 'round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8', 'round12'];
			total = 12;
		} else if (team.length > 8 && team.length <= 16) {
			rounds = ['round16', 'round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8', 'round16'];
			total = 16;
		} else if (team.length > 16 && team.length <= 32) {
			rounds = ['round32', 'round16', 'round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8', 'round16', 'round32'];
			total = 32;
		} else if (team.length > 32) {
			rounds = ['round64', 'round32', 'round16', 'round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8', 'round16', 'round32', 'round64'];
			total = 64;
		}
	}
	return rounds.map((round, j) => {
		let teams = team;
		let names = name;
		let stats = stat;
		let images = image;
		let n = Number(round.replace('round', ''));
		if (n !== total) {
			teams = bracketStore[round].team;
			names = bracketStore[round].name;
			stats = bracketStore[round].stat;
			images = bracketStore[round].image;
		}
		if (n === 8 && total === 12) {
			if (settings.bracketOneSided) {
				teams = [
					team[2] ?? '',
					bracketStore.round8.team[0] ?? '',
					team[5] ?? '',
					bracketStore.round8.team[1] ?? '',
					team[8] ?? '',
					bracketStore.round8.team[2] ?? '',
					team[11] ?? '',
					bracketStore.round8.team[3] ?? '',
				];
				names = [
					name[2] ?? '',
					bracketStore.round8.name[0] ?? '',
					name[5] ?? '',
					bracketStore.round8.name[1] ?? '',
					name[8] ?? '',
					bracketStore.round8.name[2] ?? '',
					name[11] ?? '',
					bracketStore.round8.name[3] ?? '',
				];
				stats = [
					stat[2] ?? '',
					bracketStore.round8.stat[0] ?? '',
					stat[5] ?? '',
					bracketStore.round8.stat[1] ?? '',
					stat[8] ?? '',
					bracketStore.round8.stat[2] ?? '',
					stat[11] ?? '',
					bracketStore.round8.stat[3] ?? '',
				];
				images = [
					image[2] ?? '',
					bracketStore.round8.image[0] ?? '',
					image[5] ?? '',
					bracketStore.round8.image[1] ?? '',
					image[8] ?? '',
					bracketStore.round8.image[2] ?? '',
					image[11] ?? '',
					bracketStore.round8.image[3] ?? '',
				];
			} else {
				teams = [
					bracketStore.round8.team[0] ?? '',
					team[2] ?? '',
					team[5] ?? '',
					bracketStore.round8.team[1] ?? '',
					bracketStore.round8.team[2] ?? '',
					team[8] ?? '',
					team[11] ?? '',
					bracketStore.round8.team[3] ?? '',
				];
				names = [
					bracketStore.round8.name[0] ?? '',
					name[2] ?? '',
					name[5] ?? '',
					bracketStore.round8.name[1] ?? '',
					bracketStore.round8.name[2] ?? '',
					name[8] ?? '',
					name[11] ?? '',
					bracketStore.round8.name[3] ?? '',
				];
				stats = [
					bracketStore.round8.stat[0] ?? '',
					stat[2] ?? '',
					stat[5] ?? '',
					bracketStore.round8.stat[1] ?? '',
					bracketStore.round8.stat[2] ?? '',
					stat[8] ?? '',
					stat[11] ?? '',
					bracketStore.round8.stat[3] ?? '',
				];
				images = [
					bracketStore.round8.image[0] ?? '',
					image[2] ?? '',
					image[5] ?? '',
					bracketStore.round8.image[1] ?? '',
					bracketStore.round8.image[2] ?? '',
					image[8] ?? '',
					image[11] ?? '',
					bracketStore.round8.image[3] ?? '',
				];
			}
		}
		let indices = [];
		let idx = rounds.indexOf(round);
		while (idx !== -1) {
			indices.push(idx);
			idx = rounds.indexOf(round, idx + 1);
		}
		let side;
		let mapthis;
		mapthis = teams.map((team, i) => {
			return {
				team,
				name: names[i],
				stat: stats[i],
				image: images[i],
				index: i
			}
		});
		const emptyObj = {
			team: '',
			name: '',
			stat: '',
			image: ''
		}
		while (mapthis.length < n) {
			mapthis.push(emptyObj);
		}
		if (!settings.bracketOneSided || team.length > 32) {
			const [firsthalf, secondhalf] = [mapthis.slice(0, n / 2), mapthis.slice(n / 2, total)];
			if (j === indices[0] && indices[1]) {
				side = 'leftside';
				mapthis = firsthalf;
			} else if (j === indices[1]) {
				side = 'rightside';
				mapthis = secondhalf;
			} else {
				side = 'middle';
			}
		} else {
			side = 'leftside';
		}
		const groupedTeams = [];
		if (round === 'round12') {
			let i = 0;
			while (i < mapthis.length) {
				groupedTeams.push([mapthis[i], mapthis[i + 1]]);
				i += 3;
			}
		} else {
			for (let i = 0; i < mapthis.length; i += 2) {
				groupedTeams.push(mapthis.slice(i, i + 2));
			}
		}
		return (
			<div
				key={j}
				className={`${round} ${side} roundColumn flex`}>
				{(groupedTeams.map((pair, index) => {
					return (<div
						key={index}
						className='bracket-matchup'>
						{pair.map((obj, i) => {
							let instance = i;
							if (round === 'round12') {
								instance += (index * 3) 
							} else {
								instance += (index * 2)
							}
							if (side === 'rightside') {
								instance += n / 2;
							}
							
							let teamValues = props.getTeam(obj.team);
							let t = teamValues.team ? teamValues.team : null;
							let s = teamValues.sport ? teamValues.sport : sport;
							let team1 = teamValues.team1 ? teamValues.team1 : null;
							let team2 = teamValues.team2 ? teamValues.team2 : null;
							let sport1 = teamValues.sport1 ? teamValues.sport1 : null;
							let sport2 = teamValues.sport2 ? teamValues.sport2 : null;
							let imgsrc;
							if (obj.image) {
								imgsrc = props.getImg(obj.image)[0];
							}
							let top = '';
							let bottom = '';
							if (obj.name) {
								top = '0px';
							}
							if (obj.stat === '' || !obj.stat) {
								bottom = '0px';
								if (obj.name) {
									top = '';
								}
							}
							let boximage, boxbackground, teamObj;
							let hasTeam = false;
							if (t) {
								teamObj = props.getTeamObj(t, s);
								if (teamObj) {
									hasTeam = true;
									boximage = (
										<div
											id={`image${instance}`}
											className={`boximage`}
											style={{backgroundImage: teamObj?.logo, backgroundPosition: shouldMinimize(round, total) ? 'center center' : '', backgroundSize: settings.logoSizeBracket === 100 ? 'cover' : `auto ${settings.logoSizeBracket}%`}}></div>
										);
									boxbackground = props.getBoxBackground(teamObj);
								} else {
									boxbackground = 'transparent';
								}
							}
							if (team1 && team2) {
								hasTeam = true;
								const teamObj1 = props.getTeamObj(team1, sport1);
								const teamObj2 = props.getTeamObj(team2, sport2);
								let existingSplit = teamsplits.find((split) => split.team === `${team1.toUpperCase()}/${team2.toUpperCase()}`);
								boximage = (
									<span className='boximagesplit'>
										<div
											className={`boximage ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[0] : 'cliptopleft'}`}
											style={{ backgroundImage: teamObj1?.logo, backgroundSize: settings.logoSizeBracket === 100 ? 'cover' : `auto ${settings.logoSizeBracket}%` }}></div>
										<div
											className={`boximage ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[1] : 'clipbottomright'}`}
											style={{ backgroundImage: teamObj2?.logo, backgroundSize: settings.logoSizeBracket === 100 ? 'cover' : `auto ${settings.logoSizeBracket}%` }}></div>
									</span>
								);
								boxbackground = props.getTwoTeamBoxBackground(teamObj1, teamObj2, existingSplit);
							}
							const boxBorderColor = props.findColor(settings.borderColor, teamObj);
							const boxGlowColor = props.findColor(settings.boxGlowColor, teamObj);
							return (
								<span
									key={'key' + j + instance}
									className={`${round}span roundspan` + (shouldMinimize(round, total) ? ' bracket-min' : '')}>
									<div
										id={`box${instance}`}
										style={{
											background: boxbackground,
											borderColor: boxBorderColor,
											boxShadow: hasTeam
												? `0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}, inset 0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}`
												: '',
										}}
										className={`box candrag${individuallyEdit} drag-container`}
										onClick={(e) => advanceTeam(e, instance, round, total, side)}>
										{showEditPencil(round, total, instance) && (
											<span
												className={`edit-box ${side}`}
												onClick={(e) => {
													e.stopPropagation();
													if (total === 12 && round === 'round8') {
														let edit;
														if (instance === 0 || instance === 1) edit = 2;
														else if (instance === 2) edit = 5;
														else if (instance === 4 || instance === 5) edit = 8;
														else if (instance === 6) edit = 11;
														return setEditTeam(edit);
													}
													setEditTeam(instance);
												}}>
												<i className='fas fa-pencil-alt no-click'></i>
											</span>
										)}
										<div className={`boximagecontainer`}>{boximage}</div>
										{hasTeam && settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
										{obj.name && (
											<div
												id={`boxname${instance}`}
												className={`boxname` + (settings.nameShadow === 0 ? ' no-shadow' : '') + ' ' + settings.nameAlignment}
												style={shouldMinimize(round, total) ? {
													background: boxbackground,
													color: props.findColor(settings.nameColor, teamObj)
												} : {
													background: props.findColor(settings.nameBackground, teamObj),
													color: props.findColor(settings.nameColor, teamObj)
												}}
												onClick={(e) => {
													props.selectSettings('name');
												}}>
												{shouldMinimize(round, total) && (<div className={`boximagecontainer`}>{boximage}</div>)}
												{shouldMinimize(round, total) && hasTeam && settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
												<span id={`spanname${instance}`} className='spanname' contentEditable={props.homePage} suppressContentEditableWarning={true} style={obj.name && obj.name.includes('##') ? {whiteSpace: 'pre'} : {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
													{obj.name.split('##').join('\n')}
												</span>
												{shouldMinimize(round, total) && (
													<span className='spanstat editable' contentEditable={true} suppressContentEditableWarning={true} style={obj.stat && obj.stat.includes('##') ? {whiteSpace: 'pre', zIndex: 3} : {whiteSpace: 'nowrap', zIndex: 3}} onBlur={(e) => {
														let statsCopy = [...stat];
														statsCopy[obj.index] = e.target.innerText;
														setStat(statsCopy);
													}}>
														{obj.stat ? obj.stat.split('##').join('\n') : '\u00A0'}
													</span>
												)}
												{shouldMinimize(round, total) && imgsrc && (
													<div
													className='playerImageContainer'
													style={{ top, bottom: 0 }}>
													<img
														src={imgsrc}
														draggable='false'
														alt=''
														className='playerimage'></img>
												</div>
												)}
											</div>
										)}
										{obj.stat && (
											<div
												id={`boxstat${instance}`}
												style={{ minWidth: null, color: props.findColor(settings.statColor, teamObj), background: props.findColor(settings.statBackground, teamObj) }}
												className={`boxstat editable` + (settings.statShadow === 0 ? ' no-shadow' : '') + ' ' + settings.statAlignment}
												onClick={(e) => {
													props.selectSettings('stat');
												}}>
												<span
													contentEditable={true}
													suppressContentEditableWarning={true}
													onBlur={(e) => {
														if (bracketStore[round]?.stat) {
															let statsCopy = [...bracketStore[round].stat];
															statsCopy[obj.index] = e.target.innerText;
															setRound(round, {
																team: bracketStore[round].team,
																name: bracketStore[round].name,
																stat: statsCopy,
																image: bracketStore[round].image
															});
														}
													}}
													style={obj.stat && obj.stat.includes('##') ? { whiteSpace: 'pre' } : { whiteSpace: 'nowrap' }}>
													{obj.stat.split('##').join('\n')}
												</span>
											</div>
										)}
										{imgsrc && (
											<div
												className='playerImageContainer'
												style={{ top: top, bottom: bottom }}>
												<Draggable
													disabled={!individuallyEdit}
													onStop={() => props.setDraggables()}>
													<Resizable
														lockAspectRatio={true}
														enable={{
															top: individuallyEdit,
															right: individuallyEdit,
															bottom: individuallyEdit,
															left: individuallyEdit,
															topRight: individuallyEdit,
															bottomRight: individuallyEdit,
															bottomLeft: individuallyEdit,
															topLeft: individuallyEdit,
														}}>
														<img
															src={imgsrc}
															draggable='false'
															alt=''
															className='playerimage'></img>
													</Resizable>
												</Draggable>
											</div>
										)}
									</div>
								</span>
							);
						})}
					</div>)}
				))}
			</div>
		);
	});
}
