export const posts = [
	{
		_id: '64f7c72e1c39bce7ecfa6270',
		thumbnail: '/template1.jpeg',
		title: 'Passing Yards',
		subtitle: '2021 Season',
		team: ['TAM', 'LAC', 'LAR', 'KAN', 'LVR', 'CIN', 'DAL', 'BUF', 'MIN', 'GNB'],
		name: ['Tom Brady', 'Justin Herbert', 'Matthew Stafford', 'Patrick Mahomes', 'Derek Carr', 'Joe Burrow', 'Dak Prescott', 'Josh Allen', 'Kirk Cousins', 'Aaron Rodgers'],
		stat: ['5316', '5014', '4886', '4839', '4804', '4611', '4449', '4407', '4221', '4115'],
		points: [],
		image: ['2330', '4038941', '12483', '3139477', '16757', '3915511', '2577417', '3918298', '14880', '8439'],
		sport: 'nfl',
		settings: {
			type: 'post',
			headerSize: 20,
			nameSize: 18,
			statSize: 18,
			logoOrder: 4,
			logoOpacity: 100,
			logoSize: 59,
			titleMarginTop: 2,
			headerSpacing: 0,
			nameMargin: -1,
			headerFont: 'adidas',
			nameFont: 'uniform bold',
			statFont: 'bebas neue',
			headerColor: '#54cc8f',
			headerFontColor: '#ffffff',
			watermark: '',
			watermarkFont: 'roboto condensed',
			watermarkSize: 30,
			watermarkWidth: 75,
			statMargin: 0,
			watermarkColor: '#ffffff',
			nameShadow: 0,
			borderColor: '#ffffff',
			nameSpacing: 0,
			statSpacing: 0,
			statShadow: 0,
			logoPosition: 292,
			overlap: false,
			statMargin2: 0,
			lineHeight: 0.9,
			nameBackground: '#000000',
			statBackground: '#000000',
			nameColor: '#ffffff',
			statColor: '#ffffff',
			imageHorizontalNews: 0,
			imageVerticalNews: 0,
			imageZoomNews: 132,
			headerSizeNews: 20,
			headerFontNews: 'graduate',
			headerMarginNews: 0,
			headerSpacingNews: 0,
			headerColorNews: '#ffffff',
			headerFontColorNews: '#000000',
			logoOpacityNews: 94,
			nameColorNews: '#ffffff',
			logoSizeNews: 360,
			orientation: 'leftright',
			orientationNews: 'leftright',
			orientationMatchup: 'topbottom',
			borderRadius: 14,
			scoreMargin: 5,
			paneSplit: [50, 50],
			paneSplitNews: [50, 50],
			paneSplitMatchup: [80, 20],
			duplicate: false,
			logoShadow: 3,
			nameMargin2: 10,
			postWidth: 814.44,
			postHeight: 814.44,
			postWidthNews: 814.44,
			postHeightNews: 814.44,
			tierFont: 'metropolis',
			tierColor: '#ffffff',
			tierSize: 66,
			duplicateLogoSize: 514,
			duplicateLogoPosition: 61,
			duplicateLogoOpacity: 8,
			gridMargin: 1,
			logoFlip: true,
			scoreMinHeight: 100,
			videoDirection: 'fromright',
			videoReverse: false,
			headerOutlineColor: '#ffffff',
			nameOutlineColor: '#ffffff',
			statOutlineColor: '#ffffff',
			headerOutlineSize: 0,
			nameOutlineSize: 0,
			statOutlineSize: 0,
			headerTransform: 'uppercase',
			nameTransform: 'none',
			statTransform: 'none',
			nameShadowColor: '#000000',
			statShadowColor: '#000000',
			tierTitle: 'left',
			pyramid: false,
			newsStyle: {
				value: 'style-1',
				label: 'Style 1'
			},
			postBackground: null,
			titleHeight: 10,
			subtitleFontSize: 10,
			subtitleMarginTop: -7,
			subtitleSpacing: 0,
			subtitleFont: 'league gothic',
			subtitleFontColor: '#ffffff',
			subtitleOutlineColor: '#ffffff',
			subtitleOutlineSize: 0,
			subtitleTransform: 'uppercase',
			backgroundColor: 'gradient',
			postColumns: null,
			gridColumns: null,
			tierSplit: 8,
			fivelessBreak: 8,
			rankSize: 21,
			rankFont: 'league gothic',
			rankFontColor: '#ffffff',
			rankMargin: 3,
			rankSpacing: 0,
			rankOutlineSize: 2,
			rankOutlineColor: '#000000',
			logoSizeGrid: 100,
			logoSizeTier: 100,
			pointsSize: 20,
			pointsFont: 'roboto condensed',
			pointsFontColor: '#ffffff',
			pointsMargin: 0,
			pointsSpacing: -1.2,
			pointsOutlineSize: 0,
			pointsOutlineColor: '#000000',
			pointsBackground: '#000000',
			playerHeight: 1,
			statLineHeight: 1,
			indexAxis: 'y',
			chartType: {
				value: 'bar',
				label: 'Bar Chart'
			},
			labelSize: 14,
			labelColor: '#ffffff',
			labelFont: 'lemon milk light',
			chartBorderColor: '#ffffff',
			chartBorderWidth: 3,
			chartLogoSize: 90,
			playerMargin: 0,
			dark: false,
			texture: 'grainy',
			textureSize: 1,
			textureOpacity: 19,
			rankBackground: 'primary',
			boxMarginTop: 6,
			boxMarginRight: 6,
			boxMarginBottom: 6,
			boxMarginLeft: 6,
			teamBackground: 'gradient',
			actualHeaderColor: '#00000000',
			rankWidth: 35,
			rankMargin2: 0,
			boxRankOutlineSize: 0,
			borderSize: 0,
			headerBorderSize: 0,
			darker: false,
			headerBorderColor: '#ffffff',
			lighterLogos: false,

			boxImageBackground: 'none',
			backgroundGradientDirectionDeg: '0deg',
			backgroundGradientStops: [
				{
					color: '#044571',
					percentage: 35
				},
				{
					color: '#000000',
					percentage: 100
				}
			],
			gradientDirection: '270deg',
			gradientDirectionNews: '0deg',
			gradientType: 'linear-gradient',
			gradientStops: [
				{
					color: 'secondary',
					percentage: 37
				},
				{
					color: 'mid',
					percentage: 61
				},
				{
					color: 'primary',
					percentage: 100
				}
			],
			gradientTypeNews: 'linear-gradient',
			gradientStopsNews: [
				{
					color: 'secondary',
					percentage: 53
				},
				{
					color: 'mid',
					percentage: 70
				},
				{
					color: 'primary',
					percentage: 93
				}
			]
		},
		teamoverrides: [],
		teamsplits: [],
		draggables: [],
		floatingTexts: [],
		date: '2023-09-06T00:26:22.589Z',
		ranks: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
	}
];
