import Modal from 'react-modal';
import useModalStore from '../../stores/modal.store';
import { useState } from 'react';
import usePostStore from '../../stores/posts/base-post.store';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import TooltipSlider from '../TooltipSlider';

interface RowData {
	[key: string]: string;
}

export default function PasteTableModal (): JSX.Element {
	const [source, setSource] = useState<string>('');
	const [columnNames, setColumnNames] = useState<string[]>([]);
	const [rows, setRows] = useState<RowData[]>([]);
	const [selectedColumn, setSelectedColumn] = useState<string>('');
	const [numRows, setNumRows] = useState<number>(10);
	const { setTeam, setName, setStat, setImage } = usePostStore();
	const { toggleModal } = useModalStore();

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		if (source === 'ESPN') {
			handleESPNPaste(event);
		} else if (source === 'PFR') {
			handlePFRPaste(event);
		}
	}

	const handleESPNPaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		try {
			const clipboardData = event.clipboardData;
			if (clipboardData) {
				const pastedText = clipboardData.getData('text/html');
				if (pastedText !== '') {
					const doc = new DOMParser().parseFromString(pastedText, 'text/html');
					const tableElements = doc.querySelectorAll('table');

					const columnNames: string[] = [];
					const thElements = doc.querySelectorAll('table thead th');
					thElements.forEach((th) => {
						const columnName = th.textContent?.trim().toUpperCase() ?? '';
						columnNames.push(columnName);
					});

					const rows: any[] = [];
					const playerRows = tableElements[0].querySelectorAll('tbody tr');
					const statsRows = tableElements[1].querySelectorAll('tbody tr');

					playerRows.forEach((tr, i) => {
						const rowData: RowData = {};
						const playerTds = tr.querySelectorAll('td');
						const statsTds = statsRows[i]?.querySelectorAll('td') || [];

						playerTds.forEach((td, index) => {
							const columnName = columnNames[index];
							if (columnName === 'NAME') {
								rowData.NAME = td.querySelector('.AnchorLink')?.textContent?.trim() ?? '';
								rowData.TEAM = td.querySelector('.athleteCell__teamAbbrev')?.textContent?.trim() ?? '';
							} else {
								rowData[columnName] = td.textContent?.trim() ?? '';
							}
						});

						statsTds.forEach((td, index) => {
							const columnName = columnNames[index + playerTds.length];
							rowData[columnName] = td.textContent?.trim() ?? '';
						});

						rows.push(rowData);
					});

					const sortedColumn = doc.querySelector('col.Table--sorted');
					const sortedColumnIndex = sortedColumn?.parentNode ? [...sortedColumn.parentNode.children].indexOf(sortedColumn) : -1;

					if (sortedColumnIndex !== -1) {
						setSelectedColumn(columnNames[sortedColumnIndex + 3]);
					}

					setColumnNames(columnNames);
					setRows(rows);
					return;
				}
			}
			toast.error('Table could not be parsed, please try copying and pasting again.');
		} catch (error) {
			toast.error('Table could not be parsed, please try copying and pasting again.');
		}
	};

	const handlePFRPaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		try {
			const clipboardData = event.clipboardData;
			if (clipboardData) {
				const pastedText = clipboardData.getData('text/html');
				if (pastedText !== '') {
					const doc = new DOMParser().parseFromString(pastedText, 'text/html');

					const columnNames: string[] = [];
					const thElements = doc.querySelectorAll('table thead th');
					thElements.forEach((th) => {
						const columnName = th.textContent?.trim() ?? '';
						columnNames.push(columnName);
					});

					const rows: any[] = [];
					const playerRows = doc.querySelectorAll('table tbody tr');

					playerRows.forEach((tr, i) => {
						const rowData: RowData = {};
						const ths = tr.querySelectorAll('th');
						const tds = tr.querySelectorAll('td');

						ths.forEach((th, index) => {
							const columnName = columnNames[index];
							rowData[columnName] = th.textContent?.trim() ?? '';
						});

						tds.forEach((td, index) => {
							const columnName = columnNames[index + 1];
							rowData[columnName] = td.textContent?.trim() ?? '';
						});

						rows.push(rowData);
					});

					const sortedColumn = doc.querySelector('col.sort_col');
					const sortedColumnIndex = sortedColumn?.parentNode ? [...sortedColumn.parentNode.children].indexOf(sortedColumn) : -1;

					if (sortedColumnIndex !== -1) {
						setSelectedColumn(columnNames[sortedColumnIndex]);
					}

					setColumnNames(columnNames);
					setRows(rows);
					return;
				}
			}
			toast.error('Table could not be parsed, please try copying and pasting again.');
		} catch (error) {
			toast.error('Table could not be parsed, please try copying and pasting again.');
		}
	};

	const finish = (): void => {
		if (columnNames.length > 0 && source === 'ESPN') {
			setTeam(rows.map((row) => row.TEAM).slice(0, numRows));
			setName(rows.map((row) => row.NAME).slice(0, numRows));
			setStat(rows.map((row) => row[selectedColumn]).slice(0, numRows));
			setImage([]);
		} else if (columnNames.length > 0 && source === 'PFR') {
			setTeam(rows.map((row) => row.Tm || row.Team).slice(0, numRows));
			setName(rows.map((row) => row.Player).slice(0, numRows));
			setStat(rows.map((row) => row[selectedColumn]).slice(0, numRows));
			setImage([]);
		}
		toggleModal('paste');
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('paste')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='modal paste-table-modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Paste External Table</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('paste')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							{/* Step 1: Select Source */}
							{!source && <select value={source} onChange={(e) => setSource(e.target.value)}>
								<option value="">Select Source</option>
								<option value="ESPN">ESPN</option>
								<option value="PFR">Sports Reference</option>
							</select>}
							{source && columnNames.length === 0 && (
								<div>
									{source === 'ESPN' && (
										<div className='how-to'>
											<p>
												Copy the bottom right cell all the way to the top left including the table header{' '}
												<Tippy content={<img className='tooltip-image' src='/espncopy.png' alt='espn copy'/>}>
													<i className='fa fa-question-circle'></i>
												</Tippy>
											</p>
										</div>
									)}
									{source === 'PFR' && (
										<div className='how-to'>
											<p>
												Copy the rows you would like to include plus the table header{' '}
												<Tippy content={<img className='tooltip-image' src='/pfrcopy.png' alt='pfr copy'/>}>
													<i className='fa fa-question-circle'></i>
												</Tippy>
											</p>
										</div>
									)}
									<input
										type="text"
										placeholder="Paste table here..."
										onPaste={handlePaste}
									/>
								</div>
							)}
							{/* Step 3: Select Stat Column and Number of Rows */}
							{columnNames.length > 0 && (
								<div>
									<label>Select Stat Column:</label>
									<select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)} style={{ marginBottom: '20px' }}>
										{columnNames.map((key, index) => (
											<option key={index} value={key}>{key}</option>
										))}
									</select>

									<label>Select Number of Rows:</label>
									<TooltipSlider
										defaultValue={numRows}
										onChange={(value) => setNumRows(Number(value))}
										min={5}
										max={rows.length}
										tipFormatter={(value) => `${value}`}
									/>
								</div>
							)}
						</div>
						<div className='modal-footer'>
							<button type='button' className='btn btn-primary' onClick={finish}>{columnNames.length > 0 ? 'Finish' : 'Cancel'}</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
