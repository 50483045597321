import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import useSettingsStore from "../stores/settings.store";
import { useEffect, useState } from 'react';
import useUserStore from '../stores/user.store';

export default function Formats({changeType}) {
	const [imagesLoaded, setImagesLoaded] = useState(false);
    const { settings } = useSettingsStore();
	const { sport } = useUserStore();

	useEffect(() => {
		const postTypeGrid = document.querySelector('.postTypeGrid');
		if (!postTypeGrid) return;
	
		const images = Array.from(postTypeGrid.querySelectorAll('img'));
		const imagePromises = images.map((image) => {
		  return new Promise((resolve) => {
			if (image.complete) {
			  resolve();
			} else {
			  image.onload = () => resolve();
			  image.onerror = () => resolve();
			}
		  });
		});
	
		Promise.all(imagePromises).then(() => {
		  setImagesLoaded(true);
		});
	}, []);

	useEffect(() => {
		if (imagesLoaded) {
			document.getElementById('formatsLoading').classList.add('hide');
			document.getElementById('formatsLoaded').classList.remove('hide');
		} else {
			document.getElementById('formatsLoading').classList.remove('hide');
			document.getElementById('formatsLoaded').classList.add('hide');
		}
	}, [imagesLoaded])

	return (
		<div className='postType'>
			<span className='post-image-loading' id='formatsLoading'>
				<i className='fa fa-spinner fa-spin'></i>
			</span>
			<div className='postTypeGrid hide' id='formatsLoaded'>
			<Tippy content={<span>List</span>}>
				<span
					className={settings.type === 'post' ? 'selected' : ''}
					id='post-type'
					onClick={() => changeType('post')}>
					<img
						src='/list.png'
						alt='list'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>List With Image</span>}>
				<span
					className={settings.type === 'postimage' ? 'selected' : ''}
					id='postimage-type'
					onClick={() => changeType('postimage')}>
					{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
					<img
						src='/listimage.png'
						alt='list with image'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Grid</span>}>
				<span
					className={settings.type === 'grid' ? 'selected' : ''}
					id='grid-type'
					onClick={() => changeType('grid')}>
					<img
						src='/grid.png'
						alt='grid'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Grid With Image</span>}>
				<span
					className={settings.type === 'gridimage' ? 'selected' : ''}
					id='gridimage-type'
					onClick={() => changeType('gridimage')}>
					{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
					<img
						src='/gridimage.png'
						alt='grid with image'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Tier</span>}>
				<span
					className={settings.type === 'tier' ? 'selected' : ''}
					id='tier-type'
					onClick={() => changeType('tier')}>
					<img
						src='/tier.png'
						alt='tier'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Bracket</span>}>
				<span
					className={settings.type === 'bracket' ? 'selected' : ''}
					id='bracket-type'
					onClick={() => changeType('bracket')}>
					<img
						src='/bracket.png'
						alt='bracket'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Scores</span>}>
				<span
					className={settings.type === 'scores' ? 'selected' : ''}
					id='scores-type'
					onClick={() => changeType('scores')}>
					<img
						src='/scores.png'
						alt='scores'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Matchup</span>}>
				<span
					className={settings.type === 'matchupimage' ? 'selected' : ''}
					id='matchupimage-type'
					onClick={() => changeType('matchupimage')}>
					<img
						src='/matchup.png'
						alt='matchup'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>News/Quote</span>}>
				<span
					className={settings.type === 'news' ? 'selected' : ''}
					id='news-type'
					onClick={() => changeType('news')}>
					<img
						src='/news.png'
						alt='news/quote'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Chart</span>}>
				<span
					className={settings.type === 'chart' ? 'selected' : ''}
					id='chart-type'
					onClick={() => changeType('chart')}>
					<img
						src='/chart.png'
						alt='chart'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Highlight</span>}>
				<span
					className={settings.type === 'highlightimage' ? 'selected' : ''}
					id='highlightimage-type'
					onClick={() => changeType('highlightimage')}>
					<img
						src='/highlight.png'
						alt='highlight'
						draggable='false'
					/>
				</span>
			</Tippy>
			<Tippy content={<span>Lineup</span>}>
				<span
					className={settings.type === 'lineupimage' ? 'selected' : ''}
					id='lineupimage-type'
					onClick={() => changeType('lineupimage')}>
					<img
						src='/lineup.png'
						alt='lineup'
						draggable='false'
					/>
				</span>
			</Tippy>
			{/* <Tippy content={<span>Comparison</span>}>
				<span
                    className={settings.type === 'comparison' ? 'selected' : ''}
                    id='comparison-type'
                    onClick={() => changeType('comparison')}>
                    <img
                        src='/comparison.png'
                        alt='comparison'
						draggable='false'
                    />
                </span>
			</Tippy> */}
			{!['f1','afl','custom','soccer','wnba'].includes(sport) && (<Tippy content={<span>Jersey</span>}>
				<span
                    className={settings.type === 'jerseyformat' ? 'selected' : ''}
                    id='jersey-type'
                    onClick={() => changeType('jerseyformat')}>
                    <img
                        src='/jersey.png'
                        alt='jersey'
						draggable='false'
                    />
                </span>
			</Tippy>)}
			</div>
			<a className='newFormat' href='https://forms.gle/gmU7tWwrEMQhBqvD8' target='_blank' rel='noreferrer'>
				Request a new format
			</a>
		</div>
	);
}
