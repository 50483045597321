import useSettingsStore from '../../stores/settings.store';
import usePostStore from '../../stores/posts/base-post.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';
import useTierStore from '../../stores/posts/tier.store';

export default function TierFormat(props) {
	const { settings } = useSettingsStore();
	const { tiers, setTiers } = useTierStore();
	const {
		team,
		name,
		stat,
		image,
		teamsplits
	} = usePostStore();
	const { individuallyEdit, sizeMultiplier, setEditTeam } = useGlobalStore();
	const { sport } = useUserStore();

	let rows = [];
	let teams = team;
	let separator = '#';
	let index = teams.indexOf(separator);
	while (index !== -1) {
		rows.push(index);
		index = teams.indexOf(separator, index + 1);
	}
	rows.push(200);
	return rows.map((item, i) => {
		let width = `calc(${String(tiers[i]).length + 'ch'} + 12px)`;
		let height = '100%';
		if (settings.tierTitle === 'top' || settings.tierTitle === 'bottom') {
			width = '100%';
			height = `calc(${(settings.tierSize * sizeMultiplier) / 2}px + calc(10px * var(--sizemultiplier))`;
		}
		let rowCount = 0;
		if (teams.length) {
			rowCount = teams
				.join(',')
				.split('#')
				[i].split(',')
				.filter((n) => n).length;
		}
		return (
			<div
				key={i}
				className={`tierrow row${i} tier${settings.tierTitle} ${settings.pyramid ? ' pyramid' : ''}`}>
				<input
					type='text'
					id={`tierInput${i}`}
					value={tiers[i]}
					style={{width, height}}
					className='blend-in tierBox'
					onClick={() => props.selectSettings('tier')}
					onChange={(e) => {
						let tiersCopy = {...tiers};
						tiersCopy[i] = e.target.value;
						setTiers(tiersCopy);
					}}></input>
				<span
					className={`tier-content ${rowCount > settings.tierSplit ? 'splitrow' : ''}`}
					style={rowCount > settings.tierSplit ? {gridTemplateColumns: `repeat(${Math.ceil(rowCount / 2)}, 1fr)`} : {}}>
					{teams.map((item, j) => {
						if (item !== '#' && ((rows[i - 1] && rows[i - 1] < j && j < rows[i]) || (!rows[i - 1] && j < rows[i]))) {
							let selectedName = name[j - i];
							let selectedStat = stat[j - i];
							let teamValues = props.getTeam(team[j]);
							let t = teamValues.team ? teamValues.team : null;
							let s = teamValues.sport ? teamValues.sport : sport;
							let team1 = teamValues.team1 ? teamValues.team1 : null;
							let team2 = teamValues.team2 ? teamValues.team2 : null;
							let sport1 = teamValues.sport1 ? teamValues.sport1 : null;
							let sport2 = teamValues.sport2 ? teamValues.sport2 : null;
							let imgsrc;
							if (image[j - i]) {
								imgsrc = props.getImg(image[j - i])[0];
							}
							let bottom = '';
							if (selectedStat === '' || !selectedStat || settings.statAlignment !== 'fill') {
								bottom = '0px';
							}
							let boximage, boxbackground, teamObj;
							if (t) {
								teamObj = props.getTeamObj(t, s);
								if (teamObj) {
									boximage = (
										<div
											id={`image${i + j}`}
											className={`boximage`}
											style={{backgroundImage: teamObj?.logo, backgroundSize: settings.logoSizeTier === 100 ? 'cover' : `${settings.logoSizeTier}%`}}></div>
									);
									boxbackground = props.getBoxBackground(teamObj);
								} else {
									boxbackground = 'transparent';
								}
							}
							if (team1 && team2) {
								const teamObj1 = props.getTeamObj(team1, sport1);
								const teamObj2 = props.getTeamObj(team2, sport2);
								let existingSplit = teamsplits.find((split) => split.team === team[j].toUpperCase());
								boximage = (
									<span
										className='boximagesplit'
										data-team={team[j]}
										onClick={props.flipClip}>
										<div
											className={`boximage no-click ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[0] : 'cliptopleft'}`}
											style={{backgroundImage: teamObj1?.logo, backgroundSize: settings.logoSizeTier === 100 ? 'cover' : `${settings.logoSizeTier}%`}}></div>
										<div
											className={`boximage no-click ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[1] : 'clipbottomright'}`}
											style={{backgroundImage: teamObj2?.logo, backgroundSize: settings.logoSizeTier === 100 ? 'cover' : `${settings.logoSizeTier}%`}}></div>
									</span>
								);
								boxbackground = props.getTwoTeamBoxBackground(teamObj1, teamObj2, existingSplit);
							}
							return (
								<div
									id={`box${i}${j}`}
									key={'key' + i + j}
									style={{background: boxbackground}}
									className={`box drag-container ${individuallyEdit ? ' editing2' : ''}`}>
									{individuallyEdit && (
										<span
											className={`edit-box left`}
											onClick={(e) => {
												e.stopPropagation();
												setEditTeam(i);
											}}>
											<i className='fas fa-pencil-alt no-click'></i>
										</span>
									)}
									<div className={`boximagecontainer`}>{boximage}</div>
									{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
									{selectedName && (
										<div
											id={`boxname${i}${j}`}
											className={`boxname` + (settings.nameShadow === 0 ? ' no-shadow ' : ' ') + settings.nameAlignment}
											onClick={(e) => {
												props.openInlineEditor(e.target, 'name', j, 25);
												props.selectSettings('name');
											}}
											style={props.getNameStyle(j, null, selectedName, teamObj)}>
											<span id={`spanname${i}${j}`} contentEditable={props.homePage} suppressContentEditableWarning={true}>
												{selectedName.split('##').join('\n')}
											</span>
										</div>
									)}
									{selectedStat && (
										<div
											id={`boxstat${i}${j}`}
											className={`boxstat` + (settings.statShadow === 0 ? ' no-shadow ' : ' ') + settings.statAlignment}
											onClick={(e) => {
												props.openInlineEditor(e.target, 'stat', j, 25);
												props.selectSettings('stat');
											}}
											style={props.getStatStyle(j, selectedStat, null, null, null, teamObj)}>
											<span contentEditable={props.homePage} suppressContentEditableWarning={true}>
												{String(selectedStat).split('##').join('\n')}
											</span>
										</div>
									)}
									{imgsrc && (
										<img
											src={imgsrc}
											alt=''
											style={{bottom: bottom, height: rowCount > settings.tierSplit && !settings.unevenTiers ? '140%' : ''}}
											className='playerimage'></img>
									)}
								</div>
							);
						} else {
							return null;
						}
					})}
				</span>
			</div>
		);
	});
}
