import React from 'react';

interface BasketballJerseyProps {
	fill1: string;
	fill2: string;
	fill3: string;
	fill4: string;
	fill5: string;
}

export const BasketballJersey: React.FC<BasketballJerseyProps> = ({ fill1, fill2, fill3, fill4, fill5 }) => {
	return (
		<svg
			id='svg'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			style={{ backgroundColor: fill2 }}
			className='jerseysvg'
			viewBox='0 0 810 809.999993'>
			<path
				fill={fill5}
				d='M 240.5 0.503906 C 240.5 0.503906 210.839844 133.460938 213.734375 133.859375 C 248.304688 138.597656 531.847656 129.664062 575.421875 122.632812 C 600.851562 118.53125 570.296875 0.203125 569.96875 0.210938 C 564.324219 0.292969 538.492188 0.949219 539.066406 0.910156 C 540.03125 0.847656 448.835938 16.71875 403.242188 17.273438 C 343.050781 18 280.054688 0.675781 278.105469 0.664062 M 84.078125 34.609375 C 84.078125 34.847656 67.132812 39.558594 66.773438 39.6875 C 66.320312 39.851562 97.308594 142.691406 90.402344 225.652344 C 84.441406 297.269531 40.425781 350.671875 40.402344 350.902344 C 40.34375 351.398438 40.804688 370.679688 40.453125 371.027344 C 40.222656 371.25 163.851562 371.84375 170.265625 361.238281 C 171.972656 358.414062 179.914062 35.945312 179.101562 32.316406 M 574.628906 42.621094 C 573.640625 46.050781 594.003906 381.394531 595.710938 384.21875 C 602.125 394.824219 768.800781 381.121094 768.800781 378.925781 C 768.800781 378.457031 768.292969 354.707031 768.167969 353.792969 C 768.125 353.46875 722.0625 290.902344 715.515625 218.414062 C 707.929688 134.351562 739.605469 39.375 740.019531 39.230469 C 740.625 39.015625 725.882812 35.445312 725.882812 35.078125 '
				fillRule='evenodd'
			/>
			<path
				fill={fill4}
				d='M 569.902344 0.199219 C 569.117188 0.425781 485.085938 28.050781 402.335938 28.84375 C 321.589844 29.617188 241.734375 0.796875 240.386719 0.457031 C 237.167969 -0.355469 209.640625 0.0859375 209.21875 0.464844 C 208.738281 0.890625 212.925781 177.742188 235.195312 183.84375 C 245.390625 186.640625 567.207031 199.015625 572.546875 198.21875 C 600.953125 193.96875 598.277344 0.148438 598.292969 0.113281 M 82.910156 34.683594 C 82.800781 34.757812 116.027344 154.753906 104.96875 241.199219 C 94.578125 322.445312 40.40625 370.460938 40.40625 370.460938 C 40.40625 370.460938 39.46875 389.035156 39.167969 390.042969 C 38.652344 391.757812 38.667969 391.773438 39.421875 390.230469 C 40.25 388.535156 215.175781 406.867188 228.808594 387.210938 C 245.617188 362.988281 188.5 43.242188 190.472656 21.683594 C 191.339844 12.269531 96.058594 31.421875 96.441406 30.855469 M 710.027344 30.378906 C 711.574219 31.074219 589.308594 28.734375 588.835938 31.558594 C 584.886719 55.203125 592.894531 401.003906 595.210938 403.824219 C 597.527344 406.648438 770.074219 398.25 769.496094 397.726562 C 769.011719 397.285156 769.523438 377.019531 769.523438 377.019531 C 769.523438 377.019531 711.066406 308.617188 701.1875 228.703125 C 689.992188 138.15625 726.582031 35.183594 726.46875 35.125 M 741.9375 392.472656 C 741.9375 392.597656 743.117188 393.671875 744.5625 394.855469 L 747.195312 397.007812 L 744.816406 394.625 C 742.605469 392.40625 741.9375 391.90625 741.9375 392.472656 M 752.859375 403.242188 C 755.054688 405.257812 757.027344 406.910156 757.253906 406.910156 C 757.472656 406.910156 755.859375 405.257812 753.667969 403.242188 C 751.476562 401.226562 749.503906 399.574219 749.28125 399.574219 C 749.054688 399.574219 750.667969 401.226562 752.859375 403.242188 '
				fillRule='evenodd'
			/>
			<path
				fill={fill3}
				d='M 191.609375 142.292969 C 194.722656 141.871094 630.113281 139.308594 630.730469 137.515625 C 631.210938 136.132812 624.019531 0.648438 624.335938 0.824219 C 624.648438 1 598.09375 0.03125 598.09375 0.03125 C 598.097656 0.03125 498.371094 41.542969 402.878906 41.753906 C 305.820312 41.964844 212.964844 0.871094 212.074219 0.550781 C 211.285156 0.269531 179.890625 0.730469 179.890625 0.730469 M 96.621094 30.601562 C 97.140625 30.4375 137.113281 153.128906 121.691406 249.128906 C 108.398438 331.851562 39.90625 390.5 39.300781 390.636719 C 38.804688 390.753906 38.664062 413.164062 38.664062 413.164062 C 38.664062 413.164062 166.367188 424.761719 171.484375 417.550781 C 172.757812 415.746094 179.238281 33.320312 178.742188 30.496094 C 176.207031 16.160156 111.605469 25.113281 111.863281 24.738281 M 593.160156 36.460938 C 595.136719 50.753906 451.09375 403.699219 460.890625 424.042969 C 469.128906 441.148438 767.875 415.6875 769.289062 416.191406 C 770.371094 416.578125 770.453125 396.179688 769.234375 396 C 768.582031 395.90625 699.617188 326.882812 686.167969 241.664062 C 670.914062 145.011719 712.4375 30.734375 711.660156 30.734375 C 711.265625 30.734375 696.710938 25.242188 696.460938 25.1875 '
				fillRule='evenodd'
			/>
			<path
				fill={fill1}
				d='M 180.085938 0.441406 C 157.902344 10.246094 109.304688 25.296875 110.992188 25.195312 C 111.539062 25.160156 156.058594 157.40625 138.53125 252.589844 C 120.5 350.496094 40.246094 411.125 39.675781 413.683594 C 36.179688 429.28125 38.792969 926.707031 45.945312 926.808594 C 285.546875 930.226562 525.273438 931.828125 764.839844 926.808594 C 767.613281 926.75 775.167969 456.132812 769.695312 414.609375 C 769.074219 409.886719 688.640625 343.824219 671.117188 247.898438 C 653.863281 153.441406 697.945312 27.066406 697.675781 25.78125 C 697.445312 24.679688 626.167969 0.714844 624.714844 0.667969 C 623.96875 0.644531 507.148438 52.984375 403.949219 53.761719 C 293.695312 54.597656 180.796875 0.375 179.949219 0.269531 '
				fillRule='evenodd'
			/>
		</svg>
	);
};
