
import useGlobalStore from '../../../stores/global.store';
import usePostStore from '../../../stores/posts/base-post.store';
import useWithImageStore from '../../../stores/posts/with-image.store';
import useSettingsStore from '../../../stores/settings.store';
import useUserStore from '../../../stores/user.store';

export default function LineupFormat(props) {
	const { settings } = useSettingsStore();
    const { name, stat } = usePostStore();
	const { newstitle, newsteam} = useWithImageStore();
	const { individuallyEdit, setEditTeam } = useGlobalStore();
	const { sport } = useUserStore();

	const t = newsteam.toUpperCase();
	const teamObj = props.getTeamObj(t, sport);
	const boxbackground = props.getBoxBackground(teamObj);
    return (
        <div
            className={`newsRight lineupImage ${settings.imageStyle?.value === 'style-3' ? 'hasImage3' : ''}`}
            style={{ background: boxbackground }}>
            {settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
            {individuallyEdit && teamObj && settings.imageStyle?.value !== 'style-3' && (
                <span
                    className={`edit-box left`}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditTeam(0);
                    }}>
                    <i className='fas fa-pencil-alt no-click'></i>
                </span>
            )}
            {newstitle && (
                <div
                    className='lineupTitle'
                    onClick={() => props.selectSettings('header')}>
                    {newstitle}
                </div>
            )}
            {settings.imageStyle?.value !== 'style-3' && (<div
                id={`image1000-news`}
                onClick={(e) => {
                    props.selectSettings('team');
                }}
                className='newsTeam'
                style={{ backgroundImage: teamObj?.logo }}></div>)}
            <span className={newstitle ? 'textBoxes' : 'textBoxes noTitle'}>
                {name.map((text, i) => {
                    return (
                        <span
                            className='textBox'
                            key={i}>
                            <span
                                className='textBoxLeft'
                                style={{color: props.findColor(settings.nameColor, teamObj)}}
                                onClick={(e) => {
                                    props.selectSettings('name');
                                }}>
                                {text}
                            </span>
                            <span
                                className='textBoxRight'
                                style={{color: props.findColor(settings.statColor, teamObj)}}
                                onClick={(e) => {
                                    props.selectSettings('stat');
                                }}>
                                {stat[i]}
                            </span>
                        </span>
                    );
                })}
            </span>
            {settings.newsStyle?.value === 'style-1' && (
                <span className='news-lines'>
                    <span className='news-line-1'></span>
                    <span className='news-line-2'></span>
                    <span className='news-line-3'></span>
                    <span className='news-line-4'></span>
                    <span className='news-line-5'></span>
                </span>
            )}
            {settings.newsStyle?.value === 'style-4' && (
				<div className='lines-div' style={props.getLinesSvg()}></div>
			)}
        </div>
    );
}
