import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { ChromePicker } from './editors/ChromePicker';
import Select from 'react-select';
import type { SingleValue } from 'react-select';
import { Popover } from 'react-tiny-popover';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { settingsStyles } from '../data/styles/settingsStyles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { columnOptions, gridColumnOptions } from '../data/styles/columnOptions';
import { bracketOptions, gridImageOptions, gridOptions, matchupOptions, newsOptions, postImageOptions, postOptions, scoreOptions, tierOptions, chartOptions, comparisonOptions, lineupOptions, jerseyOptions } from '../data/styles/settingsOptions';
import { deleteFile } from '../firebase/firebase';
import { textureOptions } from '../data/styles/textureOptions';
import { settingsStylesDark } from '../data/styles/settingsStylesDark';
import Lock from './Lock';
import { alignmentOptions } from '../data/styles/alignmentOptions';
import { colord } from 'colord';
import useSettingsStore from '../stores/settings.store';
import type { Settings } from '../data/settings/settings';
import { sizeOptions } from '../data/styles/sizeOptions';
import useOptionsStore from '../stores/options.store';
import type { Option } from '../stores/options.store';
import usePreferencesStore from '../stores/preferences.store';
import useModalStore from '../stores/modal.store';
import usePostStore from '../stores/posts/base-post.store';
import useUserStore from '../stores/user.store';
import useGlobalStore from '../stores/global.store';
import useWithImageStore from '../stores/posts/with-image.store';
import { minOptions } from '../data/styles/minOptions';
import { MultipleColorPicker } from './editors/MultipleColorPicker';
import { imageStyleOptions, imageStyleOptionsNews, jerseyVersionOptions, jerseyStyleOptions, styleOptions, styleOptionsNews } from '../data/styles/styleOptions';
import { SpacingEditor } from './editors/SpacingEditor';
import { FontPicker } from './editors/FontPicker';
import { GradientEditor } from './editors/GradientEditor';
import { f1LogoOptions, logoOptions } from '../data/styles/logoOptions';
import useJerseyStore from '../stores/posts/jersey.store';

export default function RichEditor (props: {
	user: { logo: any; _id: any };
	updateUser: (arg0: any) => void;
	setImageDimensions: () => void;
	adjustImage: () => void;
	resetImage: (arg0: Settings) => void;
	getTeamObj: (arg0: any, arg1: any) => any;
	prefill: (arg0: any, arg1: any) => void;
	randomSettings: () => void;
	resetSomeSettings: () => void;
	resetDraggables: () => void;
}): React.JSX.Element {
	const [selectedFooterAlignment, setSelectedFooterAlignment] = useState<{ value: string; label: string } | null>(alignmentOptions[0]);
	const [colorToastLastShown, setColorToastLastShown] = useState<number>(0);
	const [pendingHighlight, setPendingHighlight] = useState<{ text: string; color: string }>({
		text: '',
		color: '#0eff00'
	});
	const [highlightOneOpen, setHighlightOneOpen] = useState<boolean>(false);
	const [highlightTwoOpen, setHighlightTwoOpen] = useState<boolean>(false);
	const [highlightThreeOpen, setHighlightThreeOpen] = useState<boolean>(false);
	const highlightOneRef = useRef(null);
	const highlightTwoRef = useRef(null);
	const highlightThreeRef = useRef(null);
	const { settings, updateSetting, updateSettings } = useSettingsStore();
	const {
		selectedStyle,
		setSelectedStyle,
		selectedDimensions,
		setSelectedDimensions,
		selectedPostColumns,
		setSelectedPostColumns,
		selectedGridColumns,
		setSelectedGridColumns,
		selectedTexture,
		setSelectedTexture,
		selectedAlignment,
		setSelectedAlignment,
		selectedLogoType,
		setSelectedLogoType,
		selectedBackgroundLogoType,
		setSelectedBackgroundLogoType
	} = useOptionsStore();
	const { darkMode } = usePreferencesStore();
	const { toggleModal } = useModalStore();
	const { floatingTexts, setFloatingTexts, name, stat, team, ranks, setName, nameHighlights, statHighlights, setNameHighlights, setStatHighlights } = usePostStore();
	const { lockedSettings, savedPosts, sport, teamOverrides, setTeamOverrides } = useUserStore();
	const { sizeMultiplier, individuallyEdit, setIndividuallyEdit, prefilled, setForceGraphicUpdate, hasDraggables, debug, popovers, setPopovers, richEditorSidebar } = useGlobalStore();
	const { newsteam, newsimagewidth, newsimageheight } = useWithImageStore();
	const { jerseyType, setJerseyType, jerseyVersion, setJerseyVersion } = useJerseyStore();
	const [isLongClick, setIsLongClick] = useState(false);
	const [intervalSetting, setIntervalSetting] = useState<string>('');
	const [direction, setDirection] = useState<string>('');
	const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		if (isLongClick) {
			let og = Number(settings[intervalSetting]);
			intervalRef.current = setInterval(() => {
				if (direction === 'up') og++;
				else og--;
				updateSetting(intervalSetting, og);
			}, 100);
		} else {
			if (intervalRef.current !== null) {
				clearInterval(intervalRef.current);
			}
		}

		return () => {
			if (intervalRef.current !== null) {
				clearInterval(intervalRef.current);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLongClick]);

	useEffect(() => {
		setDropdowns();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStyle, settings]);

	const setDropdowns = (): void => {
		const selected = selectedStyle?.value;
		if (selected === 'team') {
			const texture = textureOptions.find((opt) => opt.value === settings.texture);
			setSelectedTexture(texture as SingleValue<Option>);
			const logo = logoOptions.find((opt) => opt.value === settings.logoType);
			setSelectedLogoType(logo as SingleValue<Option>);
			const options = sport === 'f1' ? f1LogoOptions : logoOptions;
			let backgroundLogo = options.find((opt) => opt.value === settings.backgroundLogoType);
			if (!backgroundLogo) {
				backgroundLogo = options.find((opt) => opt.value === 'primary');
			}
			setSelectedBackgroundLogoType(backgroundLogo as SingleValue<Option>);
		}
		if (selected === 'name' || selected === 'stat') {
			const alignment = minOptions.find((opt) => opt.value === settings[`${selected}Alignment`]);
			setSelectedAlignment(alignment as SingleValue<Option>);
		}
		if (selected === 'post') {
			if (settings.type.includes('post')) {
				const columns = columnOptions.find((col) => col.value === settings.postColumns);
				setSelectedPostColumns(columns as SingleValue<Option>);
			}
			if (settings.type.includes('grid')) {
				const columns = gridColumnOptions.find((col) => col.value === settings.gridColumns);
				setSelectedGridColumns(columns as SingleValue<Option>);
			}
			let width = settings.postWidth;
			let height = settings.postHeight;
			if (settings.type === 'news') {
				width = settings.postWidthNews;
				height = settings.postHeightNews;
			}
			const ratio = height / width;
			const option = sizeOptions.find((size) => {
				const split = size.value.split('x');
				const sizeratio = Number(split[1]) / Number(split[0]);
				return sizeratio === ratio;
			});
			setSelectedDimensions(option as SingleValue<Option>);
		}
		if (selected === 'icon') {
			// set multi color dropdown if reset
		}
	};

	const resetOverrides = (): void => {
		const filteredTeamOverrides = teamOverrides.filter((override: any) => override.isGlobal);
		setTeamOverrides(filteredTeamOverrides);
	};

	const addFloatingText = (): void => {
		setFloatingTexts([
			...floatingTexts,
			{
				text: '',
				font: 'roboto condensed',
				fontSize: 24,
				fontColor: '#ffffff',
				letterSpacing: 1,
				outlineColor: '#ffffff',
				textShadow: 0,
				shadowColor: '#000000',
				xval: 30,
				yval: floatingTexts.length * 10
			}
		]);
	};

	const togglePopover = (e?: any, popover?: string): void => {
		const name: string = e ? e.target.getAttribute('data-popover') : popover;
		if (!popovers.includes(name)) {
			const popoversCopy = [...popovers];
			popoversCopy.push(name);
			setPopovers(popoversCopy);
		} else {
			const popoversCopy = [...popovers];
			const index = popoversCopy.indexOf(name);
			if (index >= 0) {
				popoversCopy.splice(index, 1);
				setPopovers(popoversCopy);
			}
		}
	};

	const changeBackgroundColor = (e: any, key: keyof Settings): void => {
		updateSetting(key, e.target.getAttribute('data-background'));
	};

	const columnChange = (selected: SingleValue<Option>): void => {
		if (settings.type.includes('post')) {
			updateSetting('postColumns', selected?.value ?? null);
			setSelectedPostColumns(selected);
		} else {
			updateSetting('gridColumns', selected?.value ?? null);
			setSelectedGridColumns(selected);
		}
	};

	const isHighlightOrLineup = (): boolean => {
		if (settings.type === 'lineupimage') {
			if (selectedStyle?.value === 'name' || selectedStyle?.value === 'stat') {
				return false;
			}
			return true;
		}
		return settings.type === 'highlightimage';
	};

	const isImageType = (): boolean => {
		return settings.type === 'news' || isHighlightOrLineup();
	}

	const hasSingleTeam = (): boolean => {
		return settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage';
	}

	const colorChange = (color: string, property: keyof Settings): void => {
		// transparency slider changed on primary/secondary/primary darker
		if (color === '#NaNNaNNaN') color = '#ffffff';
		const excludedProperties = ['imageShadowColor', 'imageTitleColor', 'imageLinesColor', 'titleBackground', 'textBackground', 'teamContainerBackground', 'teamBackground', 'teamBackgroundLeft'] as Partial<Settings>;
		if ((settings.type === 'news' || isHighlightOrLineup()) && !excludedProperties.includes(property) && !String(property).includes('News')) {
			property = String(property) + 'News';
		}
		if (lockedSettings.includes(property)) {
			// only show once every 10 seconds
			if (Date.now() - colorToastLastShown >= 10 * 1000) {
				setColorToastLastShown(Date.now());
				toast.error("Can't change locked color");
			}
			return;
		}
		updateSetting(property, color);
	};

	const flipLogos = (e: any): void => {
		const bool = e.target.getAttribute('data-reverse') === 'reverse';
		updateSetting('logoFlip', bool);
	};

	const changeTierTitle = (value: string): void => {
		updateSetting('tierTitle', value);
	};

	const dimensionsChange = (selectedOption: SingleValue<Option>): void => {
		const selectedValue = selectedOption?.value;
		if (!selectedValue) return;
		const newWidth = Number(String(selectedValue).split('x')[0]);
		const newHeight = Number(String(selectedValue).split('x')[1]);
		setSelectedDimensions(selectedOption);
		const newSettings = {
			postWidth: newWidth,
			postHeight: newHeight,
			postWidthNews: newWidth,
			postHeightNews: newHeight
		};
		updateSettings(newSettings);
	};

	const settingsChange = (selectedOption: SingleValue<Option>): void => {
		setSelectedStyle(selectedOption);
	};

	const removeLogo = (): void => {
		const prevLogo = props.user.logo;
		axios
			.put(`/users/addLogo/${props.user._id}`, {
				logo: null
			})
			.then((response) => {
				toast.success('Removed logo', { autoClose: 3000 });
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				localStorage.setItem('token', response.data.data.token);
				props.updateUser(response.data.data.token);
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				deleteFile(prevLogo);
				setForceGraphicUpdate();
			})
			.catch((error) => {
				if (debug) {
					console.log(error);
				}
				toast.error('An unknown error occured.');
			});
	};

	const removeBackground = (): void => {
		const prevBackground = settings.postBackground;
		const count = savedPosts.filter((obj: { settings: { postBackground: string | null } }) => obj.settings.postBackground === prevBackground).length;
		if (count <= 1 && prevBackground && prevBackground.includes('firebase') && prevBackground.includes('o/images')) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			deleteFile(prevBackground);
		}
		updateSetting('postBackground', null);
	};

	const orderList = (): void => {
		if (lockedSettings.includes('inlineOrder')) return;
		const newNames: string[] = [];
		name.map((name: string, i: number) => {
			if (name.match(/\d\. /)) {
				newNames.push(name.substring(name.indexOf('. ') + 2));
			} else {
				if (stat[i]) {
					// if array is sorted and only contains numbers
					if (stat.every((x: any, i: number) => i === 0 || Number(x) <= Number(stat[i - 1])) && !stat.some(value => isNaN(Number(value)))) {
						const currentStat = stat[i];
						const index = stat.findIndex((x: any) => x === currentStat);
						const count = stat.reduce((n: number, x: any) => n + (x === currentStat ? 1 : 0), 0);
						if (count > 1) {
							newNames.push(`T-${index + 1}. ${name}`);
						} else {
							newNames.push(`${i + 1}. ${name}`);
						}
					} else {
						newNames.push(`${i + 1}. ${name}`);
					}
				} else {
					newNames.push(`${i + 1}. ${name}`);
				}
			}
			return name;
		});
		setName(newNames);
	};

	const sliderChange = (value: number | number[], property: keyof Settings): void => {
		const excludedProperties = ['imageShadowSize'] as Partial<Settings>;
		if ((settings.type === 'news' || isHighlightOrLineup() || String(property).includes('image')) && !String(property).includes('texture') && !excludedProperties.includes(property)) {
			property = String(property) + 'News';
		}
		updateSetting(property, value);
		if (property === 'imageZoomNews') {
			props.setImageDimensions();
		}
		if ((settings.type === 'news' || isHighlightOrLineup() || settings.type.includes('image')) && String(property).includes('image')) {
			props.adjustImage();
		}
	};

	const flipPost = (): void => {
		const newSettings: Partial<Settings> = {};
		newSettings.paneSplit = [50, 50];
		newSettings.paneSplitNews = [50, 50];
		if (settings.type === 'matchupimage') {
			if (settings.orientationMatchup === 'rightleft' || settings.orientationMatchup === 'topbottom') {
				newSettings.paneSplitMatchup = [20, 80];
			} else {
				newSettings.paneSplitMatchup = [80, 20];
			}
		}
		let orientationSetting = settings.orientation;
		let orientSettings = 'orientation';
		if (settings.type === 'news') {
			orientationSetting = settings.orientationNews;
			orientSettings = 'orientationNews';
		} else if (settings.type === 'matchupimage') {
			orientationSetting = settings.orientationMatchup;
			orientSettings = 'orientationMatchup';
		} else if (settings.type === 'highlightimage') {
			orientationSetting = settings.orientationHighlight;
			orientSettings = 'orientationHighlight';
		} else if (settings.type === 'lineupimage') {
			orientationSetting = settings.orientationLineup;
			orientSettings = 'orientationLineup';
		}
		switch (orientationSetting) {
			case 'leftright':
				newSettings[orientSettings] = 'topbottom';
				break;
			case 'topbottom':
				newSettings[orientSettings] = 'rightleft';
				break;
			case 'rightleft':
				newSettings[orientSettings] = 'bottomtop';
				break;
			case 'bottomtop':
				newSettings[orientSettings] = 'leftright';
				break;
			default:
			// do nothing
		}
		updateSettings(newSettings);
		props.resetImage({ ...settings, ...newSettings });
		props.adjustImage();
		setForceGraphicUpdate();
	};

	const addHighlight = (): void => {
		if (!pendingHighlight?.text) {
			return;
		}
		if (selectedStyle?.value === 'name') {
			const nameHighlightsCopy = [...nameHighlights];
			nameHighlightsCopy.push(pendingHighlight);
			setNameHighlights(nameHighlightsCopy);
		} else {
			const statHighlightsCopy = [...statHighlights];
			statHighlightsCopy.push(pendingHighlight);
			setStatHighlights(statHighlightsCopy);
		}
		setPendingHighlight({
			...pendingHighlight,
			text: ''
		});
	};

	const removeHighlight = (e: any, i: number): void => {
		e.stopPropagation();
		const selected = selectedStyle?.value;
		if (selected === 'name') {
			const nameHighlightsCopy = [...nameHighlights];
			nameHighlightsCopy.splice(i, 1);
			setNameHighlights(nameHighlightsCopy);
		} else {
			const statHighlightsCopy = [...statHighlights];
			statHighlightsCopy.splice(i, 1);
			setStatHighlights(statHighlightsCopy);
		}
	};

	const handleClickOutside = (event: any): void => {
		if (event.target.closest('.colorPopover') === null) {
			event.preventDefault();
			event.stopPropagation();
			setHighlightOneOpen(false);
			setHighlightTwoOpen(false);
			setHighlightThreeOpen(false);
			document.removeEventListener('click', handleClickOutside, true);
		}
	};

	const toggleColorPickerOne = (): void => {
		setHighlightOneOpen(!highlightOneOpen);
		document.addEventListener('click', handleClickOutside, true);
	};

	const toggleColorPickerTwo = (): void => {
		setHighlightTwoOpen(!highlightTwoOpen);
		document.addEventListener('click', handleClickOutside, true);
	};

	const toggleColorPickerThree = (): void => {
		setHighlightThreeOpen(!highlightThreeOpen);
		document.addEventListener('click', handleClickOutside, true);
	};

	const shouldShowImageShadow = (): boolean => {
		if (selected !== 'image') return false;
		if (settings.type === 'news') {
			if (settings.newsStyle?.value === 'style-2' || settings.newsStyle?.value === 'style-4' || settings.newsStyle?.value === 'style-6') {
				return false;
			}
		}
		if (isHighlightOrLineup()) {
			if (settings.newsStyle?.value === 'style-4') return false;
		}
		return true;
	}

	const handleMouseDown = (fontSizeProperty: string, direction: string): void => {
		if (lockedSettings.includes(fontSizeProperty)) return;
		timeoutRef.current = setTimeout(() => {
			setIntervalSetting(fontSizeProperty);
			setDirection(direction);
			setIsLongClick(true);
		}, 500);
	};

	const handleMouseUp = (): void => {
		if (timeoutRef.current !== null) {
			clearTimeout(timeoutRef.current);
		}
		setIsLongClick(false);
	};

	const handleMouseLeave = (): void => {
		if (timeoutRef.current !== null) {
			clearTimeout(timeoutRef.current);
		}
		setIsLongClick(false);
	};

	const selected = selectedStyle?.value;
	let colorProp = settings.type === 'news' ? `${selected}FontColorNews` : isHighlightOrLineup() ? 'imageTitleColor' : `${selected}FontColor`;
	let backgroundColorProp = settings.type === 'news' || isHighlightOrLineup() ? `${selected}ColorNews` : `${selected}Background`;
	if (selected === 'rank' && settings.type === 'matchupimage') {
		backgroundColorProp = 'matchupRankBackground';
	}
	if (selected === 'points' && settings.type === 'matchupimage') {
		backgroundColorProp = 'matchupPointsBackground';
	}
	if (settings.type === 'highlightimage') {
		backgroundColorProp = selected === 'header' ? 'titleBackground' : 'textBackground';
	} else if (selected === 'header' && !isImageType()) {
		backgroundColorProp = 'titleBackgroundColor';
	}
	if (selected === 'name' || selected === 'stat') colorProp = colorProp.replace('Font', '');
	if (selected === 'subtitle' && (settings.type === 'news' || isHighlightOrLineup())) colorProp = 'nameColorNews';
	let teamObj;
	if ((settings.type === 'news' || isHighlightOrLineup()) && newsteam) {
		teamObj = props.getTeamObj(newsteam, sport);
	}
	let orientationSetting = settings.orientation;
	if (settings.type === 'news') {
		orientationSetting = settings.orientationNews;
	} else if (settings.type === 'matchupimage') {
		orientationSetting = settings.orientationMatchup;
	} else if (settings.type === 'highlightimage') {
		orientationSetting = settings.orientationHighlight;
	} else if (settings.type === 'lineupimage') {
		orientationSetting = settings.orientationLineup;
	}
	let maplength = team.length;
	if (maplength >= 1 && maplength < 5) {
		maplength = 5;
	} else if (maplength >= 100) {
		maplength = 100;
	}
	let postyOptions = postOptions;
	let postyImageOptions = postImageOptions;
	if (maplength >= settings.fivelessBreak) {
		postyOptions = postyOptions.filter((opt) => {
			return opt.value !== 'points';
		});
		postyImageOptions = postyImageOptions.filter((opt) => {
			return opt.value !== 'points';
		});
	}
	if (!ranks.length) {
		postyOptions = postyOptions.filter((opt) => {
			return opt.value !== 'rank';
		});
		postyImageOptions = postyImageOptions.filter((opt) => {
			return opt.value !== 'rank';
		});
	}
	let outlineSetting = `${selected}OutlineSize`;
	if (settings.type.includes('post') && selected === 'rank') {
		outlineSetting = 'boxRankOutlineSize';
	}
	let orientSettings = 'orientation';
	if (settings.type === 'news') {
		orientSettings = 'orientationNews';
	} else if (settings.type === 'matchupimage') {
		orientSettings = 'orientationMatchup';
	} else if (settings.type === 'highlightimage') {
		orientSettings = 'orientationHighlight';
	} else if (settings.type === 'lineupimage') {
		orientSettings = 'orientationLineup';
	}
	const showFooterSettings = settings.hasFooter && selected === 'footer';
	const highlights = selected === 'name' ? nameHighlights : statHighlights;
	let boxWidth = 0;
	const boxImageElement = document.getElementById('boximage0');
	if (boxImageElement !== null) {
		boxWidth = boxImageElement.offsetWidth;
	}
	let fontSizeProperty = `${selected}Size${settings.type === 'news' || isHighlightOrLineup() ? 'News' : ''}`;
	if (selected === 'subtitle') {
		if (settings.type === 'news' || isHighlightOrLineup()) {
			fontSizeProperty = 'subtitleSizeNews';
		} else {
			fontSizeProperty = 'subtitleFontSize';
		}
	}
	if (selected === 'thirdTitle') {
		fontSizeProperty = 'thirdTitleFontSize';
	}
	let side = 'right';
	if (selected === 'image' && settings.imageStyle?.value !== 'style-1') {
		side = 'left';
	}
	let imageContainerHeight = (settings.type === 'news' || isHighlightOrLineup()) ? settings.postHeightNews : settings.postHeight;
	let imageContainerWidth = (settings.type === 'news' || isHighlightOrLineup()) ? settings.postWidthNews : settings.postWidth;
	let imageHeight = newsimageheight;
	let imageWidth = newsimagewidth;
	if (orientationSetting.includes('left')) {
		imageContainerWidth = imageContainerWidth / 2;
	} else {
		imageContainerHeight = imageContainerHeight / 2;
	}
	const newsImage = document.getElementById('newsImage') as HTMLImageElement;
	let aspectRatio;
	if (newsImage) {
		let parentEl = newsImage.parentElement;
		if (settings.imageStyle?.value === 'style-1' && parentEl) {
			parentEl = parentEl.parentElement;
		}
		if (parentEl) {
			imageContainerHeight = parentEl.offsetHeight;
			imageContainerWidth = parentEl.offsetWidth;
			aspectRatio = newsImage.naturalWidth / newsImage.naturalHeight;
			imageHeight = imageContainerHeight * (settings.imageZoomNews / 100);
			imageWidth = imageHeight * aspectRatio;
			if (orientationSetting.includes('top')) {
				imageWidth = imageContainerWidth * (settings.imageZoomNews / 100)
				imageHeight = imageWidth * aspectRatio;
			}
		}
	}
	let numLines = 1;
	if (settings.type.includes('post')) {
		if (selected === 'name') {
			numLines = name.reduce((max, str) => {
				const count = (String(str).match(/##/g) ?? []).length;
				return Math.max(max, count);
			}, 0);
			numLines += 1;
		} else if (selected === 'stat') {
			numLines = stat.reduce((max, str) => {
				const count = (String(str).match(/##/g) ?? []).length;
				return Math.max(max, count);
			}, 0);
			numLines += 1;
		}
	}
	const gradientTypeProp = !isImageType() ? 'gradientType' : side === 'left' ? 'leftBackgroundGradientType' : 'gradientTypeNews';
	const gradientDirectionProp = !isImageType() ? 'gradientDirection' : side === 'left' ? 'leftBackgroundGradientDirection' : 'gradientDirectionNews';
	const gradientStopProp = !isImageType() ? 'gradientStops' : side === 'left' ? 'leftBackgroundGradientStops' : 'gradientStopsNews';
	const alignmentSetting = `${selected}Alignment${isImageType() ? 'News' : ''}`;
	return (
		<div className={'rich-editor' + (individuallyEdit ? ' hide-options' : '') + (richEditorSidebar ? ' in-sidebar' : '')}>
			<div className='floatleft'>
				<span className='editor-dropdown'>
					{settings.type === 'tier' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={tierOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'bracket' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={bracketOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'grid' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={gridOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'gridimage' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={gridImageOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'scores' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={scoreOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'matchupimage' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={matchupOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'post' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={postyOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'postimage' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={postyImageOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{(settings.type === 'news' || settings.type === 'highlightimage') && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={newsOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'chart' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={chartOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'comparison' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={comparisonOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'lineupimage' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={lineupOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
					{settings.type === 'jerseyformat' && (
						<Select
							value={selectedStyle}
							onChange={settingsChange}
							options={jerseyOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
					)}
				</span>
				{selected === 'footer' && (
					<span className='lock-after'>
						<button
							disabled={lockedSettings.includes('hasFooter')}
							type='button'
							className='newButton'
							onClick={() => updateSetting('hasFooter', !settings.hasFooter)}>
							{settings.hasFooter ? 'Remove Footer' : 'Add Footer'}
						</button>
						<Lock
							setting='hasFooter'
							tooltip='Footer'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{(selected === 'header' || selected === 'subtitle' || selected === 'thirdTitle' || selected === 'name' || selected === 'stat' || selected === 'rank' || selected === 'points' || selected === 'tier' || selected === 'description' || selected === 'labels' || selected === 'playerName' || selected === 'playerNumber' || selected === 'icon' || selected === 'banner') && (
					<FontPicker settingName={`${selected}Font`} inline={true}></FontPicker>
				)}
				{(selected === 'header' || selected === 'subtitle' || selected === 'thirdTitle' || selected === 'name' || selected === 'stat' || selected === 'rank' || selected === 'points' || selected === 'tier' || selected === 'description' || selected === 'labels' || selected === 'playerName' || selected === 'playerNumber' || selected === 'icon' || selected === 'banner') && (
					<span className='editor-input lock-after'>
						<Tippy content={<span>Decrease Font Size</span>}>
						<span
							className={`subtract-one ${lockedSettings.includes(fontSizeProperty) ? 'disabled' : ''}`}
							onMouseDown={() => handleMouseDown(fontSizeProperty, 'down')}
							onMouseUp={handleMouseUp}
							onMouseLeave={handleMouseLeave}
							onTouchStart={() => handleMouseDown(fontSizeProperty, 'down')}
							onTouchEnd={handleMouseUp}
							onClick={() => {
								const property = fontSizeProperty;
								if (lockedSettings.includes(property)) return;
								const newValue = Number(settings[property]) - 1;
								updateSetting(property, newValue);
							}}>
							-
						</span>
						</Tippy>
						<Tippy content={<span>Font Size</span>}>
						<input
							disabled={lockedSettings.includes(fontSizeProperty)}
							type='text'
							value={settings[fontSizeProperty]}
							onChange={(e) => {
								const property = fontSizeProperty;
								const newValue = Number(e.target.value);
								updateSetting(property as keyof Settings, newValue);
							}}></input>
						</Tippy>
						<Tippy content={<span>Increase Font Size</span>}>
						<span
							className={`add-one ${lockedSettings.includes(fontSizeProperty) ? 'disabled' : ''}`}
							onMouseDown={() => handleMouseDown(fontSizeProperty, 'up')}
							onMouseUp={handleMouseUp}
							onMouseLeave={handleMouseLeave}
							onTouchStart={() => handleMouseDown(fontSizeProperty, 'up')}
							onTouchEnd={handleMouseUp}
							onClick={() => {
								const property = fontSizeProperty;
								if (lockedSettings.includes(property)) return;
								const newValue = Number(settings[property]) + 1;
								updateSetting(property, newValue);
							}}>
							+
						</span>
						</Tippy>
						<Lock
							setting={fontSizeProperty}
							tooltip='Font Size'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{((selected === 'subtitle' && !isHighlightOrLineup()) || (selected === 'header' && !isHighlightOrLineup()) || selected === 'thirdTitle') && (
					<span className='lock-after'>
						<Tippy content={<span>Text Alignment</span>}>
							<span
								className={`editor-button ${lockedSettings.includes(alignmentSetting) ? 'disabled' : ''}`}
								onClick={() => {
									if (lockedSettings.includes(alignmentSetting)) return;
									let newAlignment = 'left';
									if (settings[alignmentSetting] === 'left') newAlignment = 'center';
									if (settings[alignmentSetting] === 'center') newAlignment = 'right';
									updateSetting(alignmentSetting, newAlignment);
								}}>
								{settings[alignmentSetting] === 'left' && <i className="fa-solid fa-align-left"></i>}
								{settings[alignmentSetting] === 'center' && <i className="fa-solid fa-align-center"></i>}
								{settings[alignmentSetting] === 'right' && <i className="fa-solid fa-align-right"></i>}
							</span>
						</Tippy>
						<Lock
							tooltip='Alignment'
							setting={alignmentSetting}
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{(selected === 'header' || selected === 'subtitle' || selected === 'thirdTitle' || selected === 'name' || selected === 'stat' || selected === 'rank' || selected === 'points' || selected === 'description' || selected === 'labels') && (
					<Tippy content={<span>Text Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover2')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover2')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									{((selected === 'header' || selected === 'subtitle' || selected === 'thirdTitle') && !isImageType())
									? (<div>
										<Lock
											title='Text Color'
											setting={colorProp}
											lockedSettings={lockedSettings}
										/>
										<button
											className={`${settings[colorProp].includes('#') ? 'active-toggle ' : ''}newButton leftToggle`}
											data-background='single'
											disabled={lockedSettings.includes(colorProp)}
											onClick={(e) => colorChange('#ffffff', colorProp)}>
											Single Color
										</button>
										<button
											className={`${settings[colorProp] === 'gradient' ? 'active-toggle ' : ''}newButton rightToggle`}
											data-background='gradient'
											disabled={lockedSettings.includes(colorProp)}
											onClick={(e) => colorChange('gradient', colorProp)}>
											Gradient
										</button>
										<span className='edit-spacer'></span>
										{settings[colorProp].includes('#') && (
											<span>
												<ChromePicker
													color={settings[colorProp]}
													onChange={(color: string) => colorChange(color, colorProp)}
												/>
											</span>
										)}
										{settings[colorProp] === 'gradient' && (
											<GradientEditor typeSetting={`${selected}TextGradientType`} directionSetting={`${selected}TextGradientDirection`} stopsSetting={`${selected}TextGradientStops`}></GradientEditor>
										)}
									</div>)
									: (selected === 'name' || (selected === 'stat' && settings.type !== 'matchupimage' && settings.type !== 'scores'))
									? (<>
										<MultipleColorPicker label='Text Color' settingName={colorProp} defaultColor='#ffffff' colorChange={colorChange} includeBrighterDarker={true} includeNone={false}/>
									</>)
									: (<>
										<Lock
											title='Text Color'
											setting={colorProp}
											lockedSettings={lockedSettings}
										/>
										<ChromePicker
											color={settings[colorProp]}
											onChange={(color: string) => colorChange(color, colorProp.replace('News', ''))}
											presetColors={(settings.type === 'news' || isHighlightOrLineup()) && teamObj ? [teamObj.primary, teamObj.secondary] : []}
										/>
									</>)}
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover2'
								onClick={togglePopover}>
								<span className='colorbox-letter'>A</span>
								<span className='rainbow-bar'></span>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}
				{showFooterSettings && (
					<Tippy content={<span>Text</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover22')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover22')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div
									className='popover-content color-picker'
									onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Footer Text'
										setting='footerText'
										lockedSettings={lockedSettings}
									/>
									<textarea
										disabled={lockedSettings.includes('footerText')}
										rows={1}
										className='settingsInput'
										value={settings.footerText}
										onChange={(event) => {
											updateSetting('footerText', event.target.value);
										}}
									/>
									<Lock
										title='Font'
										setting='footerFont'
										lockedSettings={lockedSettings}
									/>
									<FontPicker settingName='footerFont' inline={false}></FontPicker>
									<span className='edit-spacer'></span>
									<Lock
										title='Text Size'
										setting='footerSize'
										lockedSettings={lockedSettings}
									/>
									<Slider
										disabled={lockedSettings.includes('footerSize')}
										value={settings.footerSize}
										onChange={(value) => sliderChange(value, 'footerSize')}
										min={5}
										max={60}
									/>
									<Lock
										title='Alignment'
										setting='footerAlignment'
										lockedSettings={lockedSettings}
									/>
									<Select
										isDisabled={lockedSettings.includes('footerAlignment')}
										value={selectedFooterAlignment}
										onChange={(selected) => {
											setSelectedFooterAlignment(selected);
											const val = String((selected as any).value);
											updateSetting('footerAlignment', val);
										}}
										options={alignmentOptions}
										styles={darkMode ? settingsStylesDark : settingsStyles}
										placeholder={settings.footerAlignment}
									/>
									<Lock
										title='Text Color'
										setting='footerFontColor'
										lockedSettings={lockedSettings}
									/>
									<ChromePicker
										color={settings.footerFontColor}
										onChange={(color: string) => colorChange(color, 'footerFontColor')}
									/>
								</div>
							}>
							<span
								className='footer-editor'
								data-popover='popover22'
								onClick={togglePopover}>
								<i className='fas fa-font no-click'></i>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}
				{selected === 'stat' && settings.type.includes('post') && team.length >= settings.fivelessBreak && (
					<Tippy content={<span>Background Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover32')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover32')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<MultipleColorPicker label='Background Color' settingName='postStatBackground' defaultColor='#000000' colorChange={colorChange} includeBrighterDarker={true} includeNone={true}/>
									{settings.postStatBackground !== 'none' && (<span>
										<Lock
											title='Background Width'
											setting={'postStatPadding'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('postStatPadding')}
											value={settings.postStatPadding}
											onChange={(value) => sliderChange(value, 'postStatPadding')}
											min={0}
											max={50}
										/>
									</span>)}
									{settings.postStatBackground !== 'none' && (<span>
										<Lock
											title='Background Height'
											setting={'postStatHeight'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('postStatHeight')}
											value={settings.postStatHeight}
											onChange={(value) => sliderChange(value, 'postStatHeight')}
											min={35}
											max={100}
										/>
									</span>)}
									{settings.postStatBackground !== 'none' && (<span>
										<Lock
											title='Background Corner Rounding'
											setting={'postStatBorderRadius'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('postStatBorderRadius')}
											value={settings.postStatBorderRadius}
											onChange={(value) => sliderChange(value, 'postStatBorderRadius')}
											min={0}
											max={100}
										/>
									</span>)}
								</div>
							}>
							<span
								className='colorbox-editor rainbow-box'
								data-popover='popover32'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}
				{((settings.type === 'news' && selected === 'header' && (settings.newsStyle?.value === 'style-1' || settings.newsStyle?.value === 'style-3' || settings.newsStyle?.value === 'style-5' || settings.newsStyle?.value === 'style-6')) ||
					(settings.type === 'highlightimage' && settings.newsStyle?.value === 'style-4' && (selected === 'header' || selected === 'subtitle')) ||
					showFooterSettings ||
					selected === 'tier' ||
					selected === 'labels' ||
					(selected === 'header' && !isHighlightOrLineup()) ||
					(selected === 'subtitle' && !isImageType()) ||
					selected === 'thirdTitle' ||
					((settings.type.includes('grid') || settings.type === 'tier' || settings.type === 'bracket') && (selected === 'name' || selected === 'stat')) ||
					((settings.type.includes('post') || settings.type === 'matchupimage') && (selected === 'points' || selected === 'rank'))) && (
					<Tippy content={<span>Background Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover4')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover4')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									{(!(selected === 'rank' ||
									(selected === 'points' && settings.type === 'matchupimage') ||
									(settings.type === 'highlightimage' && settings.newsStyle?.value === 'style-4' && (selected === 'header' || selected === 'subtitle')) ||
									((selected === 'stat' || selected === 'name')))) && (
										<span>
										<Lock
											title='Background Color'
											setting={backgroundColorProp}
											lockedSettings={lockedSettings}
										/>
										<ChromePicker
											color={settings[backgroundColorProp]}
											onChange={(color: string) => colorChange(color, backgroundColorProp.replace('News', ''))}
											presetColors={(settings.type === 'news' || isHighlightOrLineup()) && teamObj ? [teamObj.primary, teamObj.secondary] : []}
										/>
										</span>)}
									{(selected === 'rank' ||
									(selected === 'points' && settings.type === 'matchupimage') ||
									(settings.type === 'highlightimage' && settings.newsStyle?.value === 'style-4' && (selected === 'header' || selected === 'subtitle')) ||
									((selected === 'stat' || selected === 'name'))) && (
										<MultipleColorPicker label='Background Color' settingName={backgroundColorProp} defaultColor='#000000' colorChange={colorChange} includeBrighterDarker={true} includeNone={selected === 'rank'}/>
									)}
								</div>
							}>
							<span
								className='colorbox-editor rainbow-box'
								data-popover='popover4'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}
				{(selected === 'stat' && settings.type === 'matchupimage') && (
					<Tippy content={<span>Background Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover34')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover34')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Background Color'
										setting={backgroundColorProp}
										lockedSettings={lockedSettings}
									/>
									<ChromePicker
										color={settings[backgroundColorProp]}
										onChange={(color: string) => colorChange(color, backgroundColorProp.replace('News', ''))}
									/>
								</div>
							}>
							<span
								className='colorbox-editor rainbow-box'
								data-popover='popover34'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}
				{((selected === 'actualheader' && settings.type !== 'bracket' && settings.type !== 'scores') ||
					selected === 'header' ||
					selected === 'subtitle' ||
					selected === 'thirdTitle' ||
					selected === 'name' ||
					selected === 'stat' ||
					selected === 'rank' ||
					selected === 'points' ||
					selected === 'player' ||
					selected === 'description' ||
					selected === 'labels' ||
					selected === 'playerName' ||
					selected === 'playerNumber' ||
					showFooterSettings) && (
					<Tippy content={<span>Spacing</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover1')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover1')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content' onClick={(e) => e.stopPropagation()}>
									{selected === 'player' && (
										<span>
											<Lock
												title='Player Order'
												setting='playerOrder'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('playerOrder')}
												value={settings.playerOrder}
												onChange={(value) => sliderChange(value, 'playerOrder')}
												step={1}
												min={-3}
												max={4}
											/>
										</span>
									)}
									{showFooterSettings && (
										<span>
											<Lock
												title='Footer Height'
												setting='footerHeight'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('footerHeight')}
												value={settings.footerHeight}
												onChange={(value) => sliderChange(value, 'footerHeight')}
												min={0}
												max={100}
											/>
										</span>
									)}
									{settings.type !== 'news' && settings.type !== 'highlightimage' && settings.type !== 'lineupimage' && selected !== 'actualheader' && selected !== 'player' && selected !== 'header' && selected !== 'subtitle' && selected !== 'thirdTitle' && (
										<span>
											<Lock
												title={selected === 'labels' ? 'Padding' : selected === 'rank' ? 'Vertical Text Alignment' : 'Vertical Margin'}
												setting={`${selected}Margin`}
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes(`${selected}Margin`)}
												value={settings[`${selected}Margin`]}
												onChange={(value) => sliderChange(value, `${selected}Margin`)}
												min={settings.type === 'matchupimage' ? -100 : selected === 'playerNumber' ? 0 : -15}
												max={settings.type === 'matchupimage' ? 100 : selected === 'playerNumber' ? 50 : settings.type === 'scores' ? 30 : 15}
											/>
										</span>
									)}
									{(settings.type === 'news' || isHighlightOrLineup()) && selected === 'header' && (
										<span>
											<Lock
												title='Text Margin'
												setting='headerMarginNews'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('headerMarginNews')}
												value={settings.headerMarginNews}
												onChange={(value) => sliderChange(value, 'headerMargin')}
												min={-100}
												max={100}
											/>
										</span>
									)}
									{(settings.type === 'news' || isHighlightOrLineup()) && selected === 'subtitle' && (
										<span>
											<Lock
												title='Text Margin'
												setting='subtitleMarginNews'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('subtitleMarginNews')}
												value={settings.subtitleMarginNews}
												onChange={(value) => sliderChange(value, 'subtitleMargin')}
												min={0}
												max={isHighlightOrLineup() ? 40 : 100}
											/>
										</span>
									)}
									{(((settings.type.includes('post') || settings.type === 'matchupimage') && (selected === 'name' || selected === 'stat')) || selected === 'rank' || (settings.type === 'matchupimage' && selected === 'points')) && (
										<span>
											<Lock
												title={selected === 'rank' ? 'Horizontal Text Alignment' : 'Horizontal Margin'}
												setting={`${selected}Margin2`}
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes(`${selected}Margin2`)}
												value={settings[`${selected}Margin2`]}
												onChange={(value) => sliderChange(value, `${selected}Margin2`)}
												min={selected === 'rank' ? -20 : -100}
												max={selected === 'rank' ? 20 : 100}
											/>
										</span>
									)}
									{selected !== 'actualheader' && selected !== 'player' && (
										<span>
											<Lock
												title='Letter Spacing'
												setting={settings.type === 'news' || isHighlightOrLineup() ? `${selected}SpacingNews` : `${selected}Spacing`}
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes(settings.type === 'news' || isHighlightOrLineup() ? `${selected}SpacingNews` : `${selected}Spacing`)}
												value={settings.type === 'news' || isHighlightOrLineup() ? settings[`${selected}SpacingNews`] : settings[`${selected}Spacing`]}
												onChange={(value) => sliderChange(value, `${selected}Spacing`)}
												min={selected === 'playerName' ? -10 : -5}
												max={5}
											/>
										</span>
									)}
									{selected === 'header' && !isImageType() && (
										<span>
											<Lock
												title='Title Margin'
												setting='titleMargin'
												lockedSettings={lockedSettings}
											/>
											<SpacingEditor settingName='titleMargin'></SpacingEditor>
										</span>
									)}
									{selected === 'subtitle' && !isImageType() && (
										<span>
											<Lock
												title='Subtitle Margin'
												setting='subtitleMargin'
												lockedSettings={lockedSettings}
											/>
											<SpacingEditor settingName='subtitleMargin'></SpacingEditor>
										</span>
									)}
									{selected === 'thirdTitle' && !isImageType() && (
										<span>
											<Lock
												title='Subtitle Margin'
												setting='thirdTitleMargin'
												lockedSettings={lockedSettings}
											/>
											<SpacingEditor settingName='thirdTitleMargin'></SpacingEditor>
										</span>
									)}
									{selected === 'header' && !isImageType() && (
										<span>
											<Lock
												title='Title Padding'
												setting='titlePadding'
												lockedSettings={lockedSettings}
											/>
											<SpacingEditor settingName='titlePadding'></SpacingEditor>
										</span>
									)}
									{selected === 'subtitle' && !isImageType() && (
										<span>
											<Lock
												title='Subtitle Padding'
												setting='subtitlePadding'
												lockedSettings={lockedSettings}
											/>
											<SpacingEditor settingName='subtitlePadding'></SpacingEditor>
										</span>
									)}
									{selected === 'thirdTitle' && !isImageType() && (
										<span>
											<Lock
												title='Subtitle Padding'
												setting='thirdTitlePadding'
												lockedSettings={lockedSettings}
											/>
											<SpacingEditor settingName='thirdTitlePadding'></SpacingEditor>
										</span>
									)}
									{(settings.type === 'tier' || settings.type.includes('grid') || settings.type === 'bracket') && (selected === 'name' || selected === 'stat') && (
										<span className='editor-dropdown'>
											<Lock
												title='Alignment'
												setting={`${selected}Alignment`}
												lockedSettings={lockedSettings}
											/>
											<Select
												isDisabled={lockedSettings.includes(`${selected}Alignment`)}
												value={selectedAlignment}
												onChange={(s) => {
													setSelectedAlignment(s);
													const val = String((s as any).value);
													updateSetting(`${selected}Alignment`, val);
												}}
												options={minOptions}
												styles={darkMode ? settingsStylesDark : settingsStyles}
												placeholder={settings[`${selected}Alignment`]}
											/>
										</span>
									)}
									{selected === 'player' && (
										<span
											className='center-span'
											style={{ marginBottom: '10px' }}>
											<Lock
												title='Custom image width'
												setting='nonHeadshot'
												lockedSettings={lockedSettings}
											/>
											<input
												type='checkbox'
												id='nonHeadshot'
												disabled={lockedSettings.includes('nonHeadshot')}
												checked={settings.nonHeadshot}
												onChange={() => updateSetting('nonHeadshot', !settings.nonHeadshot)}
											/>
										</span>
									)}
									{selected === 'player' && settings.nonHeadshot && (
										<span>
											<Lock
												title='Image Width'
												setting='playerAspectRatio'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('playerAspectRatio')}
												value={settings.playerAspectRatio}
												onChange={(value) => sliderChange(value, 'playerAspectRatio')}
												step={0.1}
												min={0.5}
												max={2.5}
											/>
										</span>
									)}
									{selected === 'player' && !settings.nonHeadshot && (
										<span>
											<Lock
												title='Player Image Height'
												setting='playerHeight'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('playerHeight')}
												value={settings.playerHeight}
												onChange={(value) => sliderChange(value, 'playerHeight')}
												step={0.05}
												min={0.5}
												max={1.5}
											/>
										</span>
									)}
									{selected === 'player' && !settings.nonHeadshot && (
										<span>
											<Lock
												title={'Vertical Margin'}
												setting={`${selected}Margin`}
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes(`${selected}Margin`)}
												value={settings[`${selected}Margin`]}
												onChange={(value) => sliderChange(value, `${selected}Margin`)}
												min={0}
												max={20}
											/>
										</span>
									)}
									{settings.type.includes('post') && (selected === 'name' || selected === 'stat') && (
										<span>
											<Lock
												title='Line Height'
												setting={selected === 'name' ? 'lineHeight' : 'statLineHeight'}
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes(selected === 'name' ? 'lineHeight' : 'statLineHeight')}
												value={selected === 'name' ? settings.lineHeight : settings.statLineHeight}
												onChange={(value) => sliderChange(value, selected === 'name' ? 'lineHeight' : 'statLineHeight')}
												step={0.05}
												min={0.4}
												max={1.4}
											/>
										</span>
									)}
									{((settings.type === 'news' && selected === 'header') || (settings.type === 'highlightimage' && selected === 'header')) && (
										<span>
											<Lock
												title='Line Height'
												setting='lineHeightNews'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('lineHeightNews')}
												value={settings.lineHeightNews}
												onChange={(value) => sliderChange(value, 'lineHeight')}
												min={-20}
												max={20}
											/>
										</span>
									)}
									{(settings.type === 'highlightimage' && selected === 'subtitle') && (
										<span>
											<Lock
												title='Line Height'
												setting='badgeLineHeightNews'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('badgeLineHeightNews')}
												value={settings.badgeLineHeightNews}
												onChange={(value) => sliderChange(value, 'badgeLineHeight')}
												min={-15}
												max={15}
											/>
										</span>
									)}
									{(settings.type === 'news' && selected === 'subtitle') && (
										<span>
											<Lock
												title='Line Height'
												setting='lineHeightTextNews'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('lineHeightTextNews')}
												value={settings.lineHeightTextNews}
												onChange={(value) => sliderChange(value, 'lineHeightText')}
												step={0.1}
												min={0.5}
												max={2}
											/>
										</span>
									)}
									{selected === 'actualheader' && (
										<span>
											<Lock
												title='Header Height'
												setting='titleHeight'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('titleHeight')}
												value={settings.titleHeight}
												onChange={(value) => sliderChange(value, 'titleHeight')}
												min={0}
												max={50}
											/>
										</span>
									)}
									{selected === 'rank' && settings.type.includes('post') && (
										<span>
											<Lock
												title='Width'
												setting='rankWidth'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('rankWidth')}
												value={settings.rankWidth}
												onChange={(value) => sliderChange(value, 'rankWidth')}
												min={10}
												max={100}
											/>
										</span>
									)}
									{settings.type.includes('post') && (selected === 'name' || selected === 'stat') && numLines > 1 && (
										Array(numLines).fill(0).map((_x, i) => {
											return <span key={i}>
												<Lock
													title={`Line ${i + 1} Size`}
													setting={`line${i + 1}size${selected === 'stat' ? 'stat' : ''}`}
													lockedSettings={lockedSettings}
												/>
												<Slider
													disabled={lockedSettings.includes(`line${i + 1}size${selected === 'stat' ? 'stat' : ''}`)}
													value={settings[`line${i + 1}size${selected === 'stat' ? 'stat' : ''}`]}
													onChange={(value) => sliderChange(value, `line${i + 1}size${selected === 'stat' ? 'stat' : ''}`)}
													step={5}
													min={10}
													max={100}
												/>
											</span>
										})
									)}
									{showFooterSettings && (
										<span>
											<Lock
												title='Footer Padding'
												setting='footerPadding'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('footerPadding')}
												value={settings.footerPadding}
												onChange={(value) => sliderChange(value, 'footerPadding')}
												min={0}
												max={25}
											/>
										</span>
									)}
								</div>
							}>
							<span
								className='spacing-editor'
								data-popover='popover1'
								onClick={togglePopover}>
								<i className='fas fa-arrow-down-up-across-line no-click'></i>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}
				{(selected === 'header' || selected === 'subtitle' || selected === 'thirdTitle' || selected === 'name' || selected === 'stat' || selected === 'description' || selected === 'labels' || selected === 'points') &&
					settings.type !== 'news' &&
					settings.type !== 'highlightimage' &&
					settings.type !== 'lineupimage' &&
					settings.type !== 'scores' &&
					settings.type !== 'matchupimage' && (
						<span className='lock-after'>
							<Tippy content={settings[`${selected}Transform`] === 'none' ? 'Uppercase' : 'Not Uppercase'}>
								<span
									className={`editor-button ${lockedSettings.includes(`${selected}Transform`) ? 'disabled' : ''}`}
									onClick={() => {
										if (lockedSettings.includes(`${selected}Transform`)) return;
										updateSetting(`${selected}Transform`, settings[`${selected}Transform`] === 'none' ? 'uppercase' : 'none');
									}}>
									aA
								</span>
							</Tippy>
							<Lock
								tooltip='Casing'
								setting={`${selected}Transform`}
								lockedSettings={lockedSettings}
							/>
						</span>
					)}
				{(selected === 'header' || selected === 'subtitle' || selected === 'thirdTitle' || selected === 'name' || selected === 'stat' || selected === 'rank' || selected === 'points' || selected === 'description' || showFooterSettings) &&
					settings.type !== 'news' &&
					settings.type !== 'lineupimage' &&
					settings.type !== 'highlightimage' && (
						<Tippy content={<span>Outline Text</span>}>
						<span
							className='editor-button'>
							<Popover
								isOpen={popovers.includes('popover18')}
								reposition={true}
								containerStyle={{ zIndex: '1000' }}
								onClickOutside={() => togglePopover(null, 'popover18')}
								positions={['top', 'bottom', 'right', 'left']}
								content={
									<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
										<Lock
											title='Text Outline'
											setting={outlineSetting}
											lockedSettings={lockedSettings}
										/>
										<span
											className='editor-input center'
											style={{ marginBottom: '12px' }}>
											<span
												className={`subtract-one ${lockedSettings.includes(outlineSetting) ? 'disabled' : ''}`}
												onMouseDown={() => handleMouseDown(outlineSetting, 'down')}
												onMouseUp={handleMouseUp}
												onMouseLeave={handleMouseLeave}
												onTouchStart={() => handleMouseDown(outlineSetting, 'down')}
												onTouchEnd={handleMouseUp}
												onClick={() => {
													if (lockedSettings.includes(outlineSetting)) return;
													const newValue = Number(settings[outlineSetting]) - 1;
													updateSetting(outlineSetting, newValue);
												}}>
												-
											</span>
											<input
												disabled={lockedSettings.includes(outlineSetting)}
												type='text'
												value={settings[outlineSetting]}
												onChange={(e) => {
													const newValue = Number(e.target.value);
													updateSetting(outlineSetting, newValue);
												}}></input>
											<span
												className={`add-one ${lockedSettings.includes(outlineSetting) ? 'disabled' : ''}`}
												onMouseDown={() => handleMouseDown(outlineSetting, 'up')}
												onMouseUp={handleMouseUp}
												onMouseLeave={handleMouseLeave}
												onTouchStart={() => handleMouseDown(outlineSetting, 'up')}
												onTouchEnd={handleMouseUp}
												onClick={() => {
													if (lockedSettings.includes(outlineSetting)) return;
													const newValue = Number(settings[outlineSetting]) + 1;
													updateSetting(outlineSetting, newValue);
												}}>
												+
											</span>
										</span>
										<Lock
											title='Outline Color'
											setting={`${selected}OutlineColor`}
											lockedSettings={lockedSettings}
										/>
										<ChromePicker
											color={settings[`${selected}OutlineColor`]}
											onChange={(color: string) => colorChange(color, `${selected}OutlineColor`)}
										/>
									</div>
								}>
								<span
									className='text-outline'
									data-popover='popover18'
									onClick={togglePopover}>
									A
								</span>
							</Popover>
						</span>
						</Tippy>
					)}
				{(settings.type.includes('post') || settings.type === 'matchupimage' || settings.type === 'comparison') && (selected === 'name' || selected === 'stat') && (
					<span>
						<Popover
							isOpen={popovers.includes('popover3')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover3')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Text Shadow'
										setting={`${selected}Shadow`}
										lockedSettings={lockedSettings}
									/>
									<Slider
										disabled={lockedSettings.includes(`${selected}Shadow`)}
										value={settings[`${selected}Shadow`]}
										onChange={(value) => sliderChange(value, `${selected}Shadow`)}
										min={-6}
										max={6}
									/>
									<Lock
										title='Shadow Color'
										setting={`${selected}ShadowColor`}
										lockedSettings={lockedSettings}
									/>
									<ChromePicker
										color={settings[`${selected}ShadowColor`]}
										onChange={(color: string) => colorChange(color, `${selected}ShadowColor`)}
									/>
								</div>
							}>
							<Tippy content={<span>Text Shadow</span>}>
							<span
								className='editor-shadow editor-button'
								data-popover='popover3'
								onClick={togglePopover}>
								A
							</span>
							</Tippy>
						</Popover>
					</span>
				)}
				{selected === 'name' && settings.type !== 'scores' && settings.type !== 'matchupimage' && settings.type !== 'comparison' && (
					<span className='lock-after'>
						<Tippy content={String(name[0])?.includes('1. ') ? 'Unorder List' : 'Order List'}>
						<span
							className={`editor-button ${lockedSettings.includes('inlineOrder') ? 'disabled' : ''}`}
							onClick={orderList}>
							<i className='fas fa-list-ol no-click'></i>
						</span>
						</Tippy>
						<Lock
							tooltip='Order'
							setting='inlineOrder'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{selected === 'team' && settings.type !== 'chart' && (
					<span>
						<Popover
							isOpen={popovers.includes('popover6')}
							reposition={true}
							containerStyle={{ zIndex: '1000', overflow: 'auto' }}
							onClickOutside={() => togglePopover(null, 'popover6')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker3' onClick={(e) => e.stopPropagation()}>
									{sport === 'nfl' && (
										<Select
											isDisabled={lockedSettings.includes('logoType')}
											value={selectedLogoType}
											onChange={(selectedOption: SingleValue<Option>) => {
												const val = String((selectedOption as any).value);
												updateSetting('logoType', val);
												setSelectedLogoType(selectedOption);
											}}
											isSearchable={false}
											options={logoOptions}
											styles={darkMode ? settingsStylesDark : settingsStyles}
										/>
									)}
									{(settings.type === 'news' || isHighlightOrLineup()) && (
										<span>
											<Lock
												title='Logo Size'
												setting='logoSizeNews'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('logoSizeNews')}
												value={settings.logoSizeNews}
												onChange={(value) => sliderChange(value, 'logoSize')}
												min={100}
												max={600}
											/>
										</span>
									)}
									{settings.type.includes('grid') && (
										<span>
											<Lock
												title='Logo Size'
												setting='logoSizeGrid'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('logoSizeGrid')}
												value={settings.logoSizeGrid}
												onChange={(value) => sliderChange(value, 'logoSizeGrid')}
												min={50}
												max={200}
											/>
										</span>
									)}
									{settings.type.includes('tier') && (
										<span>
											<Lock
												title='Logo Size'
												setting='logoSizeTier'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('logoSizeTier')}
												value={settings.logoSizeTier}
												onChange={(value) => sliderChange(value, 'logoSizeTier')}
												min={50}
												max={200}
											/>
										</span>
									)}
									{settings.type === 'bracket' && (
										<span>
											<Lock
												title='Logo Size'
												setting='logoSizeBracket'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('logoSizeBracket')}
												value={settings.logoSizeBracket}
												onChange={(value) => sliderChange(value, 'logoSizeBracket')}
												min={50}
												max={200}
											/>
										</span>
									)}
									{(settings.type.includes('post') || settings.type === 'scores' || settings.type === 'matchupimage') && (
										<span>
											<Lock
												title='Logo Size'
												setting='logoSize'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('logoSize')}
												value={settings.logoSize}
												onChange={(value) => sliderChange(value, 'logoSize')}
												min={20}
												max={200}
											/>
											{(!settings.overlap || settings.type === 'scores' || settings.type === 'matchupimage') && (
												<span>
													<Lock
														title='Logo Order'
														setting='logoOrder'
														lockedSettings={lockedSettings}
													/>
													<Slider
														disabled={lockedSettings.includes('logoOrder')}
														value={settings.logoOrder}
														onChange={(value) => sliderChange(value, 'logoOrder')}
														step={settings.type.includes('post') ? 2 : 1}
														min={settings.type.includes('post') ? -2 : 0}
														max={settings.type.includes('post') ? 4 : 1}
													/>
												</span>
											)}
											{settings.overlap && document.getElementById('boximage0') && team.length < 20 && (
												<span>
													<Lock
														title='Logo Position'
														setting='logoPosition'
														lockedSettings={lockedSettings}
													/>
													<Slider
														disabled={lockedSettings.includes('logoPosition')}
														value={settings.logoPosition}
														onChange={(value) => sliderChange(value, 'logoPosition')}
														min={0}
														max={(settings.postWidth - boxWidth) / sizeMultiplier}
													/>
												</span>
											)}
											{settings.overlap && document.getElementById('boximage0') && team.length >= 20 && (
												<span>
													<Lock
														title='Logo Position'
														setting='logoPosition'
														lockedSettings={lockedSettings}
													/>
													<Slider
														disabled={lockedSettings.includes('logoPosition')}
														value={settings.logoPosition}
														onChange={(value) => sliderChange(value, 'logoPosition')}
														min={0}
														max={(settings.postWidth / 2 - boxWidth) / sizeMultiplier}
													/>
												</span>
											)}
										</span>
									)}
									{settings.type !== 'news' && settings.type !== 'highlightimage' && settings.type !== 'lineupimage' && (
										<span>
											<Lock
												title='Logo Shadow'
												setting='logoShadow'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('logoShadow')}
												value={settings.logoShadow}
												onChange={(value) => sliderChange(value, 'logoShadow')}
												min={0}
												max={10}
											/>
										</span>
									)}
									<Lock
										title='Logo Opacity'
										setting='logoOpacity'
										lockedSettings={lockedSettings}
									/>
									<Slider
										disabled={lockedSettings.includes('logoOpacity')}
										value={settings.type === 'news' || isHighlightOrLineup() ? settings.logoOpacityNews : settings.logoOpacity}
										onChange={(value) => sliderChange(value, 'logoOpacity')}
										min={0}
										max={100}
									/>
									{(settings.type === 'scores' || settings.type === 'matchupimage') && (
										<span>
											<Lock
												title='Logo Order'
												setting='logoFlip'
												lockedSettings={lockedSettings}
											/>
											<span className='flexToggles'>
												<button
													disabled={lockedSettings.includes('logoFlip')}
													className={`${settings.logoFlip ? 'active-toggle ' : ''}newButton leftToggle`}
													style={{ height: '47px' }}
													data-reverse='reverse'
													onClick={flipLogos}>
													Same side logos
												</button>
												<button
													disabled={lockedSettings.includes('logoFlip')}
													className={`${!settings.logoFlip ? 'active-toggle ' : ''}newButton rightToggle`}
													style={{ height: '47px' }}
													data-reverse='not-reverse'
													onClick={flipLogos}>
													Opp. side logos
												</button>
											</span>
										</span>
									)}
									{settings.type.includes('post') && (
										<span className='center-span'>
											<Lock
												title='Allow Overlap'
												setting='overlap'
												lockedSettings={lockedSettings}
											/>
											<input
												type='checkbox'
												id='overlap'
												disabled={lockedSettings.includes('overlap')}
												checked={settings.overlap}
												onChange={() => updateSetting('overlap', !settings.overlap)}
											/>
										</span>
									)}
									<span
										className='center-span'
										style={{ marginTop: '10px' }}>
										<Lock
											title='Lighter Logos'
											setting='lighterLogos'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											id='lighterLogos'
											disabled={lockedSettings.includes('lighterLogos')}
											checked={settings.lighterLogos}
											onChange={() => updateSetting('lighterLogos', !settings.lighterLogos)}
										/>
									</span>
									<span>
									<Lock
											title='Vertical Margin'
											setting={settings.type === 'news' || isHighlightOrLineup() ? 'logoVerticalMarginNews' : 'logoVerticalMargin'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes(`logoVerticalMargin${settings.type === 'news' || isHighlightOrLineup() ? 'News' : ''}`)}
											value={settings[settings.type === 'news' || isHighlightOrLineup() ? 'logoVerticalMarginNews' : 'logoVerticalMargin']}
											onChange={(value) => sliderChange(value, 'logoVerticalMargin')}
											min={-50}
											max={50}
										/>
										<Lock
											title='Horizontal Margin'
											setting={settings.type === 'news' || isHighlightOrLineup() ? 'logoHorizontalMarginNews' : 'logoHorizontalMargin'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes(`logoHorizontalMargin${settings.type === 'news' || isHighlightOrLineup() ? 'News' : ''}`)}
											value={settings[settings.type === 'news' || isHighlightOrLineup() ? 'logoHorizontalMarginNews' : 'logoHorizontalMargin']}
											onChange={(value) => sliderChange(value, 'logoHorizontalMargin')}
											min={-50}
											max={50}
										/>
									</span>
									{settings.type.includes('post') && (
										<span>
											<MultipleColorPicker label='Logo Background' settingName='boxImageBackground' defaultColor='#000000' colorChange={colorChange} includeBrighterDarker={true} includeNone={true}/>
											{settings.boxImageBackground !== 'none' && (
												<span style={{ marginTop: '8px', display: 'block' }}>
													<Lock
														title='Drop Shadow'
														setting='logoDropShadow'
														lockedSettings={lockedSettings}
													/>
													<Slider
														disabled={lockedSettings.includes('logoDropShadow')}
														value={settings.logoDropShadow}
														onChange={(value) => sliderChange(value, 'logoDropShadow')}
														min={0}
														max={20}
													/>
												</span>
											)}
										</span>
									)}
									{settings.imageStyle?.value === 'style-3' && (
										<div style={{ marginTop: '15px' }}>
										<MultipleColorPicker label='Background Color' settingName='teamContainerBackground' defaultColor='#000000' colorChange={colorChange}/>
									</div>
									)}
								</div>
							}>
							<span
								className='editor-button editor-button-text editor-button-logo'
								data-popover='popover6'
								onClick={togglePopover}>
								Team Logos
							</span>
						</Popover>
					</span>
				)}

				{selected === 'team' && settings.type.includes('post') && (
					<span>
						<Popover
							isOpen={popovers.includes('popover7')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover7')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content' onClick={(e) => e.stopPropagation()}>
									<span className='center-span'>
										<Lock
											title='Background Logo'
											setting='duplicate'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											id='duplicate'
											disabled={lockedSettings.includes('duplicate')}
											checked={settings.duplicate}
											onChange={() => updateSetting('duplicate', !settings.duplicate)}
											style={{ marginBottom: '10px' }}
										/>
									</span>
									{settings.duplicate && sport === 'nfl' && (
										<Select
											isDisabled={lockedSettings.includes('backgroundLogoType')}
											value={selectedBackgroundLogoType}
											onChange={(selectedOption: SingleValue<Option>) => {
												const val = String((selectedOption as any).value);
												updateSetting('backgroundLogoType', val);
												setSelectedBackgroundLogoType(selectedOption);
											}}
											isSearchable={false}
											options={logoOptions}
											styles={darkMode ? settingsStylesDark : settingsStyles}
										/>
									)}
									{settings.duplicate && sport === 'f1' && (
										<Select
											isDisabled={lockedSettings.includes('backgroundLogoType')}
											value={selectedBackgroundLogoType}
											onChange={(selectedOption: SingleValue<Option>) => {
												const val = String((selectedOption as any).value);
												updateSetting('backgroundLogoType', val);
												setSelectedBackgroundLogoType(selectedOption);
											}}
											isSearchable={false}
											options={f1LogoOptions}
											styles={darkMode ? settingsStylesDark : settingsStyles}
										/>
									)}
									{settings.duplicate && settings.type.includes('post') && (
										<span>
											<Lock
												title='Background Logo Size'
												setting='duplicateLogoSize'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('duplicateLogoSize')}
												value={settings.duplicateLogoSize}
												onChange={(value) => sliderChange(value, 'duplicateLogoSize')}
												min={0}
												max={team.length > 20 ? settings.postWidth / 2 / sizeMultiplier : settings.postWidth / sizeMultiplier}
											/>
											<Lock
												title='Background Logo Position'
												setting='duplicateLogoPosition'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('duplicateLogoPosition')}
												value={settings.duplicateLogoPosition}
												onChange={(value) => sliderChange(value, 'duplicateLogoPosition')}
												min={0}
												max={team.length > 20 || settings.type.includes('image') ? (settings.postWidth - settings.duplicateLogoSize) / 2 / sizeMultiplier : settings.postWidth / sizeMultiplier - settings.duplicateLogoSize}
											/>
											<Lock
												title='Background Logo Opacity'
												setting='duplicateLogoOpacity'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('duplicateLogoOpacity')}
												value={settings.duplicateLogoOpacity}
												onChange={(value) => sliderChange(value, 'duplicateLogoOpacity')}
												min={0}
												max={100}
											/>
										</span>
									)}
								</div>
							}>
							<span
								className='editor-button editor-button-text'
								data-popover='popover7'
								onClick={togglePopover}>
								Background Logos
							</span>
						</Popover>
					</span>
				)}

				{selected === 'team' && settings.type !== 'chart' && (
					<Tippy content={<span>Textures</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover21')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover21')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div
									className='popover-content'
									onClick={(e) => e.stopPropagation()}>
									<span className='editor-dropdown'>
										<Lock
											title='Pattern'
											setting='texture'
											lockedSettings={lockedSettings}
										/>
										<Select
											isDisabled={lockedSettings.includes('duplicateLogoOpacity')}
											value={selectedTexture}
											onChange={(selectedOption: SingleValue<Option>) => {
												const val = String((selectedOption as any).value);
												updateSetting('texture', val);
												setSelectedTexture(selectedOption);
											}}
											isSearchable={false}
											options={textureOptions}
											styles={darkMode ? settingsStylesDark : settingsStyles}
										/>
									</span>
									{settings.texture && (
										<span>
											<Lock
												title='Size'
												setting='textureSize'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('textureSize')}
												value={settings.textureSize}
												onChange={(value) => sliderChange(value, 'textureSize')}
												min={1}
												max={20}
											/>
										</span>
									)}
									{settings.texture && (
										<span>
											<Lock
												title='Opacity'
												setting='textureOpacity'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('textureOpacity')}
												value={settings.textureOpacity}
												onChange={(value) => sliderChange(value, 'textureOpacity')}
												min={1}
												max={30}
											/>
										</span>
									)}
								</div>
							}>
							<span
								className='colorbox-editor texture-box'
								data-popover='popover21'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'image' && (
					<span className='editor-dropdown lock-after'>
						<Select
							isDisabled={lockedSettings.includes('imageStyle')}
							value={settings.imageStyle}
							onChange={(selectedOption: SingleValue<Option>) => {
								updateSetting('imageStyle', selectedOption);
							}}
							options={
								(isHighlightOrLineup() || (settings.type === 'news' && settings.newsStyle?.value === 'style-6'))
									? imageStyleOptions
									: imageStyleOptionsNews
							}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
						<Lock
							tooltip='Style'
							setting='imageStyle'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}

				{(selected === 'image' && settings.imageStyle?.value === 'style-3') && (
					<Tippy content={<span>Style Options</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover29')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover29')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Background Text'
										setting='backgroundText'
										lockedSettings={lockedSettings}
									/>
									<textarea
										disabled={lockedSettings.includes('backgroundText')}
										rows={1}
										className='settingsInput'
										value={settings.backgroundText}
										onChange={(e) => {
											updateSetting('backgroundText', e.target.value);
										}}
									/>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover29'
								onClick={togglePopover}>
									<i className="fa-solid fa-gear"></i>
								</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'rank' && settings.type.includes('post') && (
					<Tippy content={<span>Rank Options</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover30')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover30')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<span className='center-span'>
										<Lock
											title='Rank inside box'
											setting='rankInside'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											disabled={lockedSettings.includes('rankInside')}
											checked={settings.rankInside}
											onChange={() => updateSetting('rankInside', !settings.rankInside)}
										/>
									</span>
									{!settings.rankInside && (
										<span style={{ display: 'block', marginTop: '12px' }}>
											<Lock
												title='Height'
												setting='rankOutsideMargin'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('rankOutsideMargin')}
												value={settings.rankOutsideMargin}
												onChange={(value) => sliderChange(value, 'rankOutsideMargin')}
												min={-30}
												max={0}
											/>
										</span>
									)}
									{!settings.rankInside && (
										<span style={{ display: 'block', marginTop: '12px' }}>
											<Lock
												title='Right Margin'
												setting='rankMarginReal'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('rankMarginReal')}
												value={settings.rankMarginReal}
												onChange={(value) => sliderChange(value, 'rankMarginReal')}
												min={0}
												max={25}
											/>
										</span>
									)}
									{!settings.rankInside && (
										<span>
											<Lock
												title='Background Shape'
												setting='rankBorderRadius'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('rankBorderRadius')}
												value={settings.rankBorderRadius}
												onChange={(value) => sliderChange(value, 'rankBorderRadius')}
												min={0}
												max={50}
											/>
										</span>
									)}
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover30'
								onClick={togglePopover}>
									<i className="fa-solid fa-gear"></i>
								</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{(selected === 'team' || (selected === 'image' && settings.imageStyle?.value !== 'style-1' && hasSingleTeam())) && (
					<Tippy content={<span>Gradient</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover8')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover8')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Background Color'
										setting={`teamBackground${side === 'left' ? 'Left' : ''}`}
										lockedSettings={lockedSettings}
									/>
									<button
										disabled={lockedSettings.includes(`teamBackground${side === 'left' ? 'Left' : ''}`)}
										className={`${settings[`teamBackground${side === 'left' ? 'Left' : ''}`] !== 'gradient' ? 'active-toggle ' : ''}newButton leftToggle`}
										data-background='primary'
										onClick={(e) => changeBackgroundColor(e, `teamBackground${side === 'left' ? 'Left' : ''}`)}>
										Single Color
									</button>
									<button
										disabled={lockedSettings.includes(`teamBackground${side === 'left' ? 'Left' : ''}`)}
										className={`${settings[`teamBackground${side === 'left' ? 'Left' : ''}`] === 'gradient' ? 'active-toggle ' : ''}newButton rightToggle`}
										data-background='gradient'
										onClick={(e) => changeBackgroundColor(e, `teamBackground${side === 'left' ? 'Left' : ''}`)}>
										Gradient
									</button>
									<span className='edit-spacer'></span>
									{settings[`teamBackground${side === 'left' ? 'Left' : ''}`] !== 'gradient' && (
										<MultipleColorPicker label='Color' settingName={`teamBackground${side === 'left' ? 'Left' : ''}`} defaultColor='#000000' colorChange={colorChange} includeBrighterDarker={true}/>
									)}
									{settings[`teamBackground${side === 'left' ? 'Left' : ''}`] === 'gradient' && (
										<GradientEditor typeSetting={gradientTypeProp} directionSetting={gradientDirectionProp} stopsSetting={gradientStopProp} useUnusedColor={true} showPresets={true}></GradientEditor>
									)}
								</div>
							}>
							<span
								className='colorbox-editor gradient-box'
								data-popover='popover8'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'layout' && (settings.type.includes('grid') || settings.type === 'scores' || settings.type.includes('post') || settings.type === 'matchupimage' || settings.type === 'comparison') && (
					<Tippy content={<span>Box Styles</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover9')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover9')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content' onClick={(e) => e.stopPropagation()}>
									<span>
										<Lock
											title='Box Shape'
											setting='borderRadius'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('borderRadius')}
											value={settings.borderRadius}
											onChange={(value) => sliderChange(value, 'borderRadius')}
											min={0}
											max={100}
										/>
									</span>
									{settings.type === 'scores' && (
										<span>
											<Lock
												title='Box Margin'
												setting='scoreMargin'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('scoreMargin')}
												value={settings.scoreMargin}
												onChange={(value) => sliderChange(value, 'scoreMargin')}
												min={0}
												max={25}
											/>
										</span>
									)}
									{(settings.type.includes('grid') || settings.type === 'comparison') && (
										<span>
											<Lock
												title='Box Margin'
												setting='gridMargin'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('gridMargin')}
												value={settings.gridMargin}
												onChange={(value) =>
													sliderChange(value, 'gridMargin')
												}
												min={0}
												max={15}
											/>
										</span>
									)}
									{settings.type.includes('post') && (
										<span>
											<Lock
												title='Box Margin'
												setting='boxMargin'
												lockedSettings={lockedSettings}
											/>
											<SpacingEditor settingName='boxMargin'></SpacingEditor>
										</span>
									)}
									{settings.type === 'scores' && (
										<span>
											<Lock
												title='Min. Box Height'
												setting='scoreMinHeight'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('scoreMinHeight')}
												value={settings.scoreMinHeight}
												onChange={(value) => sliderChange(value, 'scoreMinHeight')}
												min={50}
												max={400}
											/>
										</span>
									)}
									{(settings.type.includes('post') || settings.type.includes('grid')) && (
									<span
										className='editor-dropdown center-span'
										style={{ marginBottom: '5px', marginTop: '15px' }}>
										<Lock
											title='Number of Columns'
											setting={settings.type.includes('post') ? 'postColumns' : 'gridColumns'}
											lockedSettings={lockedSettings}
										/>
										<Select
											isDisabled={lockedSettings.includes(settings.type.includes('post') ? 'postColumns' : 'gridColumns')}
											value={settings.type.includes('post') ? selectedPostColumns : selectedGridColumns}
											onChange={columnChange}
											options={settings.type.includes('post') ? columnOptions : gridColumnOptions}
											styles={darkMode ? settingsStylesDark : settingsStyles}
											isSearchable={false}
										/>
									</span>)}
									{settings.type.includes('post') && (
										<span className='editor-input center-span'>
											<Lock
												title='Split text to separate lines if teams less than #'
												setting='fivelessBreak'
												lockedSettings={lockedSettings}
											/>
											<input
												disabled={lockedSettings.includes('fivelessBreak')}
												type='number'
												value={settings.fivelessBreak}
												onChange={(e) => {
													updateSetting('fivelessBreak', Number(e.target.value));
												}}></input>
										</span>
									)}
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover9'
								onClick={togglePopover}>
									<span className="material-symbols-outlined">
										view_agenda
									</span>
								</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'post' && (
					<span className='editor-dropdown lock-after'>
						<Select
							isDisabled={lockedSettings.includes('dimensions')}
							value={selectedDimensions}
							onChange={dimensionsChange}
							options={sizeOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
						<Lock
							tooltip='Dimensions'
							setting='dimensions'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}

				{selected === 'post' && settings.type === 'comparison' && (
					<Tippy content={<span>Box Styles</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover25')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover25')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content' onClick={(e) => e.stopPropagation()}>
									<span>
										<Lock
											title='Box Shape'
											setting='comparisonRadius'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('comparisonRadius')}
											value={settings.comparisonRadius}
											onChange={(value) => sliderChange(value, 'comparisonRadius')}
											min={0}
											max={100}
										/>
									</span>
									<span>
										<Lock
											title='Box Margin'
											setting='comparisonMargin'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('comparisonMargin')}
											value={settings.comparisonMargin}
											onChange={(value) => sliderChange(value, 'comparisonMargin')}
											min={0}
											max={100}
										/>
									</span>
								</div>
							}>
							<span
								className='box-editor'
								data-popover='popover25'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'post' && settings.type === 'comparison' && (
					<Tippy content={<span>Border</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover26')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover26')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<span>
										<Lock
											title='Border Width'
											setting='comparisonBorderSize'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('comparisonBorderSize')}
											value={settings.comparisonBorderSize}
											onChange={(value) => sliderChange(value, 'comparisonBorderSize')}
											min={0}
											max={5}
										/>
									</span>
									<Lock
										title='Border Color'
										setting={'comparisonBorderColor'}
										lockedSettings={lockedSettings}
									/>
									<ChromePicker
										color={settings.comparisonBorderColor}
										onChange={(color: string) => colorChange(color, 'comparisonBorderColor')}
									/>
								</div>
							}>
							<span
								className='colorbox-editor border-box'
								data-popover='popover26'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{((selected === 'post' && settings.type !== 'news' && settings.type !== 'matchupimage' && settings.type !== 'lineupimage' && settings.type !== 'highlightimage') ||
					(selected === 'image' && settings.imageStyle?.value !== 'style-1' && !hasSingleTeam()) ||
					(selected === 'actualheader' && settings.type !== 'news')) && (
					<Tippy content={<span>Background Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover13')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover13')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									{(selected === 'post' || selected === 'image') && (
										<div>
											<Lock
												title='Background Color'
												setting='backgroundColor'
												lockedSettings={lockedSettings}
											/>
											<button
												className={`${settings.backgroundColor === 'single' ? 'active-toggle ' : ''}newButton leftToggle`}
												data-background='single'
												disabled={lockedSettings.includes('backgroundColor')}
												onClick={(e) => changeBackgroundColor(e, 'backgroundColor')}>
												Single Color
											</button>
											<button
												className={`${settings.backgroundColor === 'gradient' ? 'active-toggle ' : ''}newButton rightToggle`}
												data-background='gradient'
												disabled={lockedSettings.includes('backgroundColor')}
												onClick={(e) => changeBackgroundColor(e, 'backgroundColor')}>
												Gradient
											</button>
											<span className='edit-spacer'></span>
											{settings.backgroundColor === 'single' && (
												<span>
													<Lock
														title='Color'
														setting='headerColor'
														lockedSettings={lockedSettings}
													/>
													<ChromePicker
														color={settings.headerColor}
														onChange={(color: string) => colorChange(color, 'headerColor')}
													/>
												</span>
											)}
											{settings.backgroundColor === 'gradient' && (
												<GradientEditor typeSetting='backgroundGradientType' directionSetting='backgroundGradientDirectionDeg' stopsSetting='backgroundGradientStops'></GradientEditor>
											)}
										</div>
									)}
									{selected === 'actualheader' && (
										<span>
											<Lock
												title='Header Color'
												setting='actualHeaderColor'
												lockedSettings={lockedSettings}
											/>
											<ChromePicker
												color={settings.actualHeaderColor}
												onChange={(color: string) => colorChange(color, 'actualHeaderColor')}
											/>
										</span>
									)}
								</div>
							}>
							<span
								className='colorbox-editor rainbow-box'
								data-popover='popover13'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'actualheader' && settings.type !== 'news' && (
					<Tippy content={<span>{!props.user.logo ? 'Watermark' : 'Logo Width'}</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover10')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover10')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div
									className='popover-content color-picker'
									onClick={(e) => e.stopPropagation()}>
									{!props.user.logo && (
										<span>
											<Lock
												title='Watermark Text'
												setting='watermark'
												lockedSettings={lockedSettings}
											/>
											<textarea
												disabled={lockedSettings.includes('watermark')}
												rows={1}
												className='settingsInput'
												value={settings.watermark}
												onChange={(e) => {
													updateSetting('watermark', e.target.value);
												}}
											/>
											<Lock
												title='Watermark Font'
												setting='watermarkFont'
												lockedSettings={lockedSettings}
											/>
											<FontPicker settingName='watermarkFont' inline={false}></FontPicker>
											<span className='edit-spacer'></span>
											<Lock
												title='Watermark Size'
												setting='watermarkSize'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('watermarkSize')}
												value={settings.watermarkSize}
												onChange={(value) => sliderChange(value, 'watermarkSize')}
												min={0}
												max={60}
											/>
										</span>
									)}
									<Lock
										title={!props.user.logo ? 'Watermark Width' : 'Logo Width'}
										setting='watermarkWidth'
										lockedSettings={lockedSettings}
									/>
									<Slider
										disabled={lockedSettings.includes('watermarkWidth')}
										value={settings.watermarkWidth}
										onChange={(value) => sliderChange(value, 'watermarkWidth')}
										min={0}
										max={200}
									/>
									{!props.user.logo && (
										<span>
											<Lock
												title='Watermark Color'
												setting='watermarkColor'
												lockedSettings={lockedSettings}
											/>
											<ChromePicker
												color={settings.watermarkColor}
												onChange={(color: string) => colorChange(color, 'watermarkColor')}
											/>
										</span>
									)}
								</div>
							}>
							<span
								className='watermark-editor'
								data-popover='popover10'
								onClick={togglePopover}>
								{!props.user.logo && <i className='far fa-copyright no-click'></i>}
								{props.user.logo && <i className='fas fa-arrows-alt-h no-click'></i>}
							</span>
						</Popover>
					</span>
					</Tippy>
				)}
				{selected === 'actualheader' && settings.type !== 'news' && !props.user.logo && (
					<span className='lock-after'>
						<Tippy content={<span>Add Logo</span>}>
						<span
							className={`editor-button ${lockedSettings.includes('userLogo') ? 'disabled' : ''}`}
							onClick={() => {
								if (lockedSettings.includes('userLogo')) return;
								toggleModal('logo');
							}}>
							<i className='far fa-image no-click'></i>
						</span>
						</Tippy>
						<Lock
							tooltip='Logo'
							setting='userLogo'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{selected === 'actualheader' && settings.type !== 'news' && props.user.logo && (
					<span className='lock-after'>
						<Tippy content={<span>Remove Logo</span>}>
						<span
							className={`editor-button doubleicon ${lockedSettings.includes('userLogo') ? 'disabled' : ''}`}
							onClick={() => {
								if (lockedSettings.includes('userLogo')) return;
								removeLogo();
							}}>
							<i className='far fa-image no-click'></i>
							<i className='fa-solid fa-x no-click secondicon'></i>
						</span>
						</Tippy>
						<Lock
							tooltip='Logo'
							setting='userLogo'
							lockedSettings={lockedSettings}
							hideReset={true}
						/>
					</span>
				)}
				{((selected === 'post' && settings.type !== 'news' && settings.type !== 'matchupimage' && settings.type !== 'lineupimage' && settings.type !== 'highlightimage' && !settings.postBackground) ||
				(selected === 'image' && settings.imageStyle?.value !== 'style-1' && !hasSingleTeam() && !settings.postBackground)) && (
					<span className='lock-after'>
						<Tippy content={<span>Background Image</span>}>
						<span
							className={`editor-button ${lockedSettings.includes('postBackground') ? 'disabled' : ''}`}
							onClick={() => {
								if (lockedSettings.includes('postBackground')) return;
								toggleModal('background');
							}}>
							<i className='far fa-image no-click'></i>
						</span>
						</Tippy>
						<Lock
							tooltip='Background'
							setting='postBackground'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
				{((selected === 'post' && settings.type !== 'news' && settings.type !== 'matchupimage' && settings.type !== 'lineupimage' && settings.type !== 'highlightimage' && settings.postBackground) ||
				(selected === 'image' && settings.imageStyle?.value !== 'style-1' && !hasSingleTeam() && settings.postBackground)) && (
					<span className='lock-after'>
						<Tippy content={<span>Remove Background</span>}>
						<span
							className={`editor-button doubleicon ${lockedSettings.includes('postBackground') ? 'disabled' : ''}`}
							onClick={() => {
								if (lockedSettings.includes('postBackground')) return;
								removeBackground();
							}}>
							<i className='far fa-image no-click'></i>
							<i className='fa-solid fa-x no-click secondicon'></i>
						</span>
						</Tippy>
						<Lock
							tooltip='Background'
							setting='postBackground'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{selected === 'chart' && (
					<span className='lock-after'>
						<span className='editor-dropdown'>
							<Select
								isDisabled={lockedSettings.includes('chartType')}
								value={settings.chartType}
								onChange={(selectedOption) => {
									updateSetting('chartType', selectedOption);
									const val = selectedOption?.value;
									if (prefilled && val) {
										props.prefill(null, val);
									}
								}}
								options={[
									{ value: 'bar', label: 'Bar Chart' },
									{ value: 'scatter', label: 'Scatter Chart' }
								]}
								styles={darkMode ? settingsStylesDark : settingsStyles}
							/>
						</span>
						<Lock
							tooltip='Chart Type'
							setting='chartType'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{selected === 'chart' && settings.chartType?.value === 'bar' && (
					<span className='lock-after'>
						<Tippy content={<span>Flip Chart</span>}>
						<span
							className={`editor-button ${lockedSettings.includes('indexAxis') ? 'disabled' : ''}`}
							onClick={() => {
								if (lockedSettings.includes('indexAxis')) return;
								updateSetting('indexAxis', settings.indexAxis === 'y' ? 'x' : 'y');
							}}>
							<i className='fa-solid fa-arrows-spin no-click'></i>
						</span>
						</Tippy>
						<Lock
							tooltip='Orientation'
							setting='indexAxis'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}
				{selected === 'chart' && (
					<Tippy content={<span>Labels</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover12')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover12')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div
									className='popover-content color-picker'
									onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Label Font'
										setting='labelFont'
										lockedSettings={lockedSettings}
									/>
									<span className='editor-dropdown'>
										<FontPicker settingName='labelFont' inline={false}></FontPicker>
									</span>
									<span>
										<Lock
											title='Label Size'
											setting='labelSize'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('labelSize')}
											value={settings.labelSize}
											onChange={(value) => sliderChange(value, 'labelSize')}
											min={10}
											max={20}
										/>
									</span>
									{settings.chartType?.value === 'scatter' && (
										<span>
											<Lock
												title='Logo Size'
												setting='chartLogoSize'
												lockedSettings={lockedSettings}
											/>
											<Slider
												disabled={lockedSettings.includes('chartLogoSize')}
												value={settings.chartLogoSize}
												onChange={(value) => sliderChange(value, 'chartLogoSize')}
												min={50}
												max={120}
											/>
										</span>
									)}
									<Lock
										title='Label Color'
										setting='labelColor'
										lockedSettings={lockedSettings}
									/>
									<ChromePicker
										color={settings.labelColor}
										onChange={(color: string) => colorChange(color, 'labelColor')}
									/>
									{settings.chartType?.value === 'bar' && (<span className='center-span' style={{ marginTop: '10px' }}>
										<Lock
											title='Show values on bars'
											setting='barValues'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											disabled={lockedSettings.includes('barValues')}
											checked={settings.barValues}
											onChange={() => updateSetting('barValues', !settings.barValues)}
										/>
									</span>)}
								</div>
							}>
							<span
								className='spacing-editor'
								data-popover='popover12'
								onClick={togglePopover}>
								<i className='fa-solid fa-tag no-click'></i>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'chart' && (
					<Tippy content={<span>Border</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover17')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover17')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<span>
										<Lock
											title='Border Width'
											setting='chartBorderWidth'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('chartBorderWidth')}
											value={settings.chartBorderWidth}
											onChange={(value) => sliderChange(value, 'chartBorderWidth')}
											min={0.5}
											max={4}
											step={0.5}
										/>
									</span>
									<Lock
										title='Border Color'
										setting='chartBorderColor'
										lockedSettings={lockedSettings}
									/>
									<ChromePicker
										color={settings.chartBorderColor}
										onChange={(color: string) => colorChange(color, 'chartBorderColor')}
									/>
								</div>
							}>
							<span
								className='colorbox-editor border-box'
								data-popover='popover17'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{((selected === 'team' && settings.type !== 'news' && settings.type !== 'chart' && settings.type !== 'lineupimage' && settings.type !== 'highlightimage') || selected === 'actualheader') && (
					<Tippy content={<span>Border</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover14')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover14')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<span>
										<Lock
											title='Border Width'
											setting={selected === 'actualheader' ? 'headerBorderSize' : 'borderSize'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes(selected === 'actualheader' ? 'headerBorderSize' : 'borderSize')}
											value={selected === 'actualheader' ? settings.headerBorderSize : settings.borderSize}
											onChange={(value) => sliderChange(value, selected === 'actualheader' ? 'headerBorderSize' : 'borderSize')}
											min={0}
											max={5}
										/>
									</span>
									{(!(selected === 'team' && (settings.type.includes('grid') || settings.type === 'bracket'))) && (
										<span>
											<Lock
												title='Border Color'
												setting={selected === 'actualheader' ? 'headerBorderColor' : 'borderColor'}
												lockedSettings={lockedSettings}
											/>
											<ChromePicker
												color={selected === 'actualheader' ? settings.headerBorderColor : settings.borderColor}
												onChange={(color: string) => colorChange(color, selected === 'actualheader' ? 'headerBorderColor' : 'borderColor')}
											/>
										</span>)}
									{(selected === 'team' && (settings.type.includes('grid') || settings.type === 'bracket')) && (
										<MultipleColorPicker label='Border Color' settingName='borderColor' defaultColor='#ffffff' colorChange={colorChange} includeBrighterDarker={true}/>
									)}
								</div>
							}>
							<span
								className='colorbox-editor border-box'
								data-popover='popover14'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{(selected === 'team' && (settings.type.includes('grid') || settings.type === 'bracket' || settings.type.includes('post'))) && (
					<Tippy content={<span>Box Glow</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover27')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover27')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<span>
										<Lock
											title='Box Glow Width'
											setting={'boxGlowWidth'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('boxGlowWidth')}
											value={settings.boxGlowWidth}
											onChange={(value) => sliderChange(value, 'boxGlowWidth')}
											min={0}
											max={25}
										/>
									</span>
									<MultipleColorPicker label='Box Glow Color' settingName='boxGlowColor' defaultColor='#ffffff' colorChange={colorChange} includeBrighterDarker={true}/>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover27'
								onClick={togglePopover}>
									<span className='material-symbols-outlined' style={{ fontSize: '1.1em' }}>
										flare
									</span>
								</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{(selected === 'rank' && settings.type.includes('post') && !settings.rankInside) && (
					<Tippy content={<span>Box Glow</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover31')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover31')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<span>
										<Lock
											title='Box Glow Width'
											setting={'rankGlowWidth'}
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('rankGlowWidth')}
											value={settings.rankGlowWidth}
											onChange={(value) => sliderChange(value, 'rankGlowWidth')}
											min={0}
											max={25}
										/>
									</span>
									<MultipleColorPicker label='Box Glow Color' settingName='rankGlowColor' defaultColor='#ffffff' colorChange={colorChange} includeBrighterDarker={true}/>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover31'
								onClick={togglePopover}>
									<span className='material-symbols-outlined' style={{ fontSize: '1.1em' }}>
										flare
									</span>
								</span>
						</Popover>
					</span>
					</Tippy>
				)}
				{selected === 'tier' && (
					<Tippy content={<span>Text Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover15')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover15')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Text Color'
										setting='tierColor'
										lockedSettings={lockedSettings}
									/>
									<ChromePicker
										color={settings.tierColor}
										onChange={(color: string) => colorChange(color, 'tierColor')}
									/>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover15'
								onClick={togglePopover}>
								<span className='colorbox-letter'>A</span>
								<span className='rainbow-bar'></span>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}
				{selected === 'layout' && settings.type === 'tier' && (
					<span className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover19')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover19')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content' onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Tier Titles'
										setting='tierTitle'
										lockedSettings={lockedSettings}
									/>
									<span className='setting-radio'>
										<label>
											<input
												type='radio'
												name='tierradio'
												disabled={lockedSettings.includes('tierTitle')}
												checked={settings.tierTitle === 'left'}
												value='left'
												onChange={() => changeTierTitle('left')}
											/>
											Left
										</label>
										<label>
											<input
												type='radio'
												name='tierradio'
												disabled={lockedSettings.includes('tierTitle')}
												checked={settings.tierTitle === 'top'}
												value='top'
												onChange={() => changeTierTitle('top')}
											/>
											Top
										</label>
										<label>
											<input
												type='radio'
												name='tierradio'
												disabled={lockedSettings.includes('tierTitle')}
												checked={settings.tierTitle === 'bottom'}
												value='bottom'
												onChange={() => changeTierTitle('bottom')}
											/>
											Bottom
										</label>
									</span>
									<span className='center-span'>
										<Lock
											title='Pyramid Styling (same width)'
											setting='pyramid'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											disabled={lockedSettings.includes('pyramid')}
											checked={settings.pyramid}
											onChange={() => updateSetting('pyramid', !settings.pyramid)}
										/>
									</span>
									<span className='center-span' style={{ marginTop: '10px' }}>
										<Lock
											title='Same box height (when row splits)'
											setting='unevenTiers'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											disabled={lockedSettings.includes('unevenTiers')}
											checked={settings.unevenTiers}
											onChange={() => updateSetting('unevenTiers', !settings.unevenTiers)}
										/>
									</span>
									<span className='center-span' style={{ marginTop: '10px' }}>
										<Lock
											title='Max teams per row (#)'
											setting='tierSplit'
											lockedSettings={lockedSettings}
										/>
										<input
											type='text'
											disabled={lockedSettings.includes('tierSplit')}
											value={settings.tierSplit}
											onChange={(e) => {
												updateSetting('tierSplit', Number(e.target.value));
											}}></input>
									</span>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover19'
								onClick={togglePopover}>
								<span className="material-symbols-outlined">
									view_agenda
								</span>
							</span>
						</Popover>
					</span>
				)}

				{selected === 'image' && (
					<Tippy content={<span>Image</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover16')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover16')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content' onClick={(e) => e.stopPropagation()}>
									<Lock
										title='Image Zoom'
										setting='imageZoomNews'
										lockedSettings={lockedSettings}
									/>
									<Slider
										disabled={lockedSettings.includes('imageZoomNews')}
										value={settings.imageZoomNews}
										onChange={(value) => sliderChange(value, 'imageZoom')}
										min={100}
										max={200}
									/>
									<span>
										<Lock
											title='Image Horizontal Margin'
											setting='imageHorizontalNews'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('imageHorizontalNews')}
											value={settings.imageHorizontalNews}
											onChange={(value) => sliderChange(value, 'imageHorizontal')}
											min={imageContainerWidth - imageWidth}
											max={imageWidth - imageContainerWidth}
										/>
										<Lock
											title='Image Vertical Margin'
											setting='imageVerticalNews'
											lockedSettings={lockedSettings}
										/>
										<Slider
											disabled={lockedSettings.includes('imageVerticalNews')}
											value={settings.imageVerticalNews}
											onChange={(value) => sliderChange(value, 'imageVertical')}
											min={imageContainerHeight - imageHeight}
											max={0}
										/>
									</span>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover16'
								onClick={togglePopover}>
								<i className='far fa-image no-click'></i>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'post' && (settings.type === 'news' || isHighlightOrLineup()) && (
					<span className='editor-dropdown lock-after'>
						<Select
							isDisabled={lockedSettings.includes('newsStyle')}
							value={settings.newsStyle}
							onChange={(selectedOption: SingleValue<Option>) => {
								updateSetting('newsStyle', selectedOption);
							}}
							// update fixUnavailableSettings if changes here
							options={
								settings.type === 'news'
									? styleOptionsNews
									: styleOptions
							}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
						<Lock
							tooltip='Style'
							setting='newsStyle'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}

				{selected === 'post' && settings.type === 'matchupimage' && (
					<span className='editor-dropdown lock-after'>
						<Select
							isDisabled={lockedSettings.includes('matchupStyle')}
							value={settings.matchupStyle}
							onChange={(selectedOption: SingleValue<Option>) => {
								updateSetting('matchupStyle', selectedOption);
							}}
							options={[
								{ value: 'style-1', label: 'Style 1' },
								{ value: 'style-2', label: 'Style 2' },
								{ value: 'style-3', label: 'Style 3' }
							]}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
						<Lock
							tooltip='Style'
							setting='matchupStyle'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}

				{(selected === 'post' && ((isHighlightOrLineup() && settings.newsStyle?.value === 'style-4') || (settings.type === 'news' && settings.newsStyle?.value === 'style-6'))) && (
					<Tippy content={<span>Style Options</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover28')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover28')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<MultipleColorPicker label='Lines Color' settingName='imageLinesColor' defaultColor='#ffffff' colorChange={colorChange}/>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover28'
								onClick={togglePopover}>
									<i className="fa-solid fa-gear"></i>
								</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{(selected === 'layout' && settings.type === 'bracket') && (
					<Tippy content={<span>Bracket Options</span>}>
					<span className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover33')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover33')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									{team.length > 32 && (<span className='center-span'>
										<Lock
											title='Extra-wide round of 64'
											setting='bracketMinimized'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											id='bracketMinimized'
											disabled={lockedSettings.includes('bracketMinimized')}
											checked={settings.bracketMinimized}
											onChange={() => {
												updateSetting('bracketMinimized', !settings.bracketMinimized);
											}}
										/>
									</span>)}
									{team.length <= 32 && (<span className='center-span' style={{ marginTop: '10px' }}>
										<Lock
											title='One-sided bracket'
											setting='bracketOneSided'
											lockedSettings={lockedSettings}
										/>
										<input
											type='checkbox'
											id='bracketOneSided'
											disabled={lockedSettings.includes('bracketOneSided')}
											checked={settings.bracketOneSided}
											onChange={() => {
												updateSetting('bracketOneSided', !settings.bracketOneSided);
											}}
										/>
									</span>)}
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover33'
								onClick={togglePopover}>
								<span className="material-symbols-outlined">
									view_agenda
								</span>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'post' && (settings.type.includes('image') || settings.type === 'news' || isHighlightOrLineup() || settings.type === 'matchupimage') && (
					<span className='lock-after'>
						<Tippy content={<span>Rotate Post</span>}>
						<span
							className={`editor-button ${lockedSettings.includes(orientSettings) ? 'disabled' : ''}`}
							onClick={() => {
								if (lockedSettings.includes(orientSettings)) return;
								flipPost();
							}}>
							<i className='fa-solid fa-arrows-spin no-click'></i>
						</span>
						</Tippy>
						<Lock
							tooltip='Orientation'
							setting={orientSettings}
							lockedSettings={lockedSettings}
						/>
					</span>
				)}

				{(selected === 'name' || selected === 'stat') && (settings.type.includes('post') || settings.type.includes('grid') || settings.type === 'tier') && (
					<Tippy content={<span>Highlight Text</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover23')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							positions={['top', 'bottom', 'right', 'left']}
							onClickOutside={() => togglePopover(null, 'popover23')}
							content={
								<div className='popover-content highlighter' onClick={(e) => e.stopPropagation()}>
									<h5>Conditionally Change {selected === 'name' ? 'Name' : 'Stat'} Color</h5>
									<span className='newHighlight'>
										<textarea
											rows={1}
											className='settingsInput'
											value={pendingHighlight.text}
											placeholder={selected === 'name' ? 'Full name to match' : 'Stat to match'}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													e.preventDefault();
													addHighlight();
												}
											}}
											onChange={(e) => {
												setPendingHighlight({
													...pendingHighlight,
													text: e.target.value
												});
											}}
										/>
										<button
											className='swatch'
											style={{
												backgroundColor: pendingHighlight.color,
												border: `solid 1px ${colord(pendingHighlight.color).isDark() ? '#ffffff' : '#cccccc'}`,
												outline: `solid 1px ${pendingHighlight.color}`,
												boxShadow: `0 0 0 1px ${colord(pendingHighlight.color).isDark() ? '#ffffff' : '#cccccc'}`
											}}
											onClick={toggleColorPickerOne}
										/>
										{highlightOneOpen && (
											<div
												className='colorPopover'
												ref={highlightOneRef}>
												<ChromePicker
													color={pendingHighlight.color}
													onChange={(color: string) => {
														setPendingHighlight({
															...pendingHighlight,
															color
														});
													}}
												/>
											</div>
										)}
										<button
											type='button'
											className='editor-button'
											onClick={addHighlight}>
											<i className='fa-regular fa-floppy-disk'></i>
										</button>
									</span>
									{highlights.map((highlight: { text: string; color: string }, i: number) => {
										return (
											<span
												key={i}
												className='existingHighlight'>
												<span className='highlightText'>{highlight.text}</span>
												<span
													className='swatch'
													style={{ backgroundColor: highlight.color }}
												/>
												<button
													type='button'
													className='editor-button'
													onClick={(e) => removeHighlight(e, i)}>
													<i className='fa fa-trash'></i>
												</button>
											</span>
										);
									})}
									{selected === 'stat' && (
										<span
											className='center-span'
											style={{ marginTop: '10px' }}>
											<Lock
												title='Highlight highest & lowest stats'
												setting='statHighlight'
												lockedSettings={lockedSettings}
											/>
											<input
												type='checkbox'
												disabled={lockedSettings.includes('statHighlight')}
												checked={settings.statHighlight}
												onChange={() => updateSetting('statHighlight', !settings.statHighlight)}
											/>
											{settings.statHighlight && (
												<span className='topBottomHighlights'>
													<Lock
														title='Highest # Color'
														setting='topHighlight'
														lockedSettings={lockedSettings}
													/>
													<button
														className='swatch'
														style={{
															backgroundColor: settings.topHighlight,
															border: `solid 1px ${colord(settings.topHighlight).isDark() ? '#ffffff' : '#cccccc'}`,
															outline: `solid 1px ${settings.topHighlight}`,
															boxShadow: `0 0 0 1px ${colord(settings.topHighlight).isDark() ? '#ffffff' : '#cccccc'}`
														}}
														onClick={toggleColorPickerTwo}
													/>
													{highlightTwoOpen && (
														<div
															className='colorPopover'
															ref={highlightTwoRef}>
															<ChromePicker
																color={settings.topHighlight}
																onChange={(color: string) => colorChange(color, 'topHighlight')}
															/>
														</div>
													)}
													<Lock
														title='Lowest # Color'
														setting='bottomHighlight'
														lockedSettings={lockedSettings}
													/>
													<button
														className='swatch'
														style={{
															backgroundColor: settings.bottomHighlight,
															border: `solid 1px ${colord(settings.bottomHighlight).isDark() ? '#ffffff' : '#cccccc'}`,
															outline: `solid 1px ${settings.bottomHighlight}`,
															boxShadow: `0 0 0 1px ${colord(settings.bottomHighlight).isDark() ? '#ffffff' : '#cccccc'}`
														}}
														onClick={toggleColorPickerThree}
													/>
													{highlightThreeOpen && (
														<div
															className='colorPopover'
															ref={highlightThreeRef}>
															<ChromePicker
																color={settings.bottomHighlight}
																onChange={(color: string) => colorChange(color, 'bottomHighlight')}
															/>
														</div>
													)}
												</span>
											)}
										</span>
									)}
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover23'
								onClick={togglePopover}>
								<span
									className='material-symbols-outlined'
									style={{ fontSize: '1.1em' }}>
									format_ink_highlighter
								</span>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{shouldShowImageShadow() && (
					<Tippy content={<span>Image Shadow</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover24')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							positions={['top', 'bottom', 'right', 'left']}
							onClickOutside={() => togglePopover(null, 'popover24')}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									{settings.matchupStyle?.value !== 'style-3' && (
										<span>
											<Lock
												title='Shadow Color'
												setting='imageShadowColor'
												lockedSettings={lockedSettings}
											/>
											<ChromePicker
												color={settings.imageShadowColor}
												onChange={(color: string) => colorChange(color, 'imageShadowColor')}
											/>
										</span>
									)}
									<span className='edit-spacer'></span>
									<Lock
										title='Shadow Size'
										setting='imageShadowSize'
										lockedSettings={lockedSettings}
									/>
									<Slider
										disabled={lockedSettings.includes('imageShadowSize')}
										value={settings.imageShadowSize}
										onChange={(value) => sliderChange(value, 'imageShadowSize')}
										min={0}
										max={200}
									/>
								</div>
							}>
							<span
								className='colorbox-editor'
								data-popover='popover24'
								onClick={togglePopover}>
								<span
									className='material-symbols-outlined'
									style={{ fontSize: '1.1em' }}>
									ev_shadow
								</span>
							</span>
						</Popover>
					</span>
					</Tippy>
				)}

				{selected === 'jerseyStyles' && sport === 'ncaa' && (
					<span className='editor-dropdown lock-after'>
						<Select
							isDisabled={lockedSettings.includes('jerseyType')}
							value={jerseyType}
							onChange={(selectedOption: SingleValue<Option>) => {
								setJerseyType(selectedOption);
							}}
							options={jerseyStyleOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
						<Lock
							tooltip='Style'
							setting='jerseyType'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}

				{selected === 'jerseyStyles' && sport !== 'mlb' && sport !== 'nhl' && (
					<span className='editor-dropdown lock-after'>
						<Select
							isDisabled={lockedSettings.includes('jerseyVersion')}
							value={jerseyVersion}
							onChange={(selectedOption: SingleValue<Option>) => {
								setJerseyVersion(selectedOption);
							}}
							options={jerseyVersionOptions}
							styles={darkMode ? settingsStylesDark : settingsStyles}
							isSearchable={false}
						/>
						<Lock
							tooltip='Style'
							setting='jerseyVersion'
							lockedSettings={lockedSettings}
						/>
					</span>
				)}

				{selected === 'jerseyStyles' && sport === 'ncaa' && (
					<div className="form-check form-switch">
					<input
						className={`form-check-input ${settings.flipColors ? 'custom-switch-checked' : 'custom-switch-unchecked'}`}
						type="checkbox"
						role="switch"
						id="flipColorsId"
						checked={settings.flipColors}
						onChange={(e) => {
							updateSetting('flipColors', e.target.checked);
						}}/>
					<label
						className="form-check-label"
						htmlFor="flipColorsId">
							Flip colors
					</label>
				</div>)}

				{selected === 'jerseyStyles' && (['nba', 'mlb', 'nhl'].includes(sport) || (sport === 'ncaa' && jerseyType?.value === 'basketball')) && (
					<Tippy content={<span>Background Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover35')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover35')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<MultipleColorPicker label='Background Color' settingName='jerseyBackground' defaultColor='#000000' colorChange={colorChange} includeBrighterDarker={false} includeNone={true} noneIsDefault={true}/>
								</div>
							}>
							<span
								className='colorbox-editor rainbow-box'
								data-popover='popover35'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{(selected === 'icon' || selected === 'banner') && (
					<Tippy content={<span>Color</span>}>
					<span
						className='editor-button'>
						<Popover
							isOpen={popovers.includes('popover36')}
							reposition={true}
							containerStyle={{ zIndex: '1000' }}
							onClickOutside={() => togglePopover(null, 'popover36')}
							positions={['top', 'bottom', 'right', 'left']}
							content={
								<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
									<MultipleColorPicker label='Icon Color' settingName={`${selected}Color`} defaultColor='#000000' colorChange={colorChange} includeBrighterDarker={false} includeNone={true} noneIsDefault={true}/>
								</div>
							}>
							<span
								className='colorbox-editor rainbow-box'
								data-popover='popover36'
								onClick={togglePopover}></span>
						</Popover>
					</span>
					</Tippy>
				)}

				{(selected === 'icon' || selected === 'banner') && (
					<Tippy content={<span>Text Color</span>}>
						<span
							className='editor-button'>
							<Popover
								isOpen={popovers.includes('popover37')}
								reposition={true}
								containerStyle={{ zIndex: '1000' }}
								onClickOutside={() => togglePopover(null, 'popover37')}
								positions={['top', 'bottom', 'right', 'left']}
								content={
									<div className='popover-content color-picker' onClick={(e) => e.stopPropagation()}>
										<MultipleColorPicker label='Text Color' settingName={`${selected}TextColor`} defaultColor='#ffffff' colorChange={colorChange} includeBrighterDarker={false} includeNone={true} noneIsDefault={true}/>
									</div>
								}>
								<span
									className='colorbox-editor'
									data-popover='popover37'
									onClick={togglePopover}>
									<span className='colorbox-letter'>A</span>
									<span className='rainbow-bar'></span>
								</span>
							</Popover>
						</span>
					</Tippy>
				)}

				{individuallyEdit && (
					<button
						className='newButton dont-hide addFloatingText'
						onClick={addFloatingText}>
						Add Text Box
					</button>
				)}

				{individuallyEdit && hasDraggables && (
					<button
						className='newButton dont-hide resetDraggables'
						onClick={() => {
							props.resetDraggables();
						}}>
						Reset Images
					</button>
				)}

				{individuallyEdit && teamOverrides.length > 0 && teamOverrides.filter((override: any) => !override.isGlobal).length > 0 && (
					<button
						className='newButton dont-hide'
						onClick={resetOverrides}>
						Reset Team Edits
					</button>
				)}

				{individuallyEdit && (<button
					className={'dont-hide off-editing newButton'}
					onClick={() => {
						setIndividuallyEdit(false);
					}}>
					Turn off editing
				</button>)}
			</div>

			<div className='floatright'>
				<Tippy content={<span>Randomize Settings</span>}>
					<span
						className='editor-button randomBtn'
						onClick={props.randomSettings}>
						<i className='fas fa-shuffle no-click'></i>
					</span>
				</Tippy>

				<Tippy content={<span>Reset Settings</span>}>
					<span
						className='editor-button resetBtn'
						onClick={props.resetSomeSettings}>
						<i className='fa-solid fa-rotate-left'></i>
					</span>
				</Tippy>

				<Tippy content={<span>Freestyle Edit</span>}>
				<button
					className={'dont-hide off-editing editor-button'}
					onClick={() => {
						setIndividuallyEdit(true);
					}}>
					<i className='fas fa-pencil-alt no-click'></i>
				</button>
				</Tippy>
			</div>
		</div>
	);
}
