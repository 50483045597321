export const logoOptions = [
	{value: 'primary', label: 'Primary Logo'},
	{value: 'helmet', label: 'Helmets'},
	{value: 'wordmark', label: 'Wordmark'},
	{value: 'outline', label: 'Outline'}
];

export const f1LogoOptions = [
	{value: 'primary', label: 'Primary Logo'},
	{value: 'duplicate', label: 'Car'},
];