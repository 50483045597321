import useSettingsStore from '../../../stores/settings.store';
import useUserStore from '../../../stores/user.store';
import useWithImageStore from '../../../stores/posts/with-image.store';
import { colord } from 'colord';
import './JerseyFormat.scss';
import useJerseyStore from '../../../stores/posts/jersey.store';
import { useEffect } from 'react';
import { FootballJersey } from '../common/svgs/football/footballjersey';
import { BasketballJersey } from '../common/svgs/basketball/basketballjersey';
import { BaseballJersey } from '../common/svgs/baseball/baseballjersey';
import { HockeyJersey } from '../common/svgs/hockey/hockeyjersey';
import { StripedBaseballJersey } from '../common/svgs/baseball/stripedbaseballjersey';
import { isMobile } from 'react-device-detect';

interface Team {
	abbreviation: string;
	abbreviation2?: string;
	abbreviation3?: string;
	full: string;
	full2?: string;
	primary: string;
	secondary: string;
	logos: string[];
	helmet: string;
	wordmark: string;
	outline: string;
}

export default function JerseyFormat (props: { getTeamObj: (team: string, sport: string) => any; findColor: (color: string, teamObj: Team | null) => string; selectSettings: (settings: any) => void }): JSX.Element {
	const { settings } = useSettingsStore();
	const { sport } = useUserStore();
	const { newsteam } = useWithImageStore();
	const { iconTypes, icons, iconDescriptions, playerName, playerNumber, banners, bannerSpaces, jerseyType, jerseyVersion, availableIcons, setIconDescriptions } = useJerseyStore();

	useEffect(() => {
		const copiedIconDescriptions = [...iconDescriptions];
		iconTypes.forEach((iconType, i) => {
			if (!iconDescriptions[i]) {
				const availableIcon = availableIcons.find((icon) => icon.value === iconType);
				copiedIconDescriptions[i] = availableIcon.default;
			}
		});
		setIconDescriptions(copiedIconDescriptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconTypes]);

	const jerseyColors: Record<string, Record<string, string>> = {
		nfl: {
			ATL: 'secondary',
			CIN: 'secondary',
			LV: 'secondary',
			NO: 'secondary',
			PIT: 'secondary'
		},
		nba: {
			BOS: '#0C6950',
			DET: 'secondary',
			LAC: 'secondary',
			LAL: 'secondary',
			MEM: 'secondary',
			MIA: '#000000',
			MIL: '#374C45',
			NY: '#193D82',
			PHI: '#002B5C',
			PHX: '#5A3E8B',
			POR: '#000000',
			SA: 'secondary',
			WAS: 'secondary'
		},
		ncaa: {},
		wnba: {},
		mlb: {
			ARI: '#F7F5EE',
			MIL: '#F7F1EA',
			SF: '#FFF9E9'
		},
		nhl: {
			BOS: 'secondary',
			FLA: 'secondary',
			MIN: 'secondary',
			OTT: '#000000',
			STL: '#E7DCCE',
			UTAH: 'secondary',
			WSH: 'secondary'
		},
		soccer: {}
	};

	const findJerseyColor = (teamObj: Team | null): string => {
		if (!teamObj) return '#f5f5f5';
		if (jerseyVersion?.value === 'away') return '#ffffff';
		let colorToReturn = jerseyColors[sport][teamObj.abbreviation] ?? 'primary';
		if (sport === 'mlb') {
			colorToReturn = jerseyColors[sport][teamObj.abbreviation] ?? '#ffffff';
		}
		if (colorToReturn === 'secondary') return teamObj.secondary;
		if (colorToReturn === 'primary') return teamObj.primary;
		return colorToReturn;
	};

	const nameColors: Record<string, Record<string, string>> = {
		nfl: {
			SEA: '#A5ACAF',
			LV: 'primary',
			PIT: 'primary',
			NO: 'primary',
			HOU: 'secondary',
			WAS: 'secondary'
		},
		nba: {
			CLE: 'secondary',
			DEN: 'secondary',
			GS: 'secondary',
			IND: 'secondary',
			LAL: 'primary',
			MEM: 'primary',
			PHX: 'secondary'
		},
		ncaa: {},
		wnba: {},
		mlb: {},
		nhl: {
			BOS: 'primary',
			BUF: 'secondary',
			LA: 'secondary',
			MIN: '#DDC9A3',
			NSH: 'secondary',
			NYR: 'secondary',
			PHI: '#000000',
			PIT: 'secondary',
			SEA: 'secondary',
			STL: 'primary',
			VGK: 'secondary'
		},
		soccer: {}
	};

	const nameColorsAway: Record<string, Record<string, string>> = {
		nfl: {
			DEN: 'secondary',
			CAR: 'secondary',
			CIN: 'secondary',
			JAX: '#000000',
			LV: 'secondary',
			NO: 'secondary',
			NYG: 'secondary'
		},
		nba: {
			CHA: 'secondary',
			CHI: 'secondary',
			DAL: '#0B151F',
			DEN: '#8B2131',
			DET: 'secondary',
			LAC: 'secondary',
			MEM: 'secondary',
			MIA: '#000000',
			MIL: '#374C45',
			PHX: 'secondary',
			POR: '#000000',
			SA: 'secondary'
		},
		ncaa: {},
		wnba: {},
		mlb: {
			ARI: '#000000',
			ATH: 'secondary',
			CIN: 'secondary',
			CLE: 'secondary',
			COL: '#000000',
			DET: 'secondary',
			KC: 'secondary',
			LAA: 'secondary',
			LAD: '#133E88',
			MIA: '#000000',
			MIL: 'secondary',
			PHI: 'secondary',
			SD: 'secondary',
			TB: 'secondary'
		},
		nhl: {},
		soccer: {}
	};

	const findNameColor = (teamObj: Team | null): string => {
		if (jerseyVersion?.value === 'home' && sport !== 'mlb') {
			if (!teamObj) return '#fff';
			const color = nameColors[sport][teamObj.abbreviation] ?? '#fff';
			if (color === 'secondary') return teamObj.secondary;
			if (color === 'primary') return teamObj.primary;
			return color;
		} else {
			if (!teamObj) return '#000';
			const color = nameColorsAway[sport][teamObj.abbreviation] ?? teamObj.primary;
			if (color === 'secondary') return teamObj.secondary;
			return color;
		}
	};

	const nameOutlines: Record<string, Record<string, string>> = {
		nfl: {
			KC: 'secondary',
			TEN: 'secondary',
			PHI: 'black',
			ARI: 'black',
			CAR: 'secondary',
			CHI: 'secondary',
			NO: '#e8ddc5',
			MIA: 'secondary'
		},
		nba: {},
		ncaa: {},
		wnba: {},
		mlb: {},
		nhl: {
			BOS: '#FFFFFF',
			NYI: 'secondary',
			NYR: '#FFFFFF'
		},
		soccer: {}
	};

	const nameOutlinesAway: Record<string, Record<string, string>> = {
		nfl: {
			CAR: 'primary',
			PIT: 'secondary'
		},
		nba: {},
		ncaa: {},
		wnba: {},
		mlb: {
			ARI: '#30CED8',
			ATH: 'primary',
			BOS: 'secondary',
			CHW: 'secondary',
			CHC: 'secondary',
			CLE: 'primary',
			HOU: 'secondary',
			MIA: 'primary',
			MIL: 'primary',
			NYM: 'secondary',
			PHI: '#ffffff',
			PIT: 'secondary',
			SD: 'primary',
			SF: 'secondary',
			SEA: 'secondary',
			STL: 'secondary',
			TB: 'primary',
			TEX: 'secondary',
			WAS: 'secondary'
		},
		nhl: {},
		soccer: {}
	};

	const findNameOutline = (teamOb: any): string => {
		if (!teamObj) return '';
		if (jerseyVersion?.value === 'away' || sport === 'mlb') {
			const awayColor = nameOutlinesAway[sport][teamObj.abbreviation];
			if (!awayColor) return '';
			const size = ['mlb'].includes(sport) ? 3 : 5;
			if (awayColor === 'primary') return `calc(${size}px * var(--sizemultiplier)) ${teamObj.primary}`;
			if (awayColor === 'secondary') return `calc(${size}px * var(--sizemultiplier)) ${teamObj.secondary}`;
			return `calc(3px * var(--sizemultiplier)) ${awayColor}`;
		}
		const color = nameOutlines[sport][teamObj.abbreviation];
		if (!color) return '';
		const size = ['nhl'].includes(sport) ? 3 : 5;
		if (color === 'primary') return `calc(${size}px * var(--sizemultiplier)) ${teamObj.primary}`;
		if (color === 'secondary') return `calc(${size}px * var(--sizemultiplier)) ${teamObj.secondary}`;
		return `calc(${size}px * var(--sizemultiplier)) ${color}`;
	};

	const numberOutlines: Record<string, Record<string, any>> = {
		nfl: {
			BAL: { color: '#9E7C0C', size: '6px' },
			DET: { color: '#656E7C', size: '3px' },
			JAX: { color: '#01A2B4', size: '3px' },
			NO: { color: '#ffffff', size: '6px' },
			GB: { color: '#ffffff', size: '2px' },
			IND: { color: '#ffffff', size: '2px' },
			MIN: { color: '#ffffff', size: '2px' },
			NYG: { color: '#ffffff', size: '2px' },
			PIT: { color: '#ffffff', size: '2px' },
			SF: { color: '#ffffff', size: '2px' },
			WAS: { color: '#ffffff', size: '4px' },
			BUF: { color: 'secondary', size: '4px' },
			CAR: { color: 'secondary', size: '6px' },
			CHI: { color: 'secondary', size: '6px' },
			MIA: { color: 'secondary', size: '4px' },
			DAL: { color: 'primary', size: '6px' },
			CIN: { color: 'primary', size: '5px' },
			NE: { color: '#B0B7BC', size: '6px' },
			TB: { color: '#FF7900', size: '4px' }
		},
		nba: {
			ATL: { color: 'secondary', size: '6px' },
			CHI: { color: '#ffffff', size: '6px' },
			DAL: { color: '#ffffff', size: '6px' },
			DEN: { color: '#8B2131', size: '6px' },
			DET: { color: '#ffffff', size: '6px' },
			LAL: { color: '#ffffff', size: '6px' },
			MIA: { color: 'primary', size: '6px' },
			NO: { color: '#85714D', size: '6px' },
			NY: { color: '#ffffff', size: '6px' },
			OKC: { color: '#002D62', size: '6px' },
			ORL: { color: '#000000', size: '6px' },
			POR: { color: 'primary', size: '6px' },
			SAC: { color: '#000000', size: '6px' },
			SA: { color: '#000000', size: '2px' },
			TOR: { color: '#ffffff', size: '4px' },
			WAS: { color: '#ffffff', size: '6px' }
		},
		ncaa: {},
		wnba: {},
		mlb: {},
		nhl: {
			BOS: { color: '#ffffff', size: '6px' },
			BUF: { color: '#ffffff', size: '6px' },
			CHI: { color: '#000000', size: '6px' },
			FLA: { color: '#B9975B', size: '6px' },
			LA: { color: '#000000', size: '6px' },
			MIN: { color: 'primary', size: '6px' },
			NSH: { color: '#FFFFFF', size: '6px' },
			NYR: { color: '#FFFFFF', size: '6px' },
			OTT: { color: 'primary', size: '6px' },
			PIT: { color: '#FFFFFF', size: '6px' },
			SJ: { color: '#000000', size: '6px' },
			SEA: { color: '#355464', size: '6px' },
			STL: { color: 'secondary', size: '6px' },
			TOR: { color: '#FFFFFF', size: '6px' },
			UTAH: { color: 'primary', size: '6px' },
			VGK: { color: '#FFFFFF', size: '6px' },
			WSH: { color: 'primary', size: '6px' },
			WPG: { color: '#004C97', size: '6px' }
		},
		soccer: {}
	};

	const numberOutlinesAway: Record<string, Record<string, any>> = {
		nfl: {
			DEN: { color: 'primary', size: '5px' },
			BAL: { color: '#9E7C0C', size: '6px' },
			CAR: { color: 'primary', size: '6px' },
			CIN: { color: 'primary', size: '5px' },
			DAL: { color: '#ffffff', size: '6px' },
			LV: { color: 'primary', size: '6px' },
			NE: { color: '#B0B7BC', size: '5px' },
			NO: { color: 'primary', size: '6px' },
			TB: { color: '#FF7900', size: '4px' }
		},
		nba: {
			CHA: { color: 'primary', size: '6px' },
			MIA: { color: '#000000', size: '6px' },
			MIN: { color: 'primary', size: '6px' },
			NO: { color: '#85714D', size: '6px' },
			NY: { color: 'primary', size: '6px' },
			OKC: { color: '#FFFFFF', size: '5px' },
			POR: { color: 'primary', size: '6px' },
			SAC: { color: 'primary', size: '6px' },
			SA: { color: '#ffffff', size: '2px' }
		},
		ncaa: {},
		wnba: {},
		mlb: {
			ARI: { color: '#000000', size: '6px' },
			ATH: { color: 'primary', size: '6px' },
			ATL: { color: 'primary', size: '6px' },
			CIN: { color: '#ffffff', size: '6px' },
			CLE: { color: 'primary', size: '6px' },
			COL: { color: 'secondary', size: '6px' },
			LAA: { color: 'primary', size: '6px' },
			MIA: { color: 'primary', size: '6px' },
			MIL: { color: 'primary', size: '6px' },
			PHI: { color: '#ffffff', size: '6px' },
			SD: { color: 'primary', size: '6px' },
			TB: { color: 'primary', size: '6px' },
			TEX: { color: '#ffffff', size: '6px' },
			WAS: { color: 'primary', size: '6px' }
		},
		nhl: {},
		soccer: {}
	};

	const findNumberOutline = (teamObj: Team | null): string => {
		if (jerseyVersion?.value === 'home' && sport !== 'mlb') {
			if (!teamObj) return '';
			if (sport === 'nfl' && ['CLE', 'LAR', 'ATL'].includes(teamObj.abbreviation)) return '';
			if (sport === 'nba' && ['BOS', 'BRK', 'HOU', 'PHI', 'PHX'].includes(teamObj.abbreviation)) return '';
			if (sport === 'nhl' && ['DET', 'VAN'].includes(teamObj.abbreviation)) return '';
			let outline = `calc(5px * var(--sizemultiplier)) ${teamObj.secondary}`;
			if (numberOutlines[sport][teamObj.abbreviation]) {
				let { color, size } = numberOutlines[sport][teamObj.abbreviation];
				if (color === 'primary') color = teamObj.primary;
				if (color === 'secondary') color = teamObj.secondary;
				outline = `calc(${size} * var(--sizemultiplier)) ${color}`;
			}
			return outline;
		} else {
			if (!teamObj) return '';
			if (sport === 'nfl' && ['CLE', 'LAR', 'ATL', 'GB', 'IND', 'JAX', 'MIN', 'SF'].includes(teamObj.abbreviation)) return '';
			if (sport === 'nba' && ['BOS', 'CLE', 'GS', 'HOU', 'LAC', 'PHI', 'PHX'].includes(teamObj.abbreviation)) return '';
			if (sport === 'mlb' && ['KC', 'LAD', 'NYY', 'TOR'].includes(teamObj.abbreviation)) return '';
			let outline = `calc(5px * var(--sizemultiplier)) ${teamObj.secondary}`;
			if (numberOutlinesAway[sport][teamObj.abbreviation]) {
				let { color, size } = numberOutlinesAway[sport][teamObj.abbreviation];
				if (color === 'primary') color = teamObj.primary;
				if (color === 'secondary') color = teamObj.secondary;
				outline = `calc(${size} * var(--sizemultiplier)) ${color}`;
			}
			return outline;
		}
	};

	const numberShadows: Record<string, Record<string, any>> = {
		nfl: {
			NYJ: { color: '#000000', size: '3px', sides: 1 },
			BUF: { color: '#000000', size: '3px', sides: 1 },
			PHI: { color: '#000000', size: '10px', sides: 1 },
			BAL: { color: 'secondary', size: '12px', sides: 1 },
			DAL: { color: '#ffffff', size: '6px', sides: 4 },
			NE: { color: '#C60C30', size: '6px', sides: 4 },
			ATL: { color: 'primary', size: '10px', size2: '0px', sides: 1 },
			TB: { color: '#34302B', size: '8px', sides: 4 }
		},
		nba: {
			ATL: { color: 'secondary', size: '6px', sides: 2 },
			LAL: { color: '#ffffff', size: '12px', sides: 1, direction: 'down' },
			OKC: { color: 'secondary', size: '8px', sides: 4 },
			PHI: { color: 'secondary', size: '12px', sides: 1 },
			PHX: { color: 'secondary', size: '8px', sides: 1 },
			SA: { color: 'primary', size: '6px', sides: 4 }
		},
		ncaa: {},
		wnba: {},
		mlb: {},
		nhl: {
			LA: { color: '#FFFFFF', size: '6px', sides: 4 },
			NYR: { color: '#FFFFFF', size: '20px', sides: 1, direction: 'left', sun: true },
			TB: { color: '#FFFFFF', size: '6px', sides: 4 }
		},
		soccer: {}
	};

	const numberShadowsAway: Record<string, Record<string, any>> = {
		nfl: {
			DAL: { color: 'primary', size: '8px', sides: 4 }
		},
		nba: {
			LAL: { color: 'secondary', size: '12px', sides: 1, direction: 'down' },
			OKC: { color: 'secondary', size: '8px', sides: 4 },
			ORL: { color: '#000000', size: '8px', sides: 4 },
			PHI: { color: 'secondary', size: '12px', sides: 1 },
			PHX: { color: 'secondary', size: '8px', sides: 1 },
			SA: { color: 'primary', size: '6px', sides: 4 }
		},
		ncaa: {},
		wnba: {},
		mlb: {
			ARI: { color: '#30CED8', size: '6px', sides: 4 },
			CIN: { color: 'primary', size: '12px', sides: 2 },
			MIA: { color: '#EF3340', size: '12px', sides: 2, direction: 'left' },
			TEX: { color: 'secondary', size: '6px', sides: 2 }
		},
		nhl: {},
		soccer: {}
	};

	const findNumberShadow = (teamObj: Team | null): string => {
		let shadow = '';
		let color: string, size: string, size2: string, sides: number, direction: string, sun: boolean;
		if (jerseyVersion?.value === 'home' && sport !== 'mlb') {
			if (!teamObj) return '';
			if (!numberShadows[sport][teamObj.abbreviation]) return '';
			({ color, size, size2, sides, direction, sun } = numberShadows[sport][teamObj.abbreviation]);
			if (color === 'secondary') {
				color = teamObj.secondary;
			} else if (color === 'primary') {
				color = teamObj.primary;
			}
		} else {
			if (!teamObj) return '';
			if (!numberShadowsAway[sport][teamObj.abbreviation]) return '';
			({ color, size, size2, sides, direction, sun } = numberShadowsAway[sport][teamObj.abbreviation]);
			if (color === 'secondary') {
				color = teamObj.secondary;
			} else if (color === 'primary') {
				color = teamObj.primary;
			}
		}
		if (sides === 1) {
			if (direction === 'left') {
				if (sun) {
					shadow = '';
					Array.from({ length: Number(size.replace('px', '')) }).forEach((_, i) => {
						shadow += `
							calc(-${i}px * var(--sizemultiplier)) calc(${i}px * var(--sizemultiplier)) 0 ${color}, `;
					});
					shadow = shadow.slice(0, -2);
				} else {
					shadow = `calc(-${size} * var(--sizemultiplier)) calc(${size} * var(--sizemultiplier)) 0 ${color}`;
				}
			} else if (direction === 'down') {
				shadow = `0 calc(${size} * var(--sizemultiplier)) 0 ${color}`;
			} else {
				shadow = `calc(${size} * var(--sizemultiplier)) calc(${size2 || size} * var(--sizemultiplier)) 0 ${color}`;
			}
		} else if (sides === 2) {
			if (direction === 'left') {
				shadow = `calc(-${size} * var(--sizemultiplier)) calc(${size2 || size} * var(--sizemultiplier)) 0 ${color}, calc(-${size2 || size} * var(--sizemultiplier)) calc(${size} * var(--sizemultiplier)) 0 ${color}`;
			} else {
				shadow = `calc(${size} * var(--sizemultiplier)) calc(${size2 || size} * var(--sizemultiplier)) 0 ${color}, calc(${size2 || size} * var(--sizemultiplier)) calc(${size} * var(--sizemultiplier)) 0 ${color}`;
			}
		} else if (sides === 4) {
			shadow = `calc(${size} * var(--sizemultiplier)) calc(${size} * var(--sizemultiplier)) 0 ${color}, calc(-${size} * var(--sizemultiplier)) calc(-${size} * var(--sizemultiplier)) 0 ${color}, calc(${size} * var(--sizemultiplier)) calc(-${size} * var(--sizemultiplier)) 0 ${color}, calc(-${size} * var(--sizemultiplier)) calc(${size} * var(--sizemultiplier)) 0 ${color}`;
		}
		return shadow;
	};

	const numberColors: Record<string, Record<string, string>> = {
		nfl: {
			SEA: '#A5ACAF',
			LV: 'primary',
			NO: 'primary',
			LAR: 'secondary',
			WAS: 'secondary'
		},
		nba: {
			CHI: 'secondary',
			CLE: 'secondary',
			DAL: 'secondary',
			DET: 'primary',
			GS: 'secondary',
			IND: 'secondary',
			LAL: 'primary',
			MEM: 'primary',
			NY: '#EA4C2A',
			TOR: 'secondary',
			WAS: 'primary'
		},
		ncaa: {},
		wnba: {},
		mlb: {},
		nhl: {
			BOS: 'primary',
			BUF: 'secondary',
			LA: 'secondary',
			MIN: '#DDC9A3',
			NSH: 'secondary',
			NYR: 'secondary',
			PIT: 'secondary',
			SEA: 'secondary',
			STL: 'primary',
			VGK: '#000000'
		},
		soccer: {}
	};

	const numberColorsAway: Record<string, Record<string, string>> = {
		nfl: {
			DEN: 'secondary',
			ATL: 'secondary',
			CAR: 'secondary',
			CIN: 'secondary',
			JAX: '#000000',
			LV: 'secondary',
			NO: 'secondary',
			NYG: 'secondary',
			PIT: 'secondary'
		},
		nba: {
			CHA: 'secondary',
			MEM: 'secondary',
			MIL: '#374C45',
			MIN: 'secondary',
			NY: 'secondary',
			POR: '#000000',
			SAC: '#000000',
			SA: 'secondary',
			WAS: 'secondary'
		},
		ncaa: {},
		wnba: {},
		mlb: {
			ATH: 'secondary',
			ATL: 'secondary',
			CIN: 'secondary',
			CLE: 'secondary',
			COL: '#000000',
			DET: 'secondary',
			KC: 'secondary',
			LAA: 'secondary',
			LAD: '#133E88',
			MIA: '#000000',
			MIL: 'secondary',
			MIN: 'secondary',
			PHI: 'secondary',
			SD: 'secondary',
			TB: 'secondary',
			WAS: 'secondary'
		},
		nhl: {},
		soccer: {}
	};

	const findNumberColor = (teamObj: Team | null): string => {
		if (jerseyVersion?.value === 'home' && sport !== 'mlb') {
			if (!teamObj) return '';
			const color = numberColors[sport][teamObj.abbreviation] ?? '#fff';
			if (color === 'secondary') return teamObj.secondary;
			if (color === 'primary') return teamObj.primary;
			return color;
		} else {
			if (!teamObj) return '#000';
			const color = numberColorsAway[sport][teamObj.abbreviation] ?? teamObj.primary;
			if (color === 'secondary') return teamObj.secondary;
			return color;
		}
	};

	const bannerColors: Record<string, Record<string, string>> = {
		nfl: {
			ATL: 'primary',
			CIN: 'primary',
			LV: 'primary',
			NO: 'primary',
			BAL: '#9E7C0C',
			PIT: 'primary'
		},
		nba: {
			DET: 'primary',
			LAC: 'primary',
			LAL: 'primary'
		},
		ncaa: {},
		wnba: {},
		mlb: {
			ARI: '#30CED8'
		},
		nhl: {
			ANA: '#FFFFFF',
			BOS: 'primary',
			CHI: '#000000',
			EDM: '#FFFFFF',
			FLA: 'primary',
			MIN: 'primary',
			PHI: '#FFFFFF',
			TB: '#FFFFFF',
			TOR: '#FFFFFF'
		},
		soccer: {}
	};

	const getBannerStyles = (teamObj: Team | null): any => {
		const style = {
			backgroundColor: '#000',
			color: '#fff'
		};
		if (!teamObj) return style;
		let colorToUse = '';
		if (settings.bannerColor === 'none') {
			colorToUse = bannerColors[sport][teamObj.abbreviation] ?? 'secondary';
			if (colorToUse === 'secondary' || colorToUse === 'primary') {
				style.backgroundColor = teamObj[colorToUse] ?? '#000';
			} else {
				style.backgroundColor = colorToUse;
			}
		} else {
			style.backgroundColor = props.findColor(settings.bannerColor, teamObj);
		}
		if (settings.bannerTextColor === 'none') {
			colorToUse = bannerColors[sport][teamObj.abbreviation] ?? 'secondary';
			if (colorToUse === 'secondary' || colorToUse === 'primary') {
				style.color = teamObj[colorToUse === 'primary' ? 'secondary' : 'primary'] ?? '#fff';
			} else {
				style.color = teamObj.primary;
			}
		} else {
			style.color = props.findColor(settings.bannerTextColor, teamObj);
		}
		return style;
	};

	const stripeColorsAway: Record<string, Record<string, string[]>> = {
		nfl: {},
		nba: {
			ATL: ['', 'secondary', 'primary'],
			BOS: ['primary', '#FFFFFF', 'primary'],
			BRK: ['', '', '#000000'],
			CHA: ['', '', 'secondary'],
			CHI: ['primary', '#FFFFFF', 'primary'],
			DAL: ['', '#0B151F', '#0B151F'],
			DEN: ['primary', 'primary', 'secondary'],
			DET: ['', '', 'secondary'],
			GS: ['', 'primary', 'primary'],
			HOU: ['', 'primary', ''],
			IND: ['', 'secondary', 'primary'],
			LAC: ['', 'secondary', 'primary'],
			LAL: ['secondary', 'primary', '#FFFFFF'],
			MEM: ['', '', 'secondary'],
			MIA: ['', '', 'primary'],
			MIL: ['', '', '#374C45'],
			MIN: ['', '', 'primary'],
			NO: ['', '', '#85714D'],
			NY: ['', 'secondary', 'primary'],
			OKC: ['secondary', '#fdbb30', 'primary'],
			PHI: ['secondary', '#FFFFFF', 'primary'],
			PHX: ['', 'primary', 'secondary'],
			POR: ['', '#B1B7B7', 'primary'],
			SAC: ['', 'primary', '#000000'],
			SA: ['secondary', 'primary', 'secondary'],
			UTA: ['', 'secondary', 'primary'],
			WAS: ['', 'primary', '']
		},
		ncaa: {},
		wnba: {},
		mlb: {},
		nhl: {},
		soccer: {}
	};

	const stripeColors: Record<string, Record<string, string[]>> = {
		nfl: {},
		nba: {
			ATL: ['', 'secondary', '#FFFFFF'],
			BOS: ['#FFFFFF', '#0C6950', '#FFFFFF'],
			BRK: ['', '', '#FFFFFF'],
			CHI: ['#FFFFFF', 'primary', '#FFFFFF'],
			DAL: ['#000000', '#000000', 'secondary'],
			DEN: ['#FFFFFF', '#FFFFFF', '#8B2131'],
			DET: ['', '', 'primary'],
			GS: ['', 'secondary', 'secondary'],
			HOU: ['', 'secondary', ''],
			IND: ['', 'secondary', 'primary'],
			LAC: ['', 'primary', '#FFFFFF'],
			LAL: ['#FFFFFF', 'primary', 'secondary'],
			MEM: ['', '', 'primary'],
			MIA: ['', '', 'primary'],
			MIL: ['', '', 'secondary'],
			MIN: ['', '', 'secondary'],
			NO: ['', '', '#85714D'],
			NY: ['', '#FFFFFF', 'secondary'],
			OKC: ['', 'secondary', '#fdbb30'],
			ORL: ['', '', '#000000'],
			PHI: ['#FFFFFF', 'secondary', '#FFFFFF'],
			PHX: ['', 'secondary', '#FFFFFF'],
			POR: ['', '#B1B7B7', 'primary'],
			SAC: ['', '#FFFFFF', '#000000'],
			SA: ['primary', 'secondary', 'primary'],
			UTA: ['', 'secondary', 'primary'],
			WAS: ['', '#FFFFFF', '']
		},
		ncaa: {},
		wnba: {},
		mlb: {
			CHW: ['#000000'],
			CHC: ['primary'],
			COL: ['primary'],
			NYY: ['primary'],
			NYM: ['primary'],
			PHI: ['secondary'],
			SD: ['secondary']
		},
		nhl: {
			ANA: ['#000000', '#ffffff', '#B9975B', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#F47A38', '#F47A38', '#000000'],
			BOS: ['secondary', 'primary', 'secondary', '#FFFFFF', 'secondary', 'primary', 'secondary', 'secondary', 'secondary', 'secondary', 'primary', '#FFFFFF', ''],
			BUF: ['', 'secondary', '', 'secondary', '', 'secondary', '', '', '', '', '', '', ''],
			CGY: ['secondary', '', '#FFFFFF', '', 'secondary', '', '', '', '', '', '', '', 'secondary'],
			CAR: ['secondary', '#FFFFFF', 'primary', '#FFFFFF', 'secondary', 'secondary', 'secondary', 'secondary', 'secondary', 'secondary', '', '', '#000000'],
			CHI: ['#FFFFFF', '#000000', '#FFFFFF', '', '', '', '', '', '', '', '', '', '#000000'],
			COL: ['', '', '', '', '', '', '', '', '', '', 'secondary', '#A2AAAD', '#A2AAAD'],
			CBJ: ['', '', '', '', '', '', '', '', '', '', '', 'secondary', 'secondary'],
			DAL: ['#FFFFFF', '#000000', '#FFFFFF', '', '', '', '', '', '', '', '', '', '#FFFFFF'],
			DET: ['', '', '#FFFFFF', '#FFFFFF', '', '', '', '', '', '', '', '', ''],
			EDM: ['#FFFFFF', 'primary', 'secondary', 'primary', '#FFFFFF', 'secondary', 'secondary', 'secondary', 'secondary', 'secondary', 'secondary', '#FFFFFF', '#FFFFFF'],
			FLA: ['#B9975B', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#B9975B', 'secondary', 'secondary', 'secondary', 'secondary', 'secondary', 'secondary', 'secondary', '#000000'],
			LA: ['secondary', '#FFFFFF', '#FFFFFF', '#FFFFFF', 'secondary', '', '', '', '', '', '', '', 'secondary'],
			MIN: ['#DDC9A3', 'primary', '#DDC9A3', '#DDC9A3', '', '', '', '', '', '', '', '', '#DDC9A3'],
			MTL: ['#FFFFFF', 'secondary', 'secondary', 'secondary', '#FFFFFF', '', '', '', '', '', '', '', '#FFFFFF'],
			NSH: ['', '', '', '', '', '', '', 'secondary', 'secondary', 'secondary', '', '', 'secondary'],
			NJ: ['#FFFFFF', 'secondary', '#FFFFFF', '', '', '', '', '', '', '', 'secondary', '', 'primary'],
			NYI: ['', '', 'secondary', 'secondary', 'secondary', 'primary', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '', '', 'secondary'],
			NYR: ['#FFFFFF', 'secondary', '#FFFFFF', '', '', '', '', '', '', '', '', '', ''],
			OTT: ['primary', '#000000', '#000000', 'primary', '', '', '', '', '', '', '', '', 'primary'],
			PHI: ['', '', '', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#000000', '#000000', '#000000', '#FFFFFF', '', '#000000'],
			PIT: ['primary', '#FFFFFF', 'secondary', '', '', '', '', '', '', '', '', '', 'secondary', 'secondary'],
			SJ: ['#000000', '#FFFFFF', 'primary', '#FFFFFF', '#000000', '', '', '', '', '', '', '', '#000000', ''],
			SEA: ['secondary', 'secondary', 'secondary', '#355464', '#355464', '', '#e9072b', '', '', '', '', '', '', ''],
			STL: ['secondary', '', 'primary', 'primary', 'primary', '', 'secondary', '', '', '', '', '', 'primary'],
			TB: ['', '', '#FFFFFF', '#FFFFFF', '', '', '', '', '', '', '', '', '#FFFFFF'],
			TOR: ['#FFFFFF', '', '', '#FFFFFF', '', '', '', '', '', '', '', '', '#FFFFFF'],
			UTAH: ['primary', '#FFFFFF', 'primary', '', '', '', '', '', '', '', '', '', '#FFFFFF'],
			VAN: ['#FFFFFF', 'secondary', 'secondary', '#FFFFFF', '', '', '', '', '', '', '', '', '#FFFFFF'],
			VGK: ['secondary', 'secondary', '#FFFFFF', '#FFFFFF', '#c8102E', '', '', '', '', '', '', '', '', 'secondary'],
			WSH: ['', '', '', '', '', '', '', '', '', '', '', '', 'primary'],
			WPG: ['#004C97', '#FFFFFF', '#004C97', '', '#004C97', '#FFFFFF', '#004C97', '', '', '', '', '', '']
		},
		soccer: {}
	};

	const findJerseyStripes = (teamObj: Team | null): string => {
		if (!teamObj) return '';
		const colorToUse = stripeColors[sport][teamObj.abbreviation];
		if (!colorToUse) return '';
		if (colorToUse[0] === 'secondary') return teamObj.secondary;
		if (colorToUse[0] === 'primary') return teamObj.primary;
		return colorToUse[0];
	};

	const findMultipleStripes = (teamObj: Team | null, i: number): string => {
		if (!teamObj) return '';
		const colorToUse = (jerseyVersion?.value === 'home' ? stripeColors : stripeColorsAway)[sport][teamObj.abbreviation];
		if (!colorToUse || !colorToUse[i]) return findJerseyColor(teamObj);
		if (colorToUse[i] === 'secondary') return teamObj.secondary;
		if (colorToUse[i] === 'primary') return teamObj.primary;
		return colorToUse[i];
	};

	const getIconCircleStyle = (teamObj: Team | null): any => {
		if (!teamObj) return {};
		if (jerseyVersion?.value === 'home' && sport !== 'mlb') return {};
		return {
			border: `4px solid ${teamObj.primary}`
		};
	};

	const getIconFill = (teamObj: Team | null, fill1: string): string => {
		if (!teamObj) return fill1;
		if (settings.iconColor === 'none') {
			return jerseyVersion?.value === 'home' && sport !== 'mlb' ? fill1 : teamObj.primary;
		}
		return props.findColor(settings.iconColor, teamObj);
	};

	const findAccomplishmentColor = (teamObj: Team | null): string => {
		if (!teamObj) return '#000';
		if (settings.iconTextColor === 'none') {
			if (jerseyVersion?.value === 'home' && sport !== 'mlb') {
				if (sport === 'nhl' && ['NYI'].includes(teamObj.abbreviation)) return teamObj.secondary;
				return '#fff';
			}
			return teamObj.primary;
		}
		return props.findColor(settings.iconTextColor, teamObj);
	};

	const teamObj = props.getTeamObj(newsteam.toUpperCase(), sport);
	if (settings.flipColors && ['ncaa'].includes(sport)) {
		const oldPrimary = teamObj.primary;
		const oldSecondary = teamObj.secondary;
		teamObj.primary = oldSecondary;
		teamObj.secondary = oldPrimary;
	}
	const fill1 = findJerseyColor(teamObj);
	let fill2 = fill1 === '#000000' ? colord(fill1).lighten(0.1).toHex() : colord(fill1).darken(0.1).toHex();
	if ((['nba', 'mlb', 'nhl'].includes(sport) || (sport === 'ncaa' && String(jerseyType?.value)?.includes('basketball'))) && settings.jerseyBackground !== 'none') {
		fill2 = props.findColor(settings.jerseyBackground, teamObj);
	}
	return (
		<>
			{(sport === 'nfl' || (sport === 'ncaa' && String(jerseyType?.value)?.includes('football'))) && (
				<FootballJersey
					fill1={fill1}
					fill2={fill2}
				/>
			)}
			{(sport === 'nba' || sport === 'wnba' || (sport === 'ncaa' && String(jerseyType?.value)?.includes('basketball'))) && (
				<BasketballJersey
					fill1={fill1}
					fill2={fill2}
					fill3={findMultipleStripes(teamObj, 0)}
					fill4={findMultipleStripes(teamObj, 1)}
					fill5={findMultipleStripes(teamObj, 2)}
				/>
			)}
			{(sport === 'mlb' || sport === 'soccer') &&
				(stripeColors[sport][teamObj.abbreviation]
? (
					<StripedBaseballJersey
						fill1={fill1}
						fill2={fill2}
						fill3={findJerseyStripes(teamObj)}
					/>
				)
: (
					<BaseballJersey
						fill1={fill1}
						fill2={fill2}
					/>
				))}
			{sport === 'nhl' && (
				<HockeyJersey
					fill1={fill1}
					fill2={fill2}
					fill3={findMultipleStripes(teamObj, 0)}
					fill4={findMultipleStripes(teamObj, 1)}
					fill5={findMultipleStripes(teamObj, 2)}
					fill6={findMultipleStripes(teamObj, 3)}
					fill7={findMultipleStripes(teamObj, 4)}
					fill8={findMultipleStripes(teamObj, 5)}
					fill9={findMultipleStripes(teamObj, 6)}
					fill10={findMultipleStripes(teamObj, 7)}
					fill11={findMultipleStripes(teamObj, 8)}
					fill12={findMultipleStripes(teamObj, 9)}
					fill13={findMultipleStripes(teamObj, 10)}
					fill14={findMultipleStripes(teamObj, 11)}
					fill15={findMultipleStripes(teamObj, 12)}
					fill16={findMultipleStripes(teamObj, 13)}
				/>
			)}
			{((sport !== 'nba' && sport !== 'wnba' && !(sport === 'ncaa' && String(jerseyType?.value)?.includes('basketball'))) || isMobile)
? (
				<div
					className='jersey-name'
					onClick={() => props.selectSettings('playerName')}
					style={{
						WebkitTextStroke: `${findNameOutline(teamObj)}`,
						color: `${findNameColor(teamObj)}`,
						fontSize: ['nhl', 'mlb', 'soccer'].includes(sport)
							? 'calc(var(--playernamesize) / 2 * var(--sizemultiplier))'
							: ['nba', 'wnba'].includes(sport)
								? 'calc(var(--playernamesize) * .75 * var(--sizemultiplier))'
								: 'calc(var(--playernamesize) * var(--sizemultiplier))',
						top:
							sport === 'nhl' && ['BOS', 'COL', 'CBJ', 'EDM', 'NJ', 'PHI'].includes(teamObj.abbreviation)
								? 'calc(var(--playernamemargin) + 18%)'
								: sport === 'nhl'
									? 'calc(var(--playernamemargin) + 10%)'
									: ['mlb', 'soccer', 'nba'].includes(sport)
										? 'calc(var(--playernamemargin) + 8%)'
										: 'var(--playernamemargin)'
					}}>
					{playerName}
				</div>
			)
: (
				<svg
					viewBox={`0 ${settings.postWidth / -10} ${settings.postWidth} ${settings.postWidth}`}
					onClick={() => props.selectSettings('playerName')}
					className='name-svg'>
					<path
						id='curve'
						d={`M${settings.postWidth * 0.1},${settings.postWidth * 0.4}c${settings.postWidth * 0.2},${settings.postWidth * -0.4},${settings.postWidth * 0.6},${settings.postWidth * -0.4},${settings.postWidth * 0.8},0`}
						fill='none'
					/>
					<text className='name-svg-text'>
						<textPath
							href='#curve'
							startOffset='50%'
							textAnchor='middle'
							fill={findNameColor(teamObj)}
							lengthAdjust='spacingAndGlyphs'>
							{playerName}
						</textPath>
					</text>
				</svg>
			)}
			<div
				className='jersey-number'
				onClick={() => props.selectSettings('playerNumber')}
				style={{
					textShadow: `${findNumberShadow(teamObj)}`,
					WebkitTextStroke: `${findNumberOutline(teamObj)}`,
					color: `${findNumberColor(teamObj)}`,
					top: sport === 'nhl' && ['BOS', 'COL', 'CBJ', 'EDM', 'NJ', 'PHI'].includes(teamObj.abbreviation) ? 'calc(var(--playernumbermargin) + 4%)' : 'var(--playernumbermargin)'
				}}>
				{playerNumber}
			</div>
			<div
				className='jersey-accomplishments'
				onClick={() => props.selectSettings('icon')}>
				{[0, 1, 2, 3].map((i) => {
					const Icon = iconTypes[i] ? icons[iconTypes[i]] : icons.win;
					if (!Icon) return null;
					return (
						<div
							className='jersey-accomplishment'
							key={i}>
							<span
								className='icon-circle'
								style={getIconCircleStyle(teamObj)}>
								<Icon fill={getIconFill(teamObj, fill1)} />
							</span>
							{iconDescriptions[i] && (
								<span
									className='accomplishment-text'
									style={{ color: findAccomplishmentColor(teamObj) }}>
									{iconDescriptions[i].split(' ')[0]}
									<br />
									{iconDescriptions[i].split(' ').slice(1).join(' ')}
								</span>
							)}
						</div>
					);
				})}
			</div>
			{bannerSpaces > 0 && (
				<div
					className='week-banners'
					onClick={() => props.selectSettings('banner')}>
					{Array.from({ length: bannerSpaces }).map((_u, i) => {
						const banner = banners[i];
						return (
							<div
								className='banner-spacer'
								key={i}>
								{banner
? (
									<div
										key={i}
										className='week-banner'
										style={getBannerStyles(teamObj)}>
										<span>{banner.split(' ')[0]}</span>
										<span className='week-num'>{banner.split(' ').slice(1).join(' ')}</span>
									</div>
								)
: (
									<div
										key={i}
										className='week-banner'></div>
								)}
							</div>
						);
					})}
				</div>
			)}
		</>
	);
}
