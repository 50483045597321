import { useEffect, useMemo, useState } from 'react'
import TextInput from 'react-autocomplete-input'
import {nhlAbbrs, nflAbbrs, nbaAbbrs, mlbAbbrs, ncaaAbbrs, soccerAbbrs, f1Abbrs, wnbaAbbrs, aflAbbrs} from '../../data/teams/teamAbbrs';
import axios from 'axios';
import {toast} from 'react-toastify';
import 'react-autocomplete-input/dist/bundle.css';
import QuickAdd from '../QuickAdd';
import {bracketOptions, chartOptions, comparisonOptions, gridImageOptions, gridOptions, lineupOptions, matchupOptions, newsOptions, postImageOptions, postOptions, scoreOptions, tierOptions, jerseyOptions} from '../../data/styles/settingsOptions';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import useSettingsStore from '../../stores/settings.store';
import useOptionsStore from '../../stores/options.store';
import usePostStore from '../../stores/posts/base-post.store';
import useWithImageStore from '../../stores/posts/with-image.store';
import useChartStore from '../../stores/posts/chart.store';
import useComparisonStore from '../../stores/posts/comparison.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';
import useModalStore from '../../stores/modal.store';
import Select from 'react-select';
import usePreferencesStore from '../../stores/preferences.store';
import { settingsStylesDark } from '../../data/styles/settingsStylesDark';
import { settingsStyles } from '../../data/styles/settingsStyles';
import useJerseyStore from '../../stores/posts/jersey.store';
import {Menu, MenuItem, MenuButton} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import './AboveForm.scss';

export default function AboveForm(props) {
	const [selectedField, setSelectedField] = useState('');
	const [expandedField, setExpandedField] = useState('');
	const { settings } = useSettingsStore();
	const { setSelectedStyle } = useOptionsStore();
	const {
		title, setTitle,
		subtitle, setSubtitle,
		thirdTitle, setThirdTitle,
		description, setDescription,
		team, setTeam,
		name, setName,
		stat, setStat,
		image, setImage,
		points, setPoints,
		ranks, setRanks,
	} = usePostStore();
	const {
		newsimage, setNewsImage,
		newsteam, setNewsTeam,
		newstitle, setNewsTitle,
		newssubtitle, setNewsSubtitle
	} = useWithImageStore();
	const {
		xLabel, setxLabel,
		yLabel, setyLabel,
	} = useChartStore();
	const {
		leftName, setLeftName,
		rightName, setRightName,
		leftTeam, setLeftTeam,
		rightTeam, setRightTeam,
		leftImage, setLeftImage,
		rightImage, setRightImage,
		leftStats, setLeftStats,
		rightStats, setRightStats,
		statLabels, setStatLabels
	} = useComparisonStore();
	const {
		sport
	} = useUserStore();
	const {
		teamOptions,
		playerOptions,
		individuallyEdit,
		teamAbbrs,
		debug
	} = useGlobalStore();
	const { toggleModal } = useModalStore();
	const { darkMode } = usePreferencesStore();
	const { availableIcons, iconTypes, icons, setIconTypes, iconDescriptions, setIconDescriptions, playerName, playerNumber, setPlayerName, setPlayerNumber, banners, setBanners, bannerSpaces, setBannerSpaces } = useJerseyStore();
	const [mappedOptions, setMappedOptions] = useState([]);

	const findValue = useMemo(()=> {
		const index = (sport === 'ncaa' ? ncaaAbbrs : teamAbbrs).findIndex((t) => t === newsteam);
		return mappedOptions.find((o) => o.value === teamOptions[index]) ?? null;
	}, [newsteam]);

	useEffect(() => {
		setMappedOptions(teamOptions.map((o) => ({value: o, label: o})));
	}, [teamOptions]);

	const handleClickOutside = (event) => {
		if (event.target.closest('.team-quick-add') === null && event.target.closest('.teamsInput') === null && event.target.closest('.aboveForm') === null) {
			setSelectedField('');
			document.removeEventListener('click', handleClickOutside, true);
		}
	};

	const onTeamSelect = (value) => {
		let teams = [];
		if (sport === 'nhl') teams = nhlAbbrs;
		else if (sport === 'nba') teams = nbaAbbrs;
		else if (sport === 'nfl') teams = nflAbbrs;
		else if (sport === 'ncaa') teams = ncaaAbbrs;
		else if (sport === 'mlb') teams = mlbAbbrs;
		else if (sport === 'soccer') teams = soccerAbbrs;
		else if (sport === 'f1') teams = f1Abbrs;
		else if (sport === 'wnba') teams = wnbaAbbrs;
		else if (sport === 'afl') teams = aflAbbrs;
		const val = value.split('\n');
		val.map((t, i) => {
			if (t.includes('@')) {
				const index = teamOptions.indexOf(t.replace('@', ''));
				val.splice(i, 1, teams[index]);
			}
			return t;
		});
		setTeam(val);
	};

	const onNameSelect = (value) => {
		const val = value.split('\n');
		val.map((n, i) => {
			if (n.includes('@')) {
				val.splice(i, 1, n.replace('@', ''));
			}
			return n;
		});
		setName(val);
	};

	const selectSettings = async (val) => {
		let type = settings.type;
		let availableOptions = [];
		if (type === 'post') availableOptions = postOptions;
		else if (type === 'grid') availableOptions = gridOptions;
		else if (type === 'postimage') availableOptions = postImageOptions;
		else if (type === 'gridimage') availableOptions = gridImageOptions;
		else if (type === 'tier') availableOptions = tierOptions;
		else if (type === 'bracket') availableOptions = bracketOptions;
		else if (type === 'scores') availableOptions = scoreOptions;
		else if (type === 'matchupimage') availableOptions = matchupOptions;
		else if (type === 'news') availableOptions = newsOptions;
		else if (type === 'highlightimage') availableOptions = newsOptions;
		else if (type === 'lineupimage') availableOptions = lineupOptions;
		else if (type === 'comparison') availableOptions = comparisonOptions;
		else if (type === 'jerseyformat') availableOptions = jerseyOptions;
		else if (type === 'chart') availableOptions = chartOptions;
		const value = availableOptions.find((opt) => opt.value === val);
		if (value) {
			setSelectedStyle(value);
		}
	};

	const getTeamPlaceholder = () => {
		let extra = '';
		let always = "Enter an '@' to see suggestions.";
		if (settings.type === 'tier') {
			extra = " Separate different tiers with a '#'.";
		} else if (settings.type === 'bracket') {
			extra = ' Click a team to advance them to the next round.';
		}
		if (sport === 'nfl') {
			return `Type in team names separated by a new line (i.e. 'DEN' or 'Broncos'). ${always}${extra}`;
		} else if (sport === 'nba') {
			return `Type in team names separated by a new line (i.e. 'LAL' or 'Lakers'). ${always}${extra}`;
		} else if (sport === 'mlb') {
			return `Type in team names separated by a new line (i.e. 'LAD' or 'Dodgers'). ${always}${extra}`;
		} else if (sport === 'ncaa') {
			return `Type in team abbreviations separated by a new line (i.e. 'ALA'). ${always}${extra}`;
		} else if (sport === 'nhl') {
			return `Type in team names separated by a new line (i.e. 'EDM' or 'Oilers'). ${always}${extra}`;
		} else if (sport === 'soccer') {
			return `Type in team abbreviations separated by a new line (i.e. 'BAR'). ${always}${extra}`;
		} else if (sport === 'f1') {
			return `Type in team abbreviations separated by a new line (i.e. 'RED'). ${always}${extra}`;
		} else if (sport === 'wnba') {
			return `Type in team abbreviations separated by a new line (i.e. 'LV'). ${always}${extra}`;
		} else if (sport === 'afl') {
			return `Type in team abbreviations separated by a new line (i.e. 'GEEL'). ${always}${extra}`;
		} else {
			return `Type in team names separated by a new line.${extra}`;
		}
	};

	const getNamePlaceholder = () => {
		let extra = '';
		if (settings.type === 'chart') {
			return `Type in the value of each label along the ${settings.indexAxis}-axis`;
		}
		if (sport === 'nba' || sport === 'nfl' || sport === 'mlb' || sport === 'nhl') {
			extra = " Enter an '@' to see suggestions.";
		}
		if (settings.type === 'matchupimage' || settings.type === 'scores') {
			return 'Type in scores for each team separated by a new line.';
		} else if (settings.type.includes('grid') || settings.type === 'tier') {
			return `Optionally, type in player or team names separated by a new line.${extra}`;
		} else {
			return `Type in player or team names separated by a new line.${extra}`;
		}
	};

	const getNameLabel = () => {
		let type = settings.type;
		if (type.includes('post') || type === 'lineupimage') {
			return 'Names';
		} else if (type === 'scores' || type === 'matchupimage') {
			return 'Scores';
		} else if (type === 'chart') {
			return settings.indexAxis === 'y' ? 'Y-Axis' : 'X-Axis';
		} else {
			return 'Top';
		}
	};

	const getStatLabel = () => {
		let type = settings.type;
		if (type.includes('post')) {
			return 'Stats';
		} else if (type === 'scores') {
			return 'Extra Info';
		} else if (type === 'chart') {
			return settings.indexAxis === 'y' ? 'X-Axis' : 'Y-Axis';
		} else if (type === 'lineupimage') {
			return 'Position';
		} else {
			return 'Bottom';
		}
	};

	const getStatPlaceholder = () => {
		if (settings.type === 'chart') {
			return `Type in the value of each bar along the ${settings.indexAxis === 'y' ? 'x' : 'y'}-axis`;
		} else if (settings.type === 'scores') {
			return 'Optionally, type in extra information for each match separated by a new line';
		}
		return `Optionally, type in stats or extra information separated by a new line`;
	};

	const getImagePlaceholder = () => {
		if (sport === 'nfl') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot (i.e. '2330' for Tom Brady) or provide a URL to an image or upload an image.`;
		} else if (sport === 'nba') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot (i.e. '1966' for LeBron James) or provide a URL to an image or upload an image.`;
		} else if (sport === 'mlb') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot (i.e. '30836' for Mike Trout) or provide a URL to an image or upload an image.`;
		} else if (sport === 'ncaa') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot. For college basketball, the player ID must be prefixed by 'ncaam*' (i.e. '4360310' for Trevor Lawrence or 'ncaam*4277951' for Luka Garza) or provide a URL to an image or upload an image`;
		} else if (sport === 'nhl') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot (i.e. '3895074' for Connor McDavid) or provide a URL to an image or upload an image.`;
		} else if (sport === 'soccer') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot (i.e. '146419' for Javier Hernandez) or provide a URL to an image or upload an image.`;
		} else if (sport === 'f1') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot (i.e. '4665' for Max Verstappen) or provide a URL to an image or upload an image.`;
		} else if (sport === 'wnba') {
			return `Optionally, type in the player's ESPN player ID separated by a new line to include their headshot (i.e. '2998928' for Breanna Stewart) or provide a URL to an image or upload an image.`;
		} else if (sport === 'afl') {
			return `Optionally, provide a URL to a player's headshot with transparent background.`;
		} else {
			return `Optionally, type in the player's ESPN player ID prefixed by the league and an asterisk to include their headshot (i.e. 'nfl*2330' for Tom Brady) or provide a URL to an image or upload an image.`;
		}
	};

	const getPlayerStats = async () => {
		try {
			setStat([]);
			let playerIds = image;
			let currentSport = sport;
			if (currentSport === 'ncaa') {
				currentSport = 'college-football';
			}
			//if no player ids then find them first
			if (!playerIds.length || !playerIds[0].length) {
				let request = [];
				name.map((name, i) => {
					request.push({team: team[i].toUpperCase(), name});
					return name;
				});
				const res1 = await axios.post(`/stats/${currentSport}/playerids`, request);
				playerIds = res1.data;
				setImage(playerIds);
			}
			const res2 = await axios.post(`stats/${currentSport}/playerstats`, playerIds);
			setStat(res2.data);
		} catch (e) {
			if (debug) {
				console.log(e);
			}
			toast.error('Oops! Something went wrong');
		}
	};

	let maplength = team.length;
	if (maplength >= 1 && maplength < 5) {
		maplength = 5;
	} else if (maplength >= 100) {
		maplength = 100;
	}
	const showFillPlayerIds = sport !== 'ncaa' &&
		sport !== 'custom' &&
		sport !== 'soccer' &&
		sport !== 'f1' &&
		sport !== 'wnba' &&
		sport !== 'afl';
	return (
		<div className='aboveForm'>
			{props.user.plan === 'all' && (
				<div className='radiobtns'>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'nfl'}
							value='NFL'
							onChange={() => props.sportChange('nfl', true)}
						/>
						NFL
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'nba'}
							value='NBA'
							onChange={() => props.sportChange('nba', true)}
						/>
						NBA
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'mlb'}
							value='MLB'
							onChange={() => props.sportChange('mlb', true)}
						/>
						MLB
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'ncaa'}
							value='NCAA'
							onChange={() => props.sportChange('ncaa', true)}
						/>
						NCAA
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'nhl'}
							value='NHL'
							onChange={() => props.sportChange('nhl', true)}
						/>
						NHL
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'soccer'}
							value='SOCCER'
							onChange={() => props.sportChange('soccer', true)}
						/>
						Soccer
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'f1'}
							value='F1'
							onChange={() => props.sportChange('f1', true)}
						/>
						F1
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'wnba'}
							value='wnba'
							onChange={() => props.sportChange('wnba', true)}
						/>
						WNBA
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'afl'}
							value='afl'
							onChange={() => props.sportChange('afl', true)}
						/>
						AFL
					</label>
					<label>
						<input
							type='radio'
							name='sportradio'
							checked={sport === 'custom'}
							value='CUSTOM'
							onChange={() => props.sportChange('custom', true)}
						/>
						Custom
					</label>
				</div>
			)}
			{settings.type !== 'news' && settings.type !== 'highlightimage' && settings.type !== 'jerseyformat' && (
				<span>
					<div className='above'>
						<form>
							<span className='fields'>
								{settings.type === 'lineupimage' && <span className='formGroup'>
									<label>Team</label>
									<span className='relative'>
										<textarea
											placeholder='Enter the applicable team name/abbreviation'
											rows='1'
											cols='45'
											id='newsteam'
											type='text'
											value={newsteam}
											onClick={() => selectSettings('team')}
											onChange={(event) => {
												setNewsTeam(event.target.value);
											}}
										/>
									</span>
								</span>}
								{settings.type === 'lineupimage' && <span className='formGroup'>
									<label>Title</label>
									<span className='relative'>
										<textarea
											placeholder='Title'
											rows='1'
											cols='45'
											id='newstitle'
											type='text'
											value={newstitle}
											onClick={() => selectSettings('header')}
											onChange={(event) => {
												setNewsTitle(event.target.value);
											}}
										/>
									</span>
								</span>}
								{settings.type !== 'matchupimage' && settings.type !== 'lineupimage' && (
									<span className='formGroup'>
										<label>Title</label>
										<span className='relative'>
											<textarea
												placeholder='Title'
												rows='1'
												cols='45'
												id='title'
												type='text'
												onFocus={() => {
													setSelectedField('title');
												}}
												value={title}
												onClick={() => selectSettings('header')}
												onChange={(event) => {
													setTitle(event.target.value);
												}}
											/>
										</span>
									</span>
								)}
								{settings.type !== 'matchupimage' && settings.type !== 'lineupimage' && (
									<span className='formGroup'>
										<label>Subtitle 1</label>
										<span className='relative'>
											<textarea
												placeholder='Subtitle'
												rows='1'
												cols='45'
												id='subtitle'
												type='text'
												onFocus={() => {
													setSelectedField('subtitle');
												}}
												value={subtitle}
												onClick={() => selectSettings('subtitle')}
												onChange={(event) => {
													setSubtitle(event.target.value);
												}}
											/>
										</span>
									</span>
								)}
								{settings.type !== 'matchupimage' && settings.type !== 'lineupimage' && (
									<span className='formGroup'>
										<label>Subtitle 2</label>
										<span className='relative'>
											<textarea
												placeholder='Subtitle'
												rows='1'
												cols='45'
												id='thirdTitle'
												type='text'
												onFocus={() => {
													setSelectedField('thirdTitle');
												}}
												value={thirdTitle}
												onClick={() => selectSettings('thirdTitle')}
												onChange={(event) => {
													setThirdTitle(event.target.value);
												}}
											/>
										</span>
									</span>
								)}
								{settings.type === 'matchupimage' && (
									<span className='formGroup'>
										<label>Game Status</label>
										<span className='relative'>
											<textarea
												placeholder='Optionally, type in the game status i.e. FINAL'
												rows='1'
												cols='45'
												id='gamestatus'
												type='text'
												onFocus={() => {
													setSelectedField('stat');
												}}
												value={stat.join('\n')}
												onClick={() => selectSettings('stat')}
												onChange={(event) => {
													setStat(event.target.value.replace(/\n/g, '^').split('^'));
												}}
											/>
										</span>
									</span>
								)}
								{settings.type === 'matchupimage' && (
									<span className='formGroup'>
										<label>Game Description</label>
										<span className='relative'>
											<textarea
												placeholder='Optionally, type in the game description'
												rows='1'
												cols='45'
												id='gamedescription'
												type='text'
												onFocus={() => {
													setSelectedField('description');
												}}
												value={description}
												onClick={() => selectSettings('description')}
												onChange={(event) => {
													setDescription(event.target.value);
												}}
											/>
										</span>
									</span>
								)}
								{settings.type.includes('image') && (
									<span className='formGroup'>
										<label>Image URL</label>
										<span className='relative'>
											<textarea
												placeholder='Enter the url for the image you want to add (Dimensions of at least 1080 x 1080), or upload an image below.'
												rows='1'
												cols='45'
												id='newsimagetextarea'
												type='text'
												value={newsimage}
												onClick={() => selectSettings('image')}
												onChange={(event) => {
													setNewsImage(event.target.value);
													props.setImageDimensions();
												}}
											/>
										</span>
									</span>
								)}
							</span>
						</form>
					</div>
					{settings.type === 'chart' && settings.chartType?.value === 'scatter' && (
						<div className='above2'>
							<form>
								<span className='fields'>
									<span className='formGroup'>
										<label>X-Axis Label</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='xAxis'
												type='text'
												value={xLabel}
												onClick={() => selectSettings('chart')}
												onChange={(event) => {
													setxLabel(event.target.value);
												}}
											/>
										</span>
									</span>
									<span className='formGroup'>
										<label>Y-Axis Label</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='yAxis'
												type='text'
												value={yLabel}
												onClick={() => selectSettings('chart')}
												onChange={(event) => {
													setyLabel(event.target.value);
												}}
											/>
										</span>
									</span>
								</span>
							</form>
						</div>
					)}
					{settings.type === 'comparison' && (
						<div className='above2'>
							<form>
								<span className='fields'>
									<span className='formGroup'>
										<label>Left Name</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='leftName'
												placeholder='Player name or column title'
												type='text'
												value={leftName}
												onClick={() => selectSettings('name')}
												onChange={(event) => {
													setLeftName(event.target.value);
												}}
											/>
										</span>
									</span>
									<span className='formGroup'>
										<label>Right Name</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='rightName'
												placeholder='Player name or column title'
												type='text'
												value={rightName}
												onClick={() => selectSettings('name')}
												onChange={(event) => {
													setRightName(event.target.value);
												}}
											/>
										</span>
									</span>
								</span>
							</form>
						</div>
					)}
					{settings.type === 'comparison' && (
						<div className='above2'>
							<form>
								<span className='fields'>
									<span className='formGroup'>
										<label>Left Team</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='leftTeam'
												placeholder="The team name (i.e. 'DEN')"
												type='text'
												value={leftTeam}
												onClick={() => selectSettings('team')}
												onChange={(event) => {
													setLeftTeam(event.target.value);
												}}
											/>
										</span>
									</span>
									<span className='formGroup'>
										<label>Left Image</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='leftImage'
												placeholder='Player ID or Image URL'
												type='text'
												value={leftImage}
												onClick={() => selectSettings('image')}
												onChange={(event) => {
													setLeftImage(event.target.value);
												}}
											/>
										</span>
									</span>
									<span className='formGroup'>
										<label>Right Team</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='rightTeam'
												placeholder="The team name (i.e. 'DEN')"
												type='text'
												value={rightTeam}
												onClick={() => selectSettings('team')}
												onChange={(event) => {
													setRightTeam(event.target.value);
												}}
											/>
										</span>
									</span>
									<span className='formGroup'>
										<label>Right Image</label>
										<span className='relative'>
											<textarea
												rows='1'
												cols='45'
												id='rightImage'
												placeholder='Player ID or Image URL'
												type='text'
												value={rightImage}
												onClick={() => selectSettings('image')}
												onChange={(event) => {
													setRightImage(event.target.value);
												}}
											/>
										</span>
									</span>
								</span>
							</form>
						</div>
					)}
					{settings.type === 'comparison' && (
						<div className='above'>
							<form>
								<span className='fields'>
									<span className='formGroup'>
										<label>Left Stats</label>
										<span className='relative'>
											<textarea
												rows='5'
												cols='45'
												id='leftStats'
												placeholder='Stats for the left side'
												type='text'
												value={leftStats.join('\n')}
												onClick={() => selectSettings('stat')}
												onChange={(event) => {
													setLeftStats(event.target.value.replace(/\n/g, '^').split('^'));
												}}
											/>
										</span>
									</span>
									<span className='formGroup'>
										<label>Stat Labels</label>
										<span className='relative'>
											<textarea
												rows='5'
												cols='45'
												id='statLabels'
												placeholder='Stat labels'
												type='text'
												value={statLabels.join('\n')}
												onClick={() => selectSettings('labels')}
												onChange={(event) => {
													setStatLabels(event.target.value.replace(/\n/g, '^').split('^'));
												}}
											/>
										</span>
									</span>
									<span className='formGroup'>
										<label>Right Stats</label>
										<span className='relative'>
											<textarea
												rows='5'
												cols='45'
												id='rightStats'
												placeholder='Stats for the right side'
												type='text'
												value={rightStats.join('\n')}
												onClick={() => selectSettings('stat')}
												onChange={(event) => {
													setRightStats(event.target.value.replace(/\n/g, '^').split('^'));
												}}
											/>
										</span>
									</span>
								</span>
							</form>
							<div className='aboveButtons'>
								{sport !== 'custom' && (
									<Tippy content={<span>Load Example</span>}>
										<button
											type='button'
											className='newButton'
											onClick={() => props.prefill()}>
											<i className='fa-solid fa-file-circle-plus'></i>
										</button>
									</Tippy>
								)}
								<Tippy content={<span>Reset Fields</span>}>
									<button
										type='button'
										className='newButton'
										onClick={props.reset}>
										<i className='fa-solid fa-rotate-left'></i>
									</button>
								</Tippy>
							</div>
						</div>
					)}
					{settings.type !== 'comparison' && (
						<div className='above2'>
							<form>
								<span className='fields'>
									{settings.type !== 'lineupimage' && <span className={(team.length >= 10 ? 'long-input formGroup' : 'formGroup') + (expandedField === 'teams' ? ' expanded' : '')}>
										<label>Teams</label>
										<span className='relative formRelative'>
											<TextInput
												className='teamsInput'
												options={teamOptions}
												spacer={`\n`}
												regex={'[^\n\r]*'}
												onSelect={(e) => onTeamSelect(e)}
												matchAny={true}
												placeholder={getTeamPlaceholder()}
												rows='10'
												cols='30'
												id='teamsInput'
												type='text'
												onFocus={() => {
													document.addEventListener('click', handleClickOutside, true);
													setSelectedField('teams');
													selectSettings('team');
												}}
												value={team.join('\n')}
												onChange={(value) => {
													setTeam(value.replace(/\n/g, '^').split('^'));
												}}
											/>
											<span>
												<Tippy content={<span>Clear Teams</span>}>
													<span
														className='relativeButton'
														onClick={() => setTeam([])}>
														<i className='fa-solid fa-rotate-left'></i>
													</span>
												</Tippy>
											</span>
											<span>
												<Tippy content={<span>{expandedField === 'teams' ? 'Collapse' : 'Expand'}</span>}>
													<span
														className='relativeButtonTop'
														onClick={() => (expandedField === 'teams' ? setExpandedField('') : setExpandedField('teams'))}>
														<i className={'fa-solid ' + (expandedField === 'teams' ? 'fa-solid fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center')}></i>
													</span>
												</Tippy>
											</span>
										</span>
									</span>}
									<span className={(name.length >= 10 ? 'long-input formGroup' : 'formGroup') + (expandedField === 'names' ? ' expanded' : '')}>
										<label>{getNameLabel()}</label>
										<span className='relative formRelative'>
											<TextInput
												options={playerOptions}
												spacer={`\n`}
												regex={'[^\n\r]*'}
												onSelect={(e) => onNameSelect(e)}
												matchAny={true}
												placeholder={getNamePlaceholder()}
												rows='10'
												cols='30'
												id='names'
												type='text'
												onFocus={() => {
													setSelectedField('name');
												}}
												value={name.join('\n')}
												onClick={() => selectSettings('name')}
												onChange={(value) => {
													setName(value.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '^').split('^'));
												}}
											/>
											<span>
												<Tippy content={<span>{`Clear ` + getNameLabel()}</span>}>
													<span
														className='relativeButton'
														onClick={() => setName([])}>
														<i className='fa-solid fa-rotate-left'></i>
													</span>
												</Tippy>
											</span>
											<span>
												<Tippy content={<span>{expandedField === 'names' ? 'Collapse' : 'Expand'}</span>}>
													<span
														className='relativeButtonTop'
														onClick={() => (expandedField === 'names' ? setExpandedField('') : setExpandedField('names'))}>
														<i className={'fa-solid ' + (expandedField === 'names' ? 'fa-solid fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center')}></i>
													</span>
												</Tippy>
											</span>
										</span>
									</span>
									{settings.type !== 'matchupimage' && (
										<span className={'formGroup' + (expandedField === 'stats' ? ' expanded' : '')}>
											<label>{getStatLabel()}</label>
											<span className='relative'>
												<textarea
													placeholder={getStatPlaceholder()}
													rows='10'
													cols='30'
													id='stats'
													type='text'
													onFocus={() => {
														setSelectedField('stat');
													}}
													value={stat.join('\n')}
													onClick={() => selectSettings('stat')}
													onChange={(event) => {
														setStat(event.target.value.replace(/\n/g, '^').split('^'));
													}}
												/>
												{props.user.is_admin && team.length < 8 && sport !== 'custom' && sport !== 'f1' && (
													<Tippy content={<span>Fill player stats</span>}>
														<span
															className='relativeButton2'
															onClick={getPlayerStats}>
															<i className='fas fa-plus-circle'></i>
														</span>
													</Tippy>
												)}
												<Tippy content={<span>{`Clear ` + getStatLabel()}</span>}>
													<span
														className='relativeButton'
														onClick={() => setStat([])}>
														<i className='fa-solid fa-rotate-left'></i>
													</span>
												</Tippy>
												<span>
													<Tippy content={<span>{expandedField === 'stats' ? 'Collapse' : 'Expand'}</span>}>
														<span
															className='relativeButtonTop'
															onClick={() => (expandedField === 'stats' ? setExpandedField('') : setExpandedField('stats'))}>
															<i className={'fa-solid ' + (expandedField === 'stats' ? 'fa-solid fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center')}></i>
														</span>
													</Tippy>
												</span>
											</span>
										</span>
									)}
									{settings.type !== 'scores' && settings.type !== 'matchupimage' && settings.type !== 'chart' && settings.type !== 'highlightimage' && settings.type !== 'lineupimage' && (
										<span className={'formGroup' + (expandedField === 'images' ? ' expanded' : '')}>
											<label>Player ID/Image URL</label>
											<span className='relative'>
												<textarea
													placeholder={getImagePlaceholder()}
													rows='10'
													cols='30'
													onFocus={() => {
														setSelectedField('image');
													}}
													value={image.join('\n')}
													onClick={() => selectSettings('player')}
													onChange={(event) => {
														setImage(event.target.value.replace(/\n/g, '^').split('^'));
													}}
												/>
												<Tippy content={<span>Upload Image</span>}>
													<span
														className={showFillPlayerIds ? 'relativeButton3' : 'relativeButton2'}
														onClick={() => toggleModal('upload')}>
														<i className="fa-solid fa-upload"></i>
													</span>
												</Tippy>
												{showFillPlayerIds && (
														<Tippy content={<span>Fill available player IDs</span>}>
															<span
																className='relativeButton2'
																onClick={() => props.getImagebyID()}>
																<i className='fas fa-plus-circle'></i>
															</span>
														</Tippy>
													)}
												<Tippy content={<span>Clear Player ID/Image URL</span>}>
													<span
														className='relativeButton'
														onClick={() => setImage([])}>
														<i className='fa-solid fa-rotate-left'></i>
													</span>
												</Tippy>
												<Tippy content={<span>{expandedField === 'images' ? 'Collapse' : 'Expand'}</span>}>
													<span
														className='relativeButtonTop'
														onClick={() => (expandedField === 'images' ? setExpandedField('') : setExpandedField('images'))}>
														<i className={'fa-solid ' + (expandedField === 'images' ? 'fa-solid fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center')}></i>
													</span>
												</Tippy>
											</span>
										</span>
									)}
									{(settings.type === 'scores' || settings.type.includes('post') || settings.type === 'matchupimage') && (
										<span className={'formGroup' + (expandedField === 'ranks' ? ' expanded' : '')}>
											<label>Ranks</label>
											<span className='relative'>
												<textarea
													placeholder='Optionally, type in the rank separated by a new line (or a blank line for no rank)'
													rows='10'
													cols='30'
													id='rank'
													type='text'
													onFocus={() => {
														setSelectedField('rank');
													}}
													value={ranks.join('\n')}
													onClick={() => selectSettings('rank')}
													onChange={(event) => {
														setRanks(event.target.value.replace(/\n/g, '^').split('^'));
													}}
												/>
												{settings.type.includes('post') && (
													<Tippy content={<span>Calculate Rank Automatically</span>}>
														<span
															className='relativeButton2'
															onClick={props.orderList}>
															<i className='fa-solid fa-arrow-down-1-9'></i>
														</span>
													</Tippy>
												)}
												<Tippy content={<span>Clear Ranks</span>}>
													<span
														className='relativeButton'
														onClick={() => setRanks([])}>
														<i className='fa-solid fa-rotate-left'></i>
													</span>
												</Tippy>
												<Tippy content={<span>{expandedField === 'ranks' ? 'Collapse' : 'Expand'}</span>}>
													<span
														className='relativeButtonTop'
														onClick={() => (expandedField === 'ranks' ? setExpandedField('') : setExpandedField('ranks'))}>
														<i className={'fa-solid ' + (expandedField === 'ranks' ? 'fa-solid fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center')}></i>
													</span>
												</Tippy>
											</span>
										</span>
									)}
									{((settings.type.includes('post') && maplength < settings.fivelessBreak) || settings.type === 'matchupimage') && (
										<span className={'formGroup' + (expandedField === 'points' ? ' expanded' : '')}>
											<label>{settings.type.includes('post') ? `Points (< ${settings.fivelessBreak} teams)` : 'Records'}</label>
											<span className='relative'>
												<textarea
													placeholder={settings.type.includes('post') ? 'Optionally, type in points separated by a new line' : 'Optionally, type in team records separated by a new line'}
													rows='10'
													cols='30'
													id='points'
													type='text'
													onFocus={() => {
														setSelectedField('points');
													}}
													value={points.join('\n')}
													onClick={() => selectSettings('points')}
													onChange={(event) => {
														setPoints(event.target.value.replace(/\n/g, '^').split('^'));
													}}
												/>
												<Tippy content={<span>Clear Points</span>}>
													<span
														className='relativeButton'
														onClick={() => setPoints([])}>
														<i className='fa-solid fa-rotate-left'></i>
													</span>
												</Tippy>
												<Tippy content={<span>{expandedField === 'points' ? 'Collapse' : 'Expand'}</span>}>
													<span
														className='relativeButtonTop'
														onClick={() => (expandedField === 'points' ? setExpandedField('') : setExpandedField('points'))}>
														<i className={'fa-solid ' + (expandedField === 'points' ? 'fa-solid fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center')}></i>
													</span>
												</Tippy>
											</span>
										</span>
									)}
									<div className='aboveButtons'>
										{sport !== 'custom' && (
											<Tippy content={<span>Load Example</span>}>
												<button
													type='button'
													className='newButton'
													onClick={() => props.prefill()}>
													<i className='fa-solid fa-file-circle-plus'></i>
												</button>
											</Tippy>
										)}
										{sport && sport !== 'custom' && (settings.type.includes('post') || settings.type.includes('grid'))&& (
											<Tippy content={<span>Paste External Table</span>}>
												<button
													type='button'
													className='newButton'
													onClick={() => toggleModal('paste')}>
													<i className='fa-solid fa-paste'></i>
												</button>
											</Tippy>
										)}
										<Tippy content={<span>Reset Fields</span>}>
											<button
												type='button'
												className='newButton'
												onClick={props.reset}>
												<i className='fa-solid fa-rotate-left'></i>
											</button>
										</Tippy>
									</div>
								</span>
							</form>
						</div>
					)}
				</span>
			)}
			{(settings.type === 'news' || settings.type === 'highlightimage') && (
				<span>
					<div className='above'>
						<form>
							<span className='fields'>
								<span className='formGroup'>
									<label>Team</label>
									<span className='relative'>
										<textarea
											placeholder='Enter the applicable team name/abbreviation'
											rows='1'
											cols='45'
											id='newsteam'
											type='text'
											value={newsteam}
											onClick={() => selectSettings('team')}
											onChange={(event) => {
												setNewsTeam(event.target.value);
											}}
										/>
									</span>
								</span>
								<span className='formGroup'>
									<label>Title</label>
									<span className='relative'>
										<textarea
											placeholder='Title'
											rows='1'
											cols='45'
											id='newstitle'
											type='text'
											value={newstitle}
											onClick={() => selectSettings('header')}
											onChange={(event) => {
												setNewsTitle(event.target.value);
											}}
										/>
									</span>
								</span>
							</span>
						</form>
					</div>
					<div className='above2'>
						<form>
							<span className='fields'>
								<span className='formGroup'>
									<label>Image Link</label>
									<span className='relative'>
										<textarea
											placeholder='Enter the url for the image you want to add (Dimensions of at least 1080 x 1080), or upload an image below.'
											rows='10'
											cols='45'
											id='newsimagetextarea'
											type='text'
											value={newsimage}
											onClick={() => selectSettings('image')}
											onChange={async (event) => {
												setNewsImage(event.target.value);
												props.setImageDimensions();
											}}
										/>
									</span>
								</span>
								<span className='formGroup'>
									<label>Text</label>
									<span className='relative'>
										<textarea
											placeholder={settings.type === 'news' ? 'Extra text' : 'Enter each stat on a new line, i.e.\n10 pts\n5 reb'}
											rows='10'
											cols='45'
											id='newssubtitle'
											type='text'
											value={newssubtitle}
											onClick={() => selectSettings('header')}
											onChange={(event) => {
												setNewsSubtitle(event.target.value);
											}}
										/>
									</span>
								</span>
								<div className='aboveButtons'>
								{sport !== 'custom' && (
									<Tippy content={<span>Load Example</span>}>
										<button
											type='button'
											className='newButton'
											onClick={() => props.prefill()}>
											<i className='fa-solid fa-file-circle-plus'></i>
										</button>
									</Tippy>
								)}
								<Tippy content={<span>Reset Fields</span>}>
									<button
										type='button'
										className='newButton'
										onClick={props.reset}>
										<i className='fa-solid fa-rotate-left'></i>
									</button>
								</Tippy>
								</div>
							</span>
						</form>
					</div>
				</span>
			)}
			{settings.type === 'jerseyformat' && (
				<span>
					<div className='above'>
						<form className='jerseyForm'>
							<span className='fields flexfields'>
								<span className='field-row field-row-1'>
								<span className='formGroup'>
									<label>Team</label>
									<Select
										value={findValue}
										onChange={(selectedOption) => {
											const index = teamOptions.findIndex((t) => t === selectedOption.value);
											setNewsTeam(sport === 'ncaa' ? ncaaAbbrs[index] : teamAbbrs[index]);
										}}
										options={mappedOptions}
										styles={darkMode ? settingsStylesDark : settingsStyles}
									/>
								</span>
								<span className='formGroup'>
									<label>Name</label>
									<span className='relative'>
										<textarea
											placeholder='Name'
											rows='1'
											cols='45'
											id='playerName'
											type='text'
											value={playerName}
											onClick={() => selectSettings('playerName')}
											onChange={(event) => {
												setPlayerName(event.target.value);
											}}
										/>
									</span>
								</span>
								<span className='formGroup'>
									<label>Number</label>
									<span className='relative'>
										<textarea
											placeholder='Number'
											rows='1'
											cols='45'
											id='playerNumber'
											type='text'
											value={playerNumber}
											onClick={() => selectSettings('playerNumber')}
											onChange={(event) => {
												setPlayerNumber(event.target.value);
											}}
										/>
									</span>
								</span>
								</span>
								<span className='field-row field-row-2'>
								{[0,1,2,3].map((inst) => {
									const CurrentIcon = icons[iconTypes[inst]];
									if (!CurrentIcon) return null;
									return <span className='formGroup icon-menu' key={inst}>
										<label>Icon {inst + 1}</label>
										<Menu
											menuButton={
												<MenuButton>
													<span className='icon-circle-button'>
														<CurrentIcon fill='#000' />
													</span>
												</MenuButton>
											}>
											{availableIcons.filter((o) => {
												if (sport === 'nfl') {
													return ['all','football'].includes(o.sport);
												} else if (sport === 'mlb') {
													return ['all','baseball'].includes(o.sport);
												} else if (sport === 'nba' || sport === 'wnba') {
													return ['all','basketball'].includes(o.sport);
												} else if (sport === 'nhl') {
													return ['all','hockey'].includes(o.sport);
												} else if (sport === 'soccer') {
													return ['all','soccer'].includes(o.sport);
												} else if (sport === 'ncaa') {
													return ['all','football','basketball'].includes(o.sport);
												}
											}).map((o, i) => {
												const Icon = icons[o.value];
												return <MenuItem
													key={i}
													onClick={() => {
														let copiedTypes = [...iconTypes];
														copiedTypes[inst] = o.value;
														setIconTypes(copiedTypes);
													}}>
													<Tippy content={<span>{o.label}</span>}>
														<span className='icon-circle-button'>
															<Icon fill='#000' />
														</span>
													</Tippy>
												</MenuItem>
											})}
										</Menu>
										<span className='relative'>
											<textarea
												placeholder='Description'
												rows='1'
												cols='45'
												id='icon1desc'
												type='text'
												value={iconDescriptions[inst]}
												onClick={() => selectSettings('icon')}
												onChange={(event) => {
													let copiedDesc = [...iconDescriptions];
													copiedDesc[inst] = event.target.value;
													setIconDescriptions(copiedDesc);
												}}
											/>
										</span>
									</span>
								})}
								</span>
								<span className='field-row field-row-3'>
								<label>Banners</label>
								<span className='formGroup'>
									<span className='relative' style={{minWidth: '250px'}}>
										<label style={{minWidth: '125px', alignContent: 'center'}}># of banners</label>
										<input type='number' className='number-input' value={bannerSpaces} onChange={(e) => setBannerSpaces(e.target.value)} />
									</span>
									{Array.from({length: bannerSpaces}).map((_u, i) => {
										return <span key={i} className='relative'>
											<textarea
												placeholder={`Banner ${i + 1}`}
												rows='1'
												cols='45'
												id={`banner${i}`}
												type='text'
												value={banners[i]}
												onClick={() => selectSettings('banner')}
												onChange={(event) => {
													let copiedBanners = [...banners];
													copiedBanners[i] = event.target.value;
													setBanners(copiedBanners);
												}}
											/>
										</span>
									})}
								</span>
								</span>
							</span>
							<div className='aboveButtons'>
									{sport !== 'custom' && (
										<Tippy content={<span>Load Example</span>}>
											<button
												type='button'
												className='newButton'
												onClick={() => props.prefill()}>
												<i className='fa-solid fa-file-circle-plus'></i>
											</button>
										</Tippy>
									)}
									<Tippy content={<span>Reset Fields</span>}>
										<button
											type='button'
											className='newButton'
											onClick={props.reset}>
											<i className='fa-solid fa-rotate-left'></i>
										</button>
									</Tippy>
								</div>
						</form>
					</div>
				</span>
			)}
			{selectedField === 'teams' &&
				sport &&
				!individuallyEdit &&
				(teamAbbrs.length > 0 || sport === 'custom') &&
				settings.type !== 'news' &&
				settings.type !== 'jerseyformat' &&
				settings.type !== 'lineupimage' &&
				settings.type !== 'highlightimage' && (
					<QuickAdd
						getTeamObj={props.getTeamObj}
					/>
				)}
		</div>
	);
}
