import React, { useEffect } from 'react';
import useGlobalStore from '../stores/global.store';
import useModalStore from '../stores/modal.store';

export type SidebarButtonType = 'Format' | 'Templates' | 'Posts' | 'Settings' | 'Admin' | 'Auto' | 'Team' | 'Help' | 'Referral' | 'Styles';

interface SideBarMenuProps {
    user: any;
}

const SideBarMenu: React.FC<SideBarMenuProps> = ({
    user
}) => {
    const { individuallyEdit, editTeam, expanded, setExpanded, setLastSidebar } = useGlobalStore();
    const { toggleModal } = useModalStore();

    useEffect(() => {
        if (individuallyEdit && editTeam !== null) {
            setLastSidebar(expanded);
            setExpanded('Team');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [individuallyEdit, editTeam]);

    const handleButtonClick = (type: SidebarButtonType): void => {
        if (expanded === type) {
            setExpanded('');
        } else {
            setExpanded(type);
        }
    };

    return (
        <div className="sidebar-menu">
            {user?.isActive && (<span className="sidebar-top">
                <span className={expanded === 'Format' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button id='format-tab' className='sidebar-button' onClick={() => handleButtonClick('Format')}>
                        <span className="material-symbols-outlined">
                            space_dashboard
                        </span>
                        Format
                    </button>
                </span>
                <span className={expanded === 'Posts' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button className='sidebar-button' onClick={() => handleButtonClick('Posts')}>
                        <span className="material-symbols-outlined">
                            folder
                        </span>
                        Posts
                    </button>
                </span>
                <span className={expanded === 'Settings' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button className='sidebar-button' onClick={() => handleButtonClick('Settings')}>
                        <span className="material-symbols-outlined">
                            settings
                        </span>
                        Settings
                    </button>
                </span>
                {user?.is_admin && (<span className={expanded === 'Styles' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button className='sidebar-button' onClick={() => handleButtonClick('Styles')}>
                        <span className="material-symbols-outlined">
                            brush
                        </span>
                        Styles
                    </button>
                </span>)}
                <span className={expanded === 'Templates' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button className='sidebar-button' onClick={() => handleButtonClick('Templates')}>
                        <span className="material-symbols-outlined">
                            photo_library
                        </span>
                        Templates
                    </button>
                </span>
                {user?.is_admin && (<span className={expanded === 'Auto' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button id='autopost' className='sidebar-button' onClick={() => handleButtonClick('Auto')}>
                        <span className="material-symbols-outlined">
                            query_stats
                        </span>
                        Auto
                    </button>
                </span>)}
                {user?.is_admin && (<span className={expanded === 'Admin' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button className='sidebar-button' onClick={() => handleButtonClick('Admin')}>
                        <span className="material-symbols-outlined">
                            shield_person
                        </span>
                        Admin
                    </button>
                </span>)}
                {individuallyEdit && editTeam !== null && (<span className={expanded === 'Team' ? 'active sidebar-button-wrapper' : 'sidebar-button-wrapper'} >
                    <button className='sidebar-button' onClick={() => handleButtonClick('Team')}>
                        <span className="material-symbols-outlined">
                            edit
                        </span>
                        Edit
                    </button>
                </span>)}
            </span>)}
            {!user?.isActive && (<span className="sidebar-top"></span>)}
            <span className="sidebar-bottom">
                <span className='sidebar-button-wrapper'>
                    <button className='sidebar-button' onClick={() => toggleModal('referral')}>
                        <span className="material-symbols-outlined">
                            monetization_on
                        </span>
                        Affiliate
                    </button>
                </span>
                <span className='sidebar-button-wrapper'>
                    <button className='sidebar-button' onClick={() => toggleModal('help')}>
                        <span className="material-symbols-outlined">
                            help
                        </span>
                        Help
                    </button>
                </span>
            </span>
        </div>
    );
};

export default SideBarMenu;
