import React, { useEffect, useState } from 'react';
import Lock from '../Lock';
import Select, { SingleValue } from 'react-select';
import useUserStore from '../../stores/user.store';
import useOptionsStore from '../../stores/options.store';
import useSettingsStore from '../../stores/settings.store';
import { colorOptions } from '../../data/styles/colorOptions';
import { settingsStylesDark } from '../../data/styles/settingsStylesDark';
import { settingsStyles } from '../../data/styles/settingsStyles';
import usePreferencesStore from '../../stores/preferences.store';
import { ChromePicker } from './ChromePicker';
import type { Option } from '../../stores/options.store';

interface MultipleColorProps {
  label: string;
  settingName: string;
  defaultColor: string;
  colorChange: (color: string, setting: string) => void;
  includeBrighterDarker?: boolean;
  includeNone?: boolean;
  noneIsDefault?: boolean;
}

export const MultipleColorPicker: React.FC<MultipleColorProps> = ({
  label,
  settingName,
  defaultColor,
  colorChange,
  includeBrighterDarker = false,
  includeNone = false,
  noneIsDefault = false
}) => {
	const [availableOptions, setAvailableOptions] = useState([...colorOptions]);
	const { lockedSettings } = useUserStore();
	const {
		selectedMultiColor,
		setSelectedMultiColor
	} = useOptionsStore();
	const { settings, updateSetting } = useSettingsStore();
	const { darkMode } = usePreferencesStore();

	// componentDidMount
	useEffect(() => {
		setOption();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setOption();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings[settingName]]);

	const setOption = (): void => {
		let optionsToSet = [...availableOptions];
		if (!includeBrighterDarker) {
			optionsToSet = optionsToSet.filter((opt) => {
				return opt.value !== 'brighter' && opt.value !== 'darker';
			})
		}
		if (!includeNone) {
			optionsToSet = optionsToSet.filter((opt) => {
				return opt.value !== 'none';
			})
		} else {
			if (noneIsDefault) {
				const noneOption = optionsToSet.find((opt) => {
					return opt.value === 'none';
				});
				if (noneOption) {
					noneOption.label = 'Default';
				}
			}
		}
		let firstOption = optionsToSet[0];
		if (settings[settingName].includes('#')) {
			const foundOption = availableOptions.find((opt) => opt.value === 'single');
			if (foundOption) {
				firstOption = foundOption;
			}
		} else {
			const foundOption = availableOptions.find((opt) => opt.value === settings[settingName]);
			if (foundOption) {
				firstOption = foundOption;
			}
		}
		setSelectedMultiColor(firstOption as SingleValue<Option>);
		setAvailableOptions(optionsToSet);
	};

  return (
    <div>
		<span className='editor-dropdown'>
			<Lock
				title={label}
				setting={settingName}
				lockedSettings={lockedSettings}
			/>
			<Select
				isDisabled={lockedSettings.includes(settingName)}
				value={selectedMultiColor}
				onChange={(s) => {
					setSelectedMultiColor(s);
					const val = String((s as any).value);
					if (val !== 'single') {
						updateSetting(settingName, val);
					} else {
						updateSetting(settingName, defaultColor);
					}
				}}
				options={availableOptions}
				styles={darkMode ? settingsStylesDark : settingsStyles}
				placeholder={settings[settingName].includes('#') ? 'Single' : settings[settingName]}
			/>
		</span>
		{settings[settingName].includes('#') && (<span style={{ display: 'block', marginTop: '20px' }}>
			<ChromePicker
				color={settings[settingName]}
				onChange={(color: string) => colorChange(color, settingName)}
			/>
		</span>)}
    </div>
  );
};
