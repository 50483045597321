export const quick4 = {
	type: 'post',
	headerSize: 20,
	nameSize: 18,
	statSize: 18,
	logoOrder: 1,
	logoOpacity: 89,
	logoSize: 87,
	titleMarginTop: 1,
	headerSpacing: 0,
	nameMargin: 0,
	headerFont: 'edo',
	nameFont: 'league gothic',
	statFont: 'bebas neue',
	headerColor: '#000000',
	headerFontColor: '#ffffff',
	watermark: '',
	watermarkFont: 'roboto condensed',
	watermarkSize: 30,
	watermarkWidth: 75,
	statMargin: 0,
	watermarkColor: '#ffffff',
	nameShadow: 1,
	borderColor: '#ffffff',
	nameSpacing: 0,
	statSpacing: 0,
	statShadow: 0,
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 132,
	headerSizeNews: 20,
	headerFontNews: 'graduate',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 94,
	nameColorNews: '#ffffff',
	logoSizeNews: 360,
	orientation: 'rightleft',
	orientationNews: 'leftright',
	orientationMatchup: 'topbottom',
	borderRadius: 8,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	paneSplitMatchup: [80, 20],
	duplicate: true,
	logoShadow: 5,
	nameMargin2: 10,
	postWidth: 680,
	postHeight: 680,
	postWidthNews: 680,
	postHeightNews: 680,
	tierFont: 'metropolis',
	tierColor: '#ffffff',
	tierSize: 66,
	duplicateLogoSize: 631,
	duplicateLogoPosition: 6,
	duplicateLogoOpacity: 8,
	gridMargin: 1,
	logoFlip: true,
	scoreMinHeight: 100,
	videoDirection: 'fromright',
	videoReverse: false,
	headerOutlineColor: '#ffffff',
	nameOutlineColor: '#ffffff',
	statOutlineColor: '#ffffff',
	headerOutlineSize: 0,
	nameOutlineSize: 0,
	statOutlineSize: 0,
	headerTransform: 'uppercase',
	nameTransform: 'uppercase',
	statTransform: 'none',
	nameShadowColor: '#000000',
	statShadowColor: '#000000',
	tierTitle: 'left',
	pyramid: false,
	newsStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	postBackground: 'https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background16.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291',
	titleHeight: 10,
	subtitleFontSize: 10,
	subtitleMarginTop: -7,
	subtitleSpacing: 0,
	subtitleFont: 'league gothic',
	subtitleFontColor: '#ffffff',
	subtitleOutlineColor: '#ffffff',
	subtitleOutlineSize: 0,
	subtitleTransform: 'uppercase',
	backgroundColor: 'gradient',
	postColumns: null,
	gridColumns: null,
	tierSplit: 8,
	fivelessBreak: 8,
	rankSize: 26,
	rankFont: 'roboto condensed',
	rankFontColor: '#ffffff',
	rankMargin: 0,
	rankSpacing: 0,
	rankOutlineSize: 2,
	rankOutlineColor: '#000000',
	logoSizeGrid: 100,
	logoSizeTier: 100,
	pointsSize: 20,
	pointsFont: 'roboto condensed',
	pointsFontColor: '#ffffff',
	pointsMargin: 0,
	pointsSpacing: -1.2,
	pointsOutlineSize: 0,
	pointsOutlineColor: '#000000',
	pointsBackground: '#000000',
	playerHeight: 1,
	statLineHeight: 1,
	indexAxis: 'y',
	chartType: {
		value: 'bar',
		label: 'Bar Chart',
	},
	labelSize: 14,
	labelColor: '#ffffff',
	labelFont: 'lemon milk light',
	chartBorderColor: '#ffffff',
	chartBorderWidth: 3,
	chartLogoSize: 90,
	playerMargin: 0,
	lighterLogos: true,
	texture: null,
	textureSize: 4,
	textureOpacity: 14,
	rankBackground: '#000000',
	boxMarginTop: 7.5,
	boxMarginRight: 7.5,
	boxMarginBottom: 7.5,
	boxMarginLeft: 7.5,
	teamBackground: 'gradient',
	actualHeaderColor: '#00000000',
	rankWidth: 49,
	rankMargin2: 0,
	boxRankOutlineSize: 0,
	borderSize: 0,
	headerBorderSize: 0,
	headerBorderColor: '#ffffff',
	backgroundGradientDirectionDeg: '0deg',
	backgroundGradientStops: [
		{
			color: '#000000',
			percentage: 0,
		},
		{
			color: '#606060',
			percentage: 100,
		},
	],
	gradientDirection: '0deg',
	gradientDirectionNews: '0deg',
	gradientType: 'linear-gradient',
	gradientStops: [
		{
			color: '#000000',
			percentage: 10,
		},
		{
			color: 'primary',
			percentage: 66,
		},
	],
	gradientTypeNews: 'linear-gradient',
	gradientStopsNews: [
		{
			color: '#000000',
			percentage: 53,
		},
		{
			color: 'primary',
			percentage: 93,
		},
	],
};
